define('admin-portal/pods/components/courier-delete-modal/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    session: _ember['default'].inject.service('session'),

    name: 'courier-deletion',
    classNames: ['courier-deletion'],

    onHide: function onHide() {
      this.set("courierDeletionDetails", null);
    },

    actions: {
      confirm: function confirm() {
        this.get("onDelete")(this.get("currentCourier"));
      },
      cancel: function cancel() {
        this.set("courierDeletionDetails", null);
        _ember['default'].$('.ui.modal.courier-deletion').modal('hide');
      }
    }
  });
});