define('admin-portal/pods/ecommerce/new/controller', ['exports', 'ember', 'admin-portal/shapes/ecommerce-courier'], function (exports, _ember, _adminPortalShapesEcommerceCourier) {
  exports['default'] = _ember['default'].Controller.extend({
    config: _ember['default'].inject.service('config'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),
    loading: _ember['default'].inject.service('loading'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    isBillingOpen: false,
    couriers_list: null,
    isSavingBool: false,
    newcourier: null,
    integration_methods: ["Standard API", "Custom API", "Shopify", "WooCommerce", "Magento", "Other"],
    product_payment_methods: ["Customer Pays", "Consumer Pays", "Combination"],
    carrier_partners: [{ name: "CP", code: "cpl" }, { name: "Toll", code: "toll" }, { name: "None", code: 'none' }],
    timezone_list: config.timezone_list,

    actions: {
      setCountry: function setCountry(country_name) {
        this.set('newcourier.country', country_name);
      },
      updateCarrier: function updateCarrier(selectedData) {
        var _this = this;

        this.get("loadingSpinner").show();
        this.set('isBillingOpen', false);
        this.set('isSavingBool', false);
        this.set('isLinkedCourier', true);

        this.set("newcourier.customername", selectedData.company_name);
        this.set('hubbed_user_id', selectedData.hubbed_user_id);

        this.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/edit/' + selectedData.hubbed_user_id, {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          }
        }).then(function (response) {
          if (response && response.result) {

            var results = response.result;
            _this.set('courierTypeId', selectedData.courier_type);

            if (selectedData.courier_type === 0) {
              _this.set('role_id', _this.get('internalPartnerRoleId'));
            } else if (selectedData.courier_type === 1) {
              _this.set('role_id', _this.get('externalPartnerRoleId'));
            } else {
              _this.set('role_id', results.role_id);
            }
            _this.set('hubbed_user_id', results.hubbed_user_id);

            results['customername'] = results.company_name;
            results['timezone'] = results.timeZoneId;

            results['contactname'] = results.contact_name;
            results['email'] = results.contact_email;
            results['phone'] = results.contact_phone;
            results['position'] = results.contact_position;

            results['address1'] = results.contact_address;
            results['address2'] = results.contact_address2;
            results['suburb'] = results.contact_suburb;
            results['postcode'] = results.contact_postcode;

            results['state'] = results.contact_state;
            results['country'] = results.contact_country;

            results['notificationUserName'] = results.notification_username;
            results['notificationPassword'] = results.notification_password;
            results['notificationURL'] = results.notification_url;

            if (results.contactname === results.billing_contactname && results.position === results.billing_position && results.email === results.billing_email && results.phonenumber === results.billing_phonenumber && results.address === results.billing_address && results.address2 === results.billing_address2 && results.suburb === results.billing_suburb && results.state === results.billing_state && results.postcode === results.billing_postcode && results.country === results.billing_country) {
              _this.set("isBillingOpen", true);
              _this.set('populateBillingBool', true);
            } else {
              _this.set("isBillingOpen", false);
              _this.set('populateBillingBool', false);
            }
            if (results && results.webhookEventMapping) {
              _this.set("webhook_events", results.webhookEventMapping);
            }
            _this.set('newcourier', null);

            _this.set("newcourier", _adminPortalShapesEcommerceCourier['default'].create(_ember['default'].getOwner(_this).ownerInjection(), results));
          } else {
            //this.set('newcourier', null);         
          }
          _this.get("loadingSpinner").hide();
        }, function () /*error*/{
          //this.set('newcourier', null);
          _this.get("loadingSpinner").hide();
        });
      },
      new_partner: function new_partner() {
        this.set('hubbed_user_id', 0);
        this.set('isBillingOpen', false);
        this.set('isSavingBool', false);
        this.set('role_id', this.get('unlinkedPartnerRoleId'));
        this.set('isLinkedCourier', false);
        this.set('newcourier', null);
        this.set('newcourier', _adminPortalShapesEcommerceCourier['default'].create(_ember['default'].getOwner(this).ownerInjection()));
      },
      billingOpen: function billingOpen(newcourier) {
        if (this.get('isBillingOpen')) {
          this.set('isBillingOpen', false);
          this.set('newcourier.billing_contactname', null);
          this.set('newcourier.billing_position', null);
          this.set('newcourier.billing_email', null);
          this.set('newcourier.billing_phone', null);

          this.set('newcourier.billing_address', null);
          this.set('newcourier.billing_address2', null);
          this.set('newcourier.billing_suburb', null);
          this.set('newcourier.billing_state', null);
          this.set('newcourier.billing_postcode', null);
          this.set('newcourier.billing_country', null);
        } else {
          this.set('isBillingOpen', true);
          this.set('newcourier.billing_contactname', newcourier.contactname);
          this.set('newcourier.billing_position', newcourier.position);
          this.set('newcourier.billing_email', newcourier.email);
          this.set('newcourier.billing_phone', newcourier.phone);

          this.set('newcourier.billing_address', newcourier.address1);
          this.set('newcourier.billing_address2', newcourier.address2);
          this.set('newcourier.billing_suburb', newcourier.suburb);
          this.set('newcourier.billing_state', newcourier.state);
          this.set('newcourier.billing_postcode', newcourier.postcode);
          this.set('newcourier.billing_country', newcourier.country);
        }
      },
      populateBillingAddress: function populateBillingAddress(newcourier) {
        if (this.get('isBillingOpen')) {
          this.set('newcourier.billing_contactname', newcourier.contactname);
          this.set('newcourier.billing_position', newcourier.position);
          this.set('newcourier.billing_email', newcourier.email);
          this.set('newcourier.billing_phone', newcourier.phone);

          this.set('newcourier.billing_address', newcourier.address1);
          this.set('newcourier.billing_address2', newcourier.address2);
          this.set('newcourier.billing_suburb', newcourier.suburb);
          this.set('newcourier.billing_state', newcourier.state);
          this.set('newcourier.billing_postcode', newcourier.postcode);
          this.set('newcourier.billing_country', newcourier.country);
        }
      },
      create: function create() {
        var _this2 = this;

        this.set("isSavingBool", true);
        var self = this;
        var products_list = self.get("products_list").map(function (a) {
          if (a.status) {
            return {
              "enabled": true,
              "self_enabled": true,
              "product_id": Number(a.id)
            };
          } else {
            return {
              "enabled": false,
              "self_enabled": false,
              "product_id": Number(a.id)
            };
          }
        });
        var webhook_list = self.get("webhook_events").map(function (a) {
          if (self.get('isLinkedCourier')) {
            return a;
          } else {
            return {
              "id": 0,
              "event_id": a.event_id,
              "event_name": a.event_name,
              "active": a.active
            };
          }
        });

        var billable_list = self.get("billable_services").map(function (a) {
          if (a.active) {
            return {
              "id": 0,
              "billableservice_id": a.id,
              "hubbed_user_id": self.get('hubbed_user_id'),
              "active": true
            };
          } else {
            return {
              "id": 0,
              "billableservice_id": a.id,
              "hubbed_user_id": self.get('hubbed_user_id'),
              "active": false
            };
          }
        });

        var channel_list = self.get("channel_list").map(function (a) {
          if (a.active) {
            return {
              "channel_id": a.channel_id,
              "active": true,
              "self_active": true
            };
          } else {
            return {
              "channel_id": a.channel_id,
              "self_active": false,
              "active": false
            };
          }
        });

        var apiStr = 'create';
        var isecommerce_enable_bool = false;
        if (this.get('hubbed_user_id') !== 0) {
          apiStr = 'update';
          isecommerce_enable_bool = true;
        }
        var selected_carrier_partner = null;
        if (this.get('newcourier.carrier_partner') && this.get('newcourier.carrier_partner') !== "none") {
          selected_carrier_partner = this.get('newcourier.carrier_partner');
        }
        this.get('ajax').post(this.get("config.carrierApiURL") + ('ecommerceuser/' + apiStr), {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          },
          data: JSON.stringify({
            isecommerce_enable: isecommerce_enable_bool,
            role_id: this.get('role_id'),
            hubbed_user_id: this.get('hubbed_user_id'),
            is_deleted: false,
            isLinkedCourier: this.get('isLinkedCourier'),
            couriertype_id: this.get('courierTypeId'),
            active: this.get("newcourier.active") && this.get("newcourier.active") !== "" && this.get("newcourier.active") !== undefined ? this.get("newcourier.active") : false,
            company_name: this.get('newcourier.customername'),
            notification_url: this.get('newcourier.notificationURL'),
            notification_username: this.get('newcourier.notificationUserName'),
            notification_password: this.get('newcourier.notificationPassword'),
            token: "",
            contact_name: this.get("newcourier.contactname"),
            contact_phone: this.get("newcourier.phone"),
            contact_position: this.get("newcourier.position"),
            contact_email: this.get("newcourier.email"),
            contact_address: this.get('newcourier.address1'),
            contact_address2: this.get('newcourier.address2'),
            contact_suburb: this.get('newcourier.suburb'),
            contact_state: this.get("newcourier.state"),
            contact_postcode: this.get('newcourier.postcode'),
            contact_country: this.get("newcourier.country"),
            billing_contactname: this.get("newcourier.billing_contactname"),
            billing_phone: this.get("newcourier.billing_phone"),
            billing_position: this.get("newcourier.billing_position"),
            billing_email: this.get("newcourier.billing_email"),
            billing_address: this.get('newcourier.billing_address'),
            billing_address2: this.get('newcourier.billing_address2'),
            billing_suburb: this.get('newcourier.billing_suburb'),
            billing_state: this.get("newcourier.billing_state"),
            billing_postcode: this.get('newcourier.billing_postcode'),
            billing_country: this.get("newcourier.billing_country"),
            language_code: "",
            carrier_partner: selected_carrier_partner,
            send_notification: this.get("newcourier.active_notification") && this.get("newcourier.active_notification") !== "" && this.get("newcourier.active_notification") !== undefined ? this.get("newcourier.active_notification") : false,
            timeZoneId: this.get('newcourier.timezone'),
            logo: this.get('newcourier.logo'),
            duplicate_allowed_days: this.get('newcourier.duplicate_allowed_days'),
            integration_method: this.get("newcourier.integration_method"),
            billableServiceMapping: billable_list,
            ecommerceChannelMapping: channel_list,
            product_payment_method: this.get("newcourier.product_payment_method"),
            ecommerceProductMapping: products_list,
            WebhookEventMapping: webhook_list
          }),
          contentType: 'application/json; charset=utf-8',
          crossDomain: true
        }).then(function (data) {
          _this2.set("isSavingBool", false);

          if (data.responseCode === "200" || data.responseCode === 200) {
            _this2.set('newcourier', '');
            _this2.get('notify').success('Partner has been successfully Created.');
            _this2.transitionToRoute('ecommerce.list');
          } else {
            _this2.get('notify').error(data.responseMessage);
          }
        })['catch'](function (error) {
          _this2.set("isSavingBool", false);
          var errorMsg = error.errors.map(function (e) {
            return e.detail;
          });
          if (errorMsg[0] && errorMsg[0].message) {
            _this2.get('notify').error(errorMsg[0].message);
          } else if (errorMsg[0] && errorMsg[0].result && errorMsg[0].result.length > 0) {
            _this2.get('notify').error(errorMsg[0].result[0].description);
          } else {
            _this2.get('notify').error('Error while saving, please try again.');
          }
        });
      }
    }
  });
});