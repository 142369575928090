define('admin-portal/pods/ecommerce/new/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'admin-portal/shapes/ecommerce-courier'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _adminPortalShapesEcommerceCourier) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    config: _ember['default'].inject.service('config'),
    session: _ember['default'].inject.service('session'),
    ajax: _ember['default'].inject.service(),
    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller) {
      controller.set('isBillingOpen', false);
      controller.set('isSavingBool', false);
      controller.set('newcourier', null);
      controller.set('hubbed_user_id', 0);
      controller.set('isLinkedCourier', false);

      var results = {};
      results['notificationURL'] = '';
      results['notificationUserName'] = '';
      results['notificationPassword'] = '';
      controller.set("newcourier", _adminPortalShapesEcommerceCourier['default'].create(_ember['default'].getOwner(controller).ownerInjection(), results));

      this.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/courierlist', {
        headers: {
          'Accept': 'application/json',
          'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
        }
      }).then(function (response) {
        controller.set("couriers_list", response.result);
      }, console.log);

      this.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/getmastertableslist', {
        headers: {
          'Accept': 'application/json',
          'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
        }
      }).then(function (response) {
        if (response && response.result && response.result.products) {
          controller.set("products_list", response.result.products);
        }
        if (response && response.result && response.result.billableService) {
          controller.set("billable_services", response.result.billableService);
        }
        if (response && response.result && response.result.webhookEvents) {
          var newWebhookEventsArr = [];
          for (var k = 0; k < response.result.webhookEvents.length; k++) {
            var tempObj = {};
            tempObj['event_id'] = response.result.webhookEvents[k].id;
            tempObj['event_name'] = response.result.webhookEvents[k].display_name;
            newWebhookEventsArr.push(tempObj);
          }
          controller.set("webhook_events", newWebhookEventsArr);
        }
        if (response && response.result && response.result.channels) {
          controller.set("channel_list", response.result.channels);
        }
        if (response && response.result && response.result.roles) {
          for (var k = 0; k < response.result.roles.length; k++) {
            if (response.result.roles[k] && response.result.roles[k].rolename) {
              var localNameStr = response.result.roles[k].rolename;

              if (localNameStr === "EcommercePartner") {
                controller.set('unlinkedPartnerRoleId', response.result.roles[k].id);
                controller.set('role_id', response.result.roles[k].id);
              }
              if (localNameStr === "EcommerceInternal") {
                controller.set('internalPartnerRoleId', response.result.roles[k].id);
              }
              if (localNameStr === "EcommerceExternal") {
                controller.set('externalPartnerRoleId', response.result.roles[k].id);
              }
            }
          }
        }
        if (response && response.result && response.result.courierTypes) {
          for (var i = 0; i < response.result.courierTypes.length; i++) {
            if (response.result.courierTypes[i].name === "unlinked") {
              controller.set('courierTypeId', response.result.courierTypes[i].id);
              break;
            }
          }
        }
      }, console.log);

      // fetch countries
      this.get('ajax').request('v2/admin/countries').then(function (response) {
        controller.set("countries", response);
      }, console.log);
    }
  });
});