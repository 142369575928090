define('admin-portal/pods/consumer/promo-codes/controller', ['exports', 'ember', 'admin-portal/shapes/promo-code', 'moment', 'ember-ajax/errors'], function (exports, _ember, _adminPortalShapesPromoCode, _moment, _emberAjaxErrors) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    notify: _ember['default'].inject.service(),

    q: "",
    queryParams: ['q', "page"],

    serviceTypes: [{
      id: null,
      name: "All Services"
    }, {
      id: 0,
      name: "Domestic"
    }, {
      id: 2,
      name: "International"
    }],

    loading: false,
    page: 1,
    totalPages: 1,
    codes: _ember['default'].A([]),

    'new': null,
    edit: null,

    today: (0, _moment['default'])().toDate(),

    exportURL: _ember['default'].computed('session.data.authenticated', 'config.apiURL', 'q', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      return this.get("config.apiURL") + '/v2/consumerPortal/admin/promoCodes/export?' + (this.get("q").length > 0 ? 'q=' + this.get("q") + '&' : "") + 'token=' + jwt;
    }),

    init: function init() {
      this.set("new", _adminPortalShapesPromoCode['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
        serviceType: this.get("serviceTypes")[0]
      }));
    },

    actions: {
      search: function search() {
        this.send("getPage", 1);
      },

      changeActive: function changeActive(code) {
        var _this = this;

        _ember['default'].set(code, "explicitlyDisabled", !code.explicitlyDisabled);
        this.get("loadingSpinner").show();
        this.get('ajax').post('v2/consumerPortal/admin/promoCodes/' + code.id + '/' + (code.explicitlyDisabled ? "disable" : "enable"), {
          dataType: "text"
        }).then(function () /*dl*/{
          _this.send("getPage", 1);
          _this.get("loadingSpinner").hide();
        }, function (error) {
          if ((0, _emberAjaxErrors.isConflictError)(error)) {
            _this.get("notify").error("Conflicting Promo Code");
          } else {
            _this.get("notify").error("Unknown error");
          }
          _ember['default'].set(code, "explicitlyDisabled", !code.explicitlyDisabled);
          _this.get("loadingSpinner").hide();
        });
      },

      createOpen: function createOpen() {
        this.set("new", _adminPortalShapesPromoCode['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
          serviceType: this.get("serviceTypes")[0]
        }));
        _ember['default'].$('.ui.create-promo-code.modal').modal('show');
      },

      setNewType: function setNewType(type) {
        this.set("new.type", type);
      },

      setEditType: function setEditType(type) {
        this.set("edit.type", type);
      },

      create: function create() {
        var _this2 = this;

        this.get("loadingSpinner").show();
        this.get('ajax').post('v2/consumerPortal/admin/promoCodes', {
          data: JSON.stringify(this.get("new").toJSON()),
          contentType: "application/json",
          crossDomain: true
        }).then(function () /*dl*/{
          _this2.send("getPage", 1);
          _ember['default'].$('.ui.create-promo-code.modal').modal('hide');
          _this2.set("new", _adminPortalShapesPromoCode['default'].create(_ember['default'].getOwner(_this2).ownerInjection()));
          _this2.get("loadingSpinner").hide();
          _this2.get("notify").success('Successfully added.');
        }, function () /*error*/{
          _this2.get("loadingSpinner").hide();
          _this2.get("notify").error('Error adding.');
        });
      },

      closeCreate: function closeCreate() {
        _ember['default'].$('.ui.create-promo-code.modal').modal('hide');
      },

      editOpen: function editOpen(code) {
        this.set("edit", _adminPortalShapesPromoCode['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
          id: code.id,
          code: code.code,
          description: code.description,
          type: code.discount.flatDiscount ? "Fixed" : "Percentage",
          flatDiscount: code.discount.flatDiscount ? code.discount.flatDiscount / 100 : null,
          fractionalDiscount: code.discount.fractionalDiscount ? code.discount.fractionalDiscount * 100 : null,
          userLimit: code.usageLimits.userLimit,
          userLimitUnlimited: code.usageLimits.userLimit ? false : true,
          overallLimit: code.usageLimits.overallLimit,
          overallLimitUnlimited: code.usageLimits.overallLimit ? false : true,
          promoStart: (0, _moment['default'])(code.promoStart).toDate(),
          promoEnd: code.promoEnd ? (0, _moment['default'])(code.promoEnd).toDate() : null,
          promoNeverEnds: code.promoEnd ? false : true,
          serviceType: this.get("serviceTypes").find(function (s) {
            return s.id === code.serviceType;
          }) || this.get("serviceTypes")[0]
        }));
        _ember['default'].$('.ui.edit-promo-code.modal').modal('show');
      },

      closeEdit: function closeEdit() {
        this.set("edit", null);
        _ember['default'].$('.ui.edit-promo-code.modal').modal('hide');
      },

      saveEdit: function saveEdit(edit) {
        var _this3 = this;

        this.get("loadingSpinner").show();
        this.get('ajax').put('v2/consumerPortal/admin/promoCodes/' + edit.id, {
          data: JSON.stringify(edit.toJSON()),
          contentType: "application/json",
          crossDomain: true
        }).then(function () /*dl*/{
          _this3.send("getPage", 1);
          _this3.get("loadingSpinner").hide();
          _ember['default'].$('.ui.edit-promo-code.modal').modal('hide');
          _this3.get("notify").success('Successfully edited.');
        }, function () /*error*/{
          _this3.get("loadingSpinner").hide();
          _this3.get("notify").error('Error edited.');
        });
      },

      getPage: function getPage(page) {
        var self = this;
        this.set("loading", true);
        this.get('ajax').request('v2/consumerPortal/admin/promoCodes?page=' + page + '&perPage=10' + (this.get("q") ? '&q=' + this.get("q") : "")).then(function (result) {
          self.set('page', result.page);
          self.set('totalPages', result.totalPages);
          self.set('codes', _ember['default'].A(result.results));
          self.set("loading", false);
        }, function () {
          self.set("loading", false);
        });
      }

    }
  });
});