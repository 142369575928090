define('admin-portal/pods/ecommerce/customer/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    config: _ember['default'].inject.service('config'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),
    loading: _ember['default'].inject.service('loading'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    isBillingOpen: false,
    //couriers_list : null,
    selectedEcomCourierName: null,
    products_list: null,
    billable_services: ["Software", "Transactions", "Support", "Development", "Other"],
    integration_methods: ["Standard API", "Custom API", "Shopify", "WooCommerce", "Magento", "Other"],
    product_payment_methods: ["Customer Pays", "Consumer Pays", "Combination"],
    skin_background_options: ["Image", "Color"],
    return_carrier_partners: [{ name: "Toll", code: "toll" }],
    skinBgImageBool: true,
    skinBgColorBool: false,
    selectedEcomCourier: null,
    isSavingBool: false,
    return_types: ["RMA", "Pre-Auth RMA", "No RMA"],
    returnPortalTemplateArr: config.returnPortalTemplate,
    timezone_list: config.timezone_list,
    returnLocations: [],
    notValidReturnLocation: false,
    showPageSetupUI: false,
    clickCollectPageSetupUI: false,
    returnPageSetupUI: false,

    actions: {
      selected_template: function selected_template(val) {
        this.set("developerAccount.template", val);
        if (val === "default") {
          this.set("defaultTemplateBool", true);
        } else {
          this.set("defaultTemplateBool", false);
        }
      },
      skinBackgroundOption: function skinBackgroundOption(data) {
        this.set("developerAccount.return_skin_background", "");
        if (data === "Color") {
          this.set("skinBgColorBool", true);
          this.set("skinBgImageBool", false);
        } else {
          this.set("skinBgColorBool", false);
          this.set("skinBgImageBool", true);
        }
      },
      removeLocationModalOpen: function removeLocationModalOpen(returnLocation) {
        this.set("eRemoveLocationDetails", returnLocation);
        _ember['default'].$('.ui.modal.ecommerce-remove-location').modal('show');
      },
      removeLocation: function removeLocation(returnLocation) {
        this.get('returnLocations').removeObject(returnLocation);
        this.set("eRemoveLocationDetails", null);
        _ember['default'].$('.ui.modal.ecommerce-remove-location').modal('hide');
      },
      cancelLocation: function cancelLocation() {
        this.get('returnLocations').addObject(this.get('hiddenReturnLocationData'));
        this.set("updateLocationBool", false);
        this.set('notValidReturnLocation', false);
        this.set("hiddenReturnLocationData", null);
        this.set('developerAccount.location_name', null);
        this.set('developerAccount.location_phone', null);
        this.set('developerAccount.location_reference', null);
        this.set('developerAccount.location_address', null);
        this.set('developerAccount.location_suburb', null);
        this.set('developerAccount.location_state', null);
        this.set('developerAccount.location_postcode', null);
        this.set('developerAccount.location_country', null);
      },
      updateLocation: function updateLocation(returnLocation) {
        this.set("updateLocationBool", true);
        this.set('notValidReturnLocation', false);
        this.set("hiddenReturnLocationData", returnLocation);
        this.set('developerAccount.location_name', returnLocation.name);
        this.set('developerAccount.location_phone', returnLocation.phone);
        this.set('developerAccount.location_reference', returnLocation.reference);
        this.set('developerAccount.location_address', returnLocation.address);
        this.set('developerAccount.location_suburb', returnLocation.suburb);
        this.set('developerAccount.location_state', returnLocation.state);
        this.set('developerAccount.location_postcode', returnLocation.postcode);
        this.set('developerAccount.location_country', returnLocation.country);
        this.get('returnLocations').removeObject(returnLocation);
      },
      addLocation: function addLocation(newcourier) {
        this.set('returnLocationErrorStr', "Please add all the required information.");
        this.set('notValidReturnLocation', false);

        var location_reference = newcourier.location_reference;
        if (newcourier.location_name !== null && newcourier.location_phone !== null && newcourier.location_address !== null && newcourier.location_suburb !== null && newcourier.location_state !== null && newcourier.location_postcode !== null && newcourier.location_country !== null && newcourier.location_name !== undefined && newcourier.location_phone !== undefined && newcourier.location_address !== undefined && newcourier.location_suburb !== undefined && newcourier.location_state !== undefined && newcourier.location_postcode !== undefined && newcourier.location_country !== undefined && newcourier.location_name.trim() !== undefined && newcourier.location_phone.trim() !== undefined && newcourier.location_address.trim() !== undefined && newcourier.location_suburb.trim() !== undefined && newcourier.location_state.trim() !== undefined && newcourier.location_postcode.trim() !== undefined && newcourier.location_country.trim() !== undefined && newcourier.location_name.trim() !== "" && newcourier.location_phone.trim() !== "" && newcourier.location_address.trim() !== "" && newcourier.location_suburb.trim() !== "" && newcourier.location_state.trim() !== "" && newcourier.location_postcode.trim() !== "" && newcourier.location_country.trim() !== "") {
          //var regName = /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/;
          var regName = /^[a-zA-Z0-9]+ [a-zA-Z0-9]+$/;
          var onlyAlphanumberic = /^[0-9a-zA-Z ]+$/;
          var multipleSpaceRegex = /\s{2,}/g;

          if (multipleSpaceRegex.test(newcourier.location_name)) {
            this.set('returnLocationErrorStr', "Multiple spaces in name.");
            this.set('notValidReturnLocation', true);
          } else if (!onlyAlphanumberic.test(newcourier.location_name)) {
            this.set('returnLocationErrorStr', "Only Alphanumeric is allowed for name.");
            this.set('notValidReturnLocation', true);
          } else if (!regName.test(newcourier.location_name)) {
            this.set('returnLocationErrorStr', "Please enter name as 2 words.");
            this.set('notValidReturnLocation', true);
          } else if (location_reference !== null && location_reference !== undefined && location_reference !== "" && location_reference.length > 31) {
            this.set('returnLocationErrorStr', "Maximum 30 characters are allowed for reference.");
            this.set('notValidReturnLocation', true);
          } else {
            this.set("updateLocationBool", false);
            this.set('notValidReturnLocation', false);
            var dataObj = {};
            dataObj['name'] = newcourier.location_name;
            var fullnameArr = newcourier.location_name.split(' ');
            var lastNameStr = '';
            for (var i = 1; i < fullnameArr.length; i++) {
              lastNameStr += fullnameArr[i] + " ";
            }
            dataObj['firstname'] = fullnameArr[0];
            dataObj['lastname'] = lastNameStr.trim();
            dataObj['phone'] = newcourier.location_phone.trim();

            if (location_reference !== null && location_reference !== undefined && location_reference !== "") {
              location_reference = newcourier.location_reference.trim();
            }
            dataObj['reference'] = location_reference;
            dataObj['address'] = newcourier.location_address.trim();
            dataObj['suburb'] = newcourier.location_suburb.trim();
            dataObj['state'] = newcourier.location_state.trim();
            dataObj['postcode'] = newcourier.location_postcode.trim();
            dataObj['country'] = newcourier.location_country.trim();
            this.set('developerAccount.location_name', null);
            this.set('developerAccount.location_phone', null);
            this.set('developerAccount.location_reference', null);
            this.set('developerAccount.location_address', null);
            this.set('developerAccount.location_suburb', null);
            this.set('developerAccount.location_state', null);
            this.set('developerAccount.location_postcode', null);
            this.set('developerAccount.location_country', null);
            this.set("hiddenReturnLocationData", null);
            newcourier = '';
            this.get('returnLocations').addObject(dataObj);
          }
        } else {
          this.set('notValidReturnLocation', true);
        }
      },
      billingOpen: function billingOpen(developerAccount) {
        if (this.get('isBillingOpen')) {
          this.set('isBillingOpen', false);
          this.set('developerAccount.billing_contactname', null);
          this.set('developerAccount.billing_email', null);
          this.set('developerAccount.billing_phone', null);
          this.set('developerAccount.billing_position', null);
          this.set('developerAccount.billing_address', null);
          this.set('developerAccount.billing_address2', null);
          this.set('developerAccount.billing_suburb', null);
          this.set('developerAccount.billing_state', null);
          this.set('developerAccount.billing_postcode', null);
          this.set('developerAccount.billing_country', null);
        } else {
          this.set('isBillingOpen', true);
          this.set('developerAccount.billing_contactname', developerAccount.contact_name);
          this.set('developerAccount.billing_email', developerAccount.contact_email);
          this.set('developerAccount.billing_phone', developerAccount.contact_phone);
          this.set('developerAccount.billing_position', developerAccount.contact_position);
          this.set('developerAccount.billing_address', developerAccount.contact_address);
          this.set('developerAccount.billing_address2', developerAccount.contact_address2);
          this.set('developerAccount.billing_suburb', developerAccount.contact_suburb);
          this.set('developerAccount.billing_state', developerAccount.contact_state);
          this.set('developerAccount.billing_postcode', developerAccount.contact_postcode);
          this.set('developerAccount.billing_country', developerAccount.contact_country);
        }
      },
      populateBillingAddress: function populateBillingAddress(developerAccount) {
        if (this.get('isBillingOpen')) {
          this.set('developerAccount.billing_contactname', developerAccount.contact_name);
          this.set('developerAccount.billing_email', developerAccount.contact_email);
          this.set('developerAccount.billing_phone', developerAccount.contact_phone);
          this.set('developerAccount.billing_position', developerAccount.contact_position);
          this.set('developerAccount.billing_address', developerAccount.contact_address);
          this.set('developerAccount.billing_address2', developerAccount.contact_address2);
          this.set('developerAccount.billing_suburb', developerAccount.contact_suburb);
          this.set('developerAccount.billing_state', developerAccount.contact_state);
          this.set('developerAccount.billing_postcode', developerAccount.contact_postcode);
          this.set('developerAccount.billing_country', developerAccount.contact_country);
        }
      },
      colorChanged: function colorChanged(e) {
        this.set("developerAccount." + e, this.get(e));
      },
      setCountry: function setCountry(country_name) {
        this.set('developerAccount.contact_country', country_name);
      },
      setCounsolidatedAddress: function setCounsolidatedAddress(consolidated_addressId) {
        this.set('consolidatedLocationAddresssId', consolidated_addressId);
      },
      checkForProducts: function checkForProducts(e) {
        var self = this;
        self.get("products_list").map(function (a) {
          if (a.product_id === e) {
            _ember['default'].set(a, 'enabled', !a.enabled);
          }
          if (a.enabled === true && a.product_name === "Click & Collect") {
            self.set('clickCollectPageSetupUI', true);
          } else if (a.enabled === false && a.product_name === "Click & Collect") {
            self.set('clickCollectPageSetupUI', false);
          }
          if (a.enabled === true && a.product_name === "Return") {
            self.set('returnPageSetupUI', true);
          } else if (a.enabled === false && a.product_name === "Return") {
            self.set('returnPageSetupUI', false);
          }
          if (self.get('returnPageSetupUI') === true || self.get('clickCollectPageSetupUI') === true) {
            self.set('showPageSetupUI', true);
          } else {
            self.set('showPageSetupUI', false);
          }
        });
      },
      create: function create() {
        var _this = this;

        var self = this;
        var webhook_events = self.get("webhookEventList").map(function (a) {
          if (a.active) {
            return {
              "active": true,
              "event_id": Number(a.event_id)
            };
          } else {
            return {
              "active": false,
              "event_id": Number(a.event_id)
            };
          }
        });
        var products_list = self.get("products_list").map(function (a) {
          if (a.enabled) {
            return {
              "enabled": true,
              "self_enabled": true,
              "product_id": Number(a.product_id)
            };
          } else {
            return {
              "enabled": false,
              "self_enabled": false,
              "product_id": Number(a.product_id)
            };
          }
        });

        var channelListArr = [];
        if (this.get("channel_list")) {
          channelListArr = this.get("channel_list").map(function (a) {
            if (a.active) {
              return {
                "id": 0,
                "channel_id": a.channel_id,
                "active": true,
                "self_active": true
              };
            } else {
              return {
                "id": 0,
                "channel_id": a.channel_id,
                "active": false,
                "self_active": false
              };
            }
          });
        }
        var getinvalue = this.get('developerAccount.title');
        if (this.get("developerAccount.title") !== null && this.get("developerAccount.title") !== undefined && this.get("developerAccount.title") !== "") {
          getinvalue = getinvalue.replace(/(<([^>]+)>)/gi, "");
          this.set('developerAccount.title', getinvalue);
        }

        var getabout = this.get('developerAccount.about_us');
        if (this.get("developerAccount.about_us") !== null && this.get("developerAccount.about_us") !== undefined && this.get("developerAccount.about_us") !== "") {
          getabout = getabout.replace(/(<([^>]+)>)/gi, "");
          this.set('developerAccount.about_us', getabout);
        }

        var returnLocationsData = this.get('returnLocations');
        if (this.get('returnPageSetupUI') !== true) {
          returnLocationsData = [];
        }
        var saveAccountBool = false,
            rateValidationBool = false;
        if (returnLocationsData.length > 0 && this.get('returnPageSetupUI')) {
          saveAccountBool = true;
        } else if (this.get('returnPageSetupUI') !== true) {
          saveAccountBool = true;
        }

        if (this.get("developerAccount.sale_rate") < 0) {
          saveAccountBool = false;
          rateValidationBool = true;
        }
        if (this.get("developerAccount.buy_rate") < 0) {
          saveAccountBool = false;
          rateValidationBool = true;
        }
        if (this.get("developerAccount.additional_charge") < 0) {
          saveAccountBool = false;
          rateValidationBool = true;
        }
        var sale_rate_val = 0,
            buy_rate_val = 0,
            additional_charge_val = 0;
        if (this.get("developerAccount.sale_rate") !== null && this.get("developerAccount.sale_rate") !== undefined && this.get("developerAccount.sale_rate") !== "") {
          sale_rate_val = parseFloat(this.get("developerAccount.sale_rate"));
        }
        if (this.get("developerAccount.buy_rate") !== null && this.get("developerAccount.buy_rate") !== undefined && this.get("developerAccount.buy_rate") !== "") {
          buy_rate_val = parseFloat(this.get("developerAccount.buy_rate"));
        }
        if (this.get("developerAccount.additional_charge") !== "" && this.get("developerAccount.additional_charge") !== undefined && this.get("developerAccount.additional_charge") !== null) {
          additional_charge_val = parseFloat(this.get("developerAccount.additional_charge"));
        }
        if (saveAccountBool) {
          this.set("isSavingBool", true);
          this.get('ajax').post(this.get("config.carrierApiURL") + 'ecommerceuser/create', {
            headers: {
              'Accept': 'application/json',
              'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
            },
            data: JSON.stringify({
              role_id: this.get('role_id'),
              hubbed_user_id: this.get('selectedEcomCourier'),
              is_deleted: false,
              isLinkedCourier: true,
              company_name: this.get('developerAccount.company_name'),
              timezoneId: this.get('developerAccount.timezoneId'),
              notification_username: this.get('developerAccount.notification_username'),
              notification_password: this.get('developerAccount.notification_password'),
              contact_name: this.get("developerAccount.contact_name"),
              contact_position: this.get("developerAccount.contact_position"),
              contact_phone: this.get("developerAccount.contact_phone"),
              contact_email: this.get("developerAccount.contact_email"),
              active: this.get("developerAccount.active") && this.get("developerAccount.active") !== "" && this.get("developerAccount.active") !== undefined ? this.get("developerAccount.active") : false,
              send_outbound_notification: this.get("developerAccount.send_outbound_notification") && this.get("developerAccount.send_outbound_notification") !== "" && this.get("developerAccount.send_outbound_notification") !== undefined ? this.get("developerAccount.send_outbound_notification") : false,
              payment_rate_type: this.get("developerAccount.enable_payment") && this.get("developerAccount.enable_payment") !== "" && this.get("developerAccount.enable_payment") !== undefined ? 1 : 0,
              carrier_partner: this.get("developerAccount.carrier_partner") && this.get("developerAccount.carrier_partner") !== "" && this.get("developerAccount.carrier_partner") !== undefined ? this.get("developerAccount.carrier_partner") : "",
              contact_address: this.get('developerAccount.contact_address'),
              contact_address2: this.get('developerAccount.contact_address2'),
              contact_suburb: this.get('developerAccount.contact_suburb'),
              contact_state: this.get("developerAccount.contact_state"),
              contact_postcode: this.get('developerAccount.contact_postcode'),
              contact_country: this.get("developerAccount.contact_country"),
              billing_contactname: this.get("developerAccount.billing_contactname"),
              template: this.get("developerAccount.template"),
              billing_position: this.get("developerAccount.billing_position"),
              billing_phone: this.get("developerAccount.billing_phone"),
              billing_email: this.get("developerAccount.billing_email"),
              billing_address: this.get('developerAccount.billing_address'),
              billing_address2: this.get('developerAccount.billing_address2'),
              billing_suburb: this.get('developerAccount.billing_suburb'),
              billing_state: this.get("developerAccount.billing_state"),
              billing_postcode: this.get('developerAccount.billing_postcode'),
              billing_country: this.get("developerAccount.billing_country"),
              notification_url: this.get("developerAccount.notification_url"),
              isCustomNetwork: this.get("developerAccount.customNetwork") && this.get("developerAccount.customNetwork") === 1 ? true : false,
              logo: this.get("developerAccount.logo"),
              send_notification: true,
              duplicate_allowed_days: 0,
              ecommerceProductMapping: products_list,
              WebhookEventMapping: webhook_events,
              ecommerceAccountSettings: {
                logo: this.get("developerAccount.logo"),
                termsofserviceurl: this.get("developerAccount.terms_of_service"),
                returnpolicy: this.get("developerAccount.returnpolicy"),
                parcelinstructionsurl: this.get("developerAccount.parcel_info"),
                name: this.get("developerAccount.return_consumer_name"),
                website: this.get("developerAccount.website"),
                aboutus: this.get("developerAccount.about_us"),
                title: this.get("developerAccount.title"),
                fontcolor: this.get("developerAccount.fontcolor"),
                buttoncolor: this.get("developerAccount.buttoncolor"),
                skinbackground: this.get("developerAccount.return_skin_background"),
                backgroundcolor: this.get("developerAccount.backgroundcolor"),
                headertextcolor: this.get("developerAccount.headertextcolor"),
                stepsbackgroundcolor: this.get("developerAccount.stepsbackgroundcolor"),
                return_type: this.get("developerAccount.return_type"),
                cc_location_map: this.get("developerAccount.cc_location_map") && this.get("developerAccount.cc_location_map") !== "" && this.get("developerAccount.cc_location_map") !== undefined ? this.get("developerAccount.cc_location_map") : false
              },
              ecommerceLocationMapping: returnLocationsData,
              fixedRates: {
                id: 0,
                hubbed_user_id: 0,
                sale_rate: sale_rate_val,
                buy_rate: buy_rate_val,
                additional_charge: additional_charge_val
              },
              consoliAddressID: this.get('consolidatedLocationAddresssId'),
              integration_method: this.get("developerAccount.integration_method"),
              product_payment_method: this.get("developerAccount.product_payment_method"),
              ecommerceChannelMapping: channelListArr
            }),
            contentType: 'application/json; charset=utf-8',
            crossDomain: true
          }).then(function (data) {
            _this.set("isSavingBool", false);
            if (data.responseCode === "200" || data.responseCode === 200) {
              _this.set('developerAccount', '');
              _this.get('notify').success('eCommerce customer has been successfully Updated.');
              _this.transitionToRoute('ecommerce.details', _this.get('selectedEcomCourier'));
            } else {
              _this.get('notify').error(data.responseMessage);
            }
          })['catch'](function (error) {
            _this.set("isSavingBool", false);
            var errorMsg = error.errors.map(function (e) {
              return e.detail;
            });
            if (errorMsg[0] && errorMsg[0].message) {
              _this.get('notify').error(errorMsg[0].message);
            } else if (errorMsg[0] && errorMsg[0].result && errorMsg[0].result.length > 0) {
              _this.get('notify').error(errorMsg[0].result[0].description);
            } else {
              _this.get('notify').error('Error while saving, please try again.');
            }
          });
        } else {
          this.set("isSavingBool", false);
          if (rateValidationBool) {
            this.get('notify').error("Sale rate, buy rate and additional charges can't be negative");
          } else {
            this.get('notify').error('Please add Return Locations');
          }
        }
      }
    }
  });
});