define('admin-portal/pods/lockers/audit-logs/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),
    loadingSpinner: _ember['default'].inject.service('loading'),

    results: null,
    queryParams: ["start", "end", "page"],

    loading: false,
    page: 1,
    totalPages: 1,

    actions: {
      changePage: function changePage(page) {
        this.set('page', page);
        this.send('search');
      },

      search: function search() {
        var _this = this;

        this.set('loading', true);
        this.get('ajax').request('v2/admin/lockerLogs?page=' + this.get('page') + '&perPage=10').then(function (results) {
          _this.set('page', results.page);
          _this.set('totalPages', results.totalPages);
          _this.set('results', results.results);
          _this.set('loading', false);
        }, function (error) {
          console.log(error);
        });
      }
    }
  });
});