define('admin-portal/pods/components/pagination-component/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['ui pagination menu'],

    hasNextPage: (function () {
      return this.get('page') < this.get('totalPages');
    }).property('page', 'totalPages'),

    hasPreviousPage: (function () {
      return this.get('page') > 1;
    }).property('page', 'totalPages'),

    actions: {
      nextPage: function nextPage() {
        this.get('getPageAction')(this.get('page') + 1);
        this.rerender();
      },
      previousPage: function previousPage() {
        this.get('getPageAction')(this.get('page') - 1);
        this.rerender();
      }
    }
  });
});