define('admin-portal/shapes/developer-account', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  var Validations = (0, _emberCpValidations.buildValidations)({
    company_name: {
      description: "Company Name",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false }), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    timezoneId: [(0, _emberCpValidations.validator)('presence', true)],
    contact_name: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false }), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z][a-zA-Z' \-]*$/,
        message: 'Invalid characters'
      })]
    },
    billing_contactname: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false }), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z][a-zA-Z' \-]*$/,
        message: 'Invalid characters'
      })]
    },
    contact_position: {
      description: "Position",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false }), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z][a-zA-Z' \-]*$/,
        message: 'Invalid characters'
      })]
    },
    contact_phone: {
      description: "Phone",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 8,
        max: 12
      })]
    },
    billing_email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      message: 'Invalid email eg. abc@domain.com'
    })],
    billing_phone: {
      description: "Phone",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 8,
        max: 12
      })]
    },
    contact_email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      message: 'Invalid email eg. abc@domain.com'
    })],
    contact_address: {
      description: "Address Line",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    contact_suburb: {
      description: "Suburb",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    contact_postcode: {
      description: "Postcode",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    contact_state: {
      description: "State",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      }), (0, _emberCpValidations.validator)('length', {
        max: 3
      })]
    },
    contact_country: {
      description: "Country",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_address: {
      description: "Address Line",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_suburb: {
      description: "Suburb",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_postcode: {
      description: "Postcode",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_state: {
      description: "State",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      }), (0, _emberCpValidations.validator)('length', {
        max: 3
      })]
    },
    billing_country: {
      description: "Country",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    location_state: {
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 3
      })]
    },
    location_reference: {
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 30
      })]
    },
    about_us: {
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 120
      })]
    },
    website: [(0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /\b((http|https|HTTP|HTTPS):\/\/?)[^\s()<>]+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
      message: 'Invalid URL'
    })],
    logo: [(0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /\b((http|https|HTTP|HTTPS):\/\/?)[^\s()<>]+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
      //regex :  /^((http(s?)|HTTP(S?)?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
      // regex :  /^((http(s?)|HTTP(S?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g,
      message: 'Invalid URL'
    })],
    returnpolicy: [(0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /\b((http|https|HTTP|HTTPS):\/\/?)[^\s()<>]+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
      message: 'Invalid URL'
    })],
    terms_of_service: [(0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /\b((http|https|HTTP|HTTPS):\/\/?)[^\s()<>]+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
      message: 'Invalid URL'
    })],
    parcel_info: [(0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /\b((http|https|HTTP|HTTPS):\/\/?)[^\s()<>]+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
      message: 'Invalid URL'
    })],
    notification_url: {
      description: "Notification URL",
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        regex: /\b((http|https|HTTP|HTTPS):\/\/?)[^\s()<>]+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
        message: 'Invalid URL'
      }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
        var username = model.get('notification_username');

        if (value !== undefined && value !== "" && value !== null) {
          if (username === undefined || username === "" || username === null) {
            return 'Please enter user name to enable webhook notifications';
          }
        }

        return true;
      }, {
        dependentKeys: ['model.notification_url', 'model.notification_username', 'model.notification_password']
      })]
    },
    notification_username: {
      description: "User Name",
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true
      }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
        var notification_password = model.get('notification_password');
        var notification_url = model.get('notification_url');

        if (value !== undefined && value !== "" && value !== null) {
          if (notification_password === undefined || notification_password === "" || notification_password === null) {
            return 'Please enter password to enable webhook notifications';
          }

          if (notification_url === undefined || notification_url === "" || notification_url === null) {
            return 'Please enter notification url to enable webhook notifications';
          }
        }
        return true;
      }, {
        dependentKeys: ['model.notification_username', 'model.notification_url', 'model.notification_password']
      })]
    },
    notification_password: {
      description: "Notification Password",
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true
      }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
        var notification_url = model.get('notification_url');

        if (value !== undefined && value !== "" && value !== null) {
          if (notification_url === undefined || notification_url === "" || notification_url === null) {
            return 'Please enter notification url to enable webhook notifications';
          }
        }
        return true;
      }, {
        dependentKeys: ['model.notification_password', 'model.notification_url', 'model.notification_username']
      })]
    }
  });
  exports['default'] = _ember['default'].Object.extend(Validations, {
    contact_name: null,
    billing_contactname: null,
    contact_position: null,
    company_name: null,
    contact_phone: null, billing_phone: null,
    contact_email: null, billing_email: null,
    contact_address: null,
    contact_suburb: null,
    contact_postcode: null,
    contact_state: null,
    contact_country: null,
    billing_address: null,
    billing_suburb: null,
    billing_postcode: null,
    billing_state: null,
    billing_country: null
  });
});