define('admin-portal/shapes/ecommerce-courier', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  var Validations = (0, _emberCpValidations.buildValidations)({
    customername: {
      description: "Partner Name",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false }), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    timezone: [(0, _emberCpValidations.validator)('presence', true)],
    logo: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      // type : 'url',
      // regex :  /^((http(s?)|HTTP(S?)?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
      regex: /\b((http|https|HTTP|HTTPS):\/\/?)[^\s()<>]+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
      message: 'Invalid URL'
    })],
    contactname: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false }), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z][a-zA-Z' \-]*$/,
        message: 'Invalid characters'
      })]
    },
    billing_contactname: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false }), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z][a-zA-Z' \-]*$/,
        message: 'Invalid characters'
      })]
    },
    // courier_code: {
    //   description: "Courier",
    //   validators: [
    //     validator('presence', { presence: true, ignoreBlank: false })
    //   ]
    // }, 
    position: {
      description: "Position",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false }), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z][a-zA-Z' \-]*$/,
        message: 'Invalid characters'
      })]
    },
    phone: {
      description: "Phone",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 8,
        max: 12
      })]
    },
    billing_email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      message: 'Invalid email eg. abc@domain.com'
    })],
    billing_phone: {
      description: "Phone",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 8,
        max: 12
      })]
    },
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      message: 'Invalid email eg. abc@domain.com'
    })],
    address1: {
      description: "Address Line",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    suburb: {
      description: "Suburb",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    postcode: {
      description: "Postcode",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    state: {
      description: "State",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      }), (0, _emberCpValidations.validator)('length', {
        max: 3
      })]
    },
    country: {
      description: "Country",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_address: {
      description: "Address Line",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_suburb: {
      description: "Suburb",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_postcode: {
      description: "Postcode",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_state: {
      description: "State",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      }), (0, _emberCpValidations.validator)('length', {
        max: 3
      })]
    },
    billing_country: {
      description: "Country",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    notificationURL: {
      description: "Notification URL",
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        regex: /\b((http|https|HTTP|HTTPS):\/\/?)[^\s()<>]+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
        message: 'Invalid URL'
      }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
        var username = model.get('notificationUserName');

        if (value !== undefined && value !== "" && value !== null) {
          if (username === undefined || username === "" || username === null) {
            return 'Please enter user name to enable webhook notifications';
          }
        }

        return true;
      }, {
        dependentKeys: ['model.notificationURL', 'model.notificationUserName', 'model.notificationPassword']
      })]
    },
    notificationUserName: {
      description: "User Name",
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true
      }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
        var notificationPassword = model.get('notificationPassword');
        var notificationURL = model.get('notificationURL');

        if (value !== undefined && value !== "" && value !== null) {
          if (notificationPassword === undefined || notificationPassword === "" || notificationPassword === null) {
            return 'Please enter password to enable webhook notifications';
          }

          if (notificationURL === undefined || notificationURL === "" || notificationURL === null) {
            return 'Please enter notification url to enable webhook notifications';
          }
        }
        return true;
      }, {
        dependentKeys: ['model.notificationUserName', 'model.notificationURL', 'model.notificationPassword']
      })]
    },
    notificationPassword: {
      description: "Notification Password",
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true
      }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
        var notificationURL = model.get('notificationURL');

        if (value !== undefined && value !== "" && value !== null) {
          if (notificationURL === undefined || notificationURL === "" || notificationURL === null) {
            return 'Please enter notification url to enable webhook notifications';
          }
        }
        return true;
      }, {
        dependentKeys: ['model.notificationPassword', 'model.notificationURL', 'model.notificationUserName']
      })]
    }
  });
  exports['default'] = _ember['default'].Object.extend(Validations, {
    contactname: null,
    billing_contactname: null,
    phone: null, billing_phone: null,
    email: null, billing_email: null,
    address1: null,
    suburb: null,
    postcode: null,
    state: null,
    country: null,
    billing_address: null,
    billing_suburb: null,
    billing_postcode: null,
    billing_state: null,
    billing_country: null
  });
});