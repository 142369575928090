define("admin-portal/helpers/toString-helper", ["exports", "ember"], function (exports, _ember) {
	exports.toStringHelper = toStringHelper;

	function toStringHelper(param) {
		if (param[0] !== undefined && param[0] !== null && param[0] !== "") {
			return param[0].toFixed(2);
		} else {
			return param[0];
		}
	}

	exports["default"] = _ember["default"].Helper.helper(toStringHelper);
});