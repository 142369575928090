define('admin-portal/pods/ecommerce/list/controller', ['exports', 'ember', 'ember-ajax/errors'], function (exports, _ember, _emberAjaxErrors) {
  exports['default'] = _ember['default'].Controller.extend({
    config: _ember['default'].inject.service(),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    notify: _ember['default'].inject.service(),

    loading: false,
    page: 1,
    totalPages: 1,
    couriersData: _ember['default'].A([]),
    courierDeletionDetails: null,

    buildUrl: function buildUrl(page) {
      var url = this.get("config.carrierApiURL") + 'ecommerceuser/getlist?userType=partner&pageno=' + page + '&perpage=20';
      if (this.get('q') !== '' && this.get('q') !== undefined) {
        url += '&searchby=' + this.get("q").trim();
      }
      if (this.get('orderBy') !== '' && this.get('orderBy') !== undefined) {
        url += '&orderBy=' + this.get("orderBy");
      }
      if (this.get('order') !== '' && this.get('order') !== undefined) {
        url += '&order=' + this.get("order");
      }

      return url;
    },
    actions: {
      ecommerceUserDelete: function ecommerceUserDelete(data) {
        var _this = this;

        this.get("loadingSpinner").show();
        this.get('ajax').request(this.get("config.carrierApiURL") + 'developeraccount/isenable?id=' + data.id + '&IsEnable=' + data.enabled + '&isLocked=true', {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          }
        }).then(function () /*result*/{
          _this.get('notify').success(data.companyname + ' is Successfully deleted.');
          _this.set("eUserDeletionDetails", null);
          _ember['default'].$('.ui.modal.ecommerce-user-deletion').modal('hide');
          _this.get("loadingSpinner").show();
          _this.send("getPage", _this.get('page'));
        }, function () /*error*/{
          _this.get('notify').error('Could not delete ' + data.companyname + '. Try refreshing this page.');
          _this.get("loadingSpinner").hide();
        });
      },
      deleteChildModalOpen: function deleteChildModalOpen(courierDetails) {
        courierDetails['companyname'] = courierDetails['name'];
        this.set("eUserDeletionDetails", courierDetails);
        _ember['default'].$('.ui.modal.ecommerce-user-deletion').modal('show');
      },
      getPage: function getPage(page) {
        var self = this;
        self.set("loading", true);
        this.get('ajax').request(this.buildUrl(page), {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          }
        }).then(function (responseData) {
          if (responseData && responseData.result) {
            var result = responseData.result;
            self.set('page', result.pageno);
            if (result.pageno === 1 && result.totalPages === 0) {
              self.set('totalPages', 1);
            } else {
              self.set('totalPages', result.totalPages);
            }
            self.set('couriersData', _ember['default'].A(result.query));
            self.set("loading", false);
            self.get("loadingSpinner").hide();
          } else {
            self.set("loading", false);
          }
        }, function () /*error*/{
          self.set("loading", false);
        });
      },
      ecommerceCourierDelete: function ecommerceCourierDelete(objData) {
        var _this2 = this;

        this.get("loadingSpinner").show();
        this.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/SoftDeleteUser/' + objData.hubbed_user_id, {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          }
        }).then(function () /*result*/{
          //this.get('ajax').delete(`v2/admin/couriers/${objData.hubbed_user_id}`, {
          //  dataType: "text"
          //}).then(() => {

          _this2.get('notify').success(objData.company_name + ' is Successfully deleted.');
          _this2.set("courierDeletionDetails", null);
          _ember['default'].$('.ui.modal.ecommerce-courier-deletion').modal('hide');
          _this2.get("loadingSpinner").show();
          _this2.send("getPage", _this2.get('page'));
        }, function () /*error*/{
          _this2.get('notify').error('Could not delete ' + objData.company_name + '. Try refreshing this page.');
          _this2.get("loadingSpinner").hide();
        });
      },
      changeActiveEcommCourier: function changeActiveEcommCourier(data) {
        var _this3 = this;

        _ember['default'].set(data, "enabled", !data.enabled);
        this.get("loadingSpinner").show();
        this.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/isactive?hubbed_user_id=' + data.hubbed_user_id + '&status=' + data.enabled, {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          }
        }).then(function () /*result*/{
          //this.send("getPage", this.get('page'));
          _this3.get("loadingSpinner").hide();
        }, function (error) {
          if ((0, _emberAjaxErrors.isConflictError)(error)) {
            _this3.get("notify").error("Conflicting in Updation");
          } else {
            _this3.get("notify").error("Unknown error");
          }
          _ember['default'].set(data, "enabled", !data.enabled);
          _this3.get("loadingSpinner").hide();
        });
      },
      search: function search() {
        this.send("getPage", 1);
      },
      orderBy: function orderBy(orderValue, orderAs) {
        this.set('orderBy', orderValue);
        this.set('order', orderAs);
        this.send("getPage", 1);
      },
      deleteModalOpen: function deleteModalOpen(courierDetails) {
        this.set("courierDeletionDetails", courierDetails);
        _ember['default'].$('.ui.modal.ecommerce-courier-deletion').modal('show');
      }
    }
  });
});