define('admin-portal/pods/ecommerce/view/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    config: _ember['default'].inject.service('config'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    notify: _ember['default'].inject.service(),
    returnPortalTemplateArr: config.returnPortalTemplate,

    actions: {
      backTo: function backTo() {
        this.transitionToRoute('ecommerce.details', this.get("selectedEcomCourier"));
      }
    }
  });
});