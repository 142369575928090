define('admin-portal/pods/components/consignment-info-modal/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    session: _ember['default'].inject.service('session'),
    config: _ember['default'].inject.service('config'),

    name: 'consignment-info',
    classNames: ['consignment-info'],

    consignment: null,

    onHide: function onHide() {
      this.set("consignment", null);
    }
  });
});