define("admin-portal/helpers/in-object", ["exports", "ember"], function (exports, _ember) {
	exports.inObject = inObject;

	function inObject(params) {
		var findValue = params[0];

		var menu_items = [{ id: 16, name: "Reports" }, { id: 17, name: "Invoicing Reports" }, { id: 20, name: "Agent Notification" }, { id: 3, name: "External Couriers" }, { id: 4, name: "eCommerce Partners" }, { id: 5, name: "Network Management" }, { id: 6, name: "Parcel Status" }, { id: 7, name: "Consignments" }, { id: 8, name: "Locations" }, { id: 9, name: "Transactions" }, { id: 10, name: "Consumer Portal" }, { id: 11, name: "Lockers" }, { id: 12, name: "7 Eleven" }, { id: 13, name: "Unlinked Consignments" }, { id: 14, name: "Admin Users" }, { id: 15, name: "Manifest Rules" }];

		for (var i = 0; i < menu_items.length; i++) {
			if (menu_items[i]['name'] === findValue) {
				return menu_items[i]['id'];
			}
		}
		return null;
	}

	exports["default"] = _ember["default"].Helper.helper(inObject);
});