define("admin-portal/helpers/weekday-helper", ["exports", "ember"], function (exports, _ember) {
  exports.weekDayHelper = weekDayHelper;

  function weekDayHelper(param) {
    switch (param[0]) {
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      case 7:
        return "Sunday";
      default:
        return "";
    }
  }

  exports["default"] = _ember["default"].Helper.helper(weekDayHelper);
});