define('admin-portal/pods/lockers/consignments/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service(),

    model: function model(params) {
      return params;
    },

    setupController: function setupController(controller, model) {
      controller.set("locker_id", '');
      controller.send("setLocker", model.id);
      controller.set('consArr', []);

      this.get('ajax').request('v2/admin/' + model.id + '/getDropinandout').then(function (results) {
        if (results && results.length > 0 && results[0].shape.size_class.name == "Drop-in") {
          controller.set("drop_locker_id", results[0].shape.id);
        } else if (results && results.length > 0 && results[1].shape.size_class.name == "Drop-in") {
          controller.set("drop_locker_id", results[1].shape.id);
        }
      }, console.log);
    }
  });
});