define('admin-portal/pods/ecommerce/details/controller', ['exports', 'ember', 'ember-ajax/errors'], function (exports, _ember, _emberAjaxErrors) {
  exports['default'] = _ember['default'].Controller.extend({
    config: _ember['default'].inject.service('config'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    notify: _ember['default'].inject.service(),
    loading: false,
    page: 1,
    totalPages: 1,
    ecommerceData: _ember['default'].A([]),

    buildUrl: function buildUrl(page) {
      var url = this.get("config.carrierApiURL") + 'developeraccount/getlist?page=' + page + '&perPage=20&ecommerceid=' + this.get("selectedEcomCourier");
      if (this.get('q') !== '' && this.get('q') !== undefined) {
        url += '&searchby=' + this.get("q").trim();
      }
      if (this.get('orderBy') !== '' && this.get('orderBy') !== undefined) {
        url += '&orderBy=' + this.get("orderBy");
      }
      if (this.get('order') !== '' && this.get('order') !== undefined) {
        url += '&order=' + this.get("order");
      }
      return url;
    },
    actions: {
      getPage: function getPage(page) {
        var self = this;
        self.set("loading", true);
        this.get('ajax').request(this.buildUrl(page), {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          }
        }).then(function (res) {
          if (res.pageno === 1 && res.totalPages === 0) {
            self.set('totalPages', 1);
          } else {
            self.set('totalPages', res.totalPages);
          }
          self.set('page', res.pageno);
          self.set('ecommerceData', _ember['default'].A(res.result));
          self.set("loading", false);
          self.get("loadingSpinner").hide();
        }, function () /*error*/{
          self.set("loading", false);
        });
      },
      ecommerceUserDelete: function ecommerceUserDelete(objData) {
        var _this = this;

        this.get("loadingSpinner").show();
        // this.get('ajax').delete(`v2/admin/couriers/${objData.hubbed_user_id}`, {
        //   dataType: "text"
        // }).then(() => {
        this.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/SoftDeleteUser/' + objData.hubbed_user_id, {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          }
        }).then(function () /*result*/{
          _this.get('notify').success(objData.company_name + ' is Successfully deleted.');
          _this.set("eUserDeletionDetails", null);
          _ember['default'].$('.ui.modal.ecommerce-user-deletion').modal('hide');
          _this.get("loadingSpinner").hide();
          window.location.reload(true);
          //this.send("getPage",this.get('page')); 
        }, function () /*error*/{
          _this.get('notify').error('Could not delete ' + objData.company_name + '. Try refreshing this page.');
          _this.get("loadingSpinner").hide();
        });
      },
      changeActiveEcommerce: function changeActiveEcommerce(data) {
        var _this2 = this;

        _ember['default'].set(data, "enabled", !data.enabled);
        this.get("loadingSpinner").show();
        this.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/isactive?hubbed_user_id=' + data.hubbed_user_id + '&status=' + data.enabled, {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          }
        }).then(function () /*result*/{
          //this.send("getPage", this.get('page'));
          _this2.get("loadingSpinner").hide();
        }, function (error) {
          if ((0, _emberAjaxErrors.isConflictError)(error)) {
            _this2.get("notify").error("Conflicting in Updation");
          } else {
            _this2.get("notify").error("Unknown error");
          }
          _ember['default'].set(data, "active", !data.active);
          _this2.get("loadingSpinner").hide();
        });
      },
      search: function search() {
        this.send("getPage", 1);
      },
      orderBy: function orderBy(orderValue, orderAs) {
        this.set('orderBy', orderValue);
        this.set('order', orderAs);
        this.send("getPage", 1);
      },
      deleteModalOpen: function deleteModalOpen(courierDetails) {
        this.set("eUserDeletionDetails", courierDetails);
        _ember['default'].$('.ui.modal.ecommerce-user-deletion').modal('show');
      },
      handleEscKey: function handleEscKey(event) {
        if (event.key === "Escape") {
          this.set("apiKeyValue", null);
          this.set("reseetPasswordBool", false);
          this.set("tempPassword", '');
          this.set("isSavingBool", false);
          this.set("productMappingList", null);
          this.set("isBillingOpen", true);
          this.set('populateBillingBool', true);
          _ember['default'].$('body').removeClass('editCourierClass');
          _ember['default'].$('.ui.edit-developer-details.modal').modal('hide');
        }
      },
      openViewModal: function openViewModal(id) {
        var _this3 = this;

        _ember['default'].$('body').addClass('editCourierClass');
        this.set("isGeneratingBool", false);
        this.get("loadingSpinner").show();
        this.get('ajax').request(this.get("config.carrierApiURL") + 'developeraccount/edit/' + id, {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          }
        }).then(function (response) {
          if (response && response.result && response.result !== null) {
            if (response.result && response.result.settings && response.result.settings !== null && response.result.settings['skinbackground'] && response.result.settings['skinbackground'] !== null) {
              var bgData = response.result.settings['skinbackground'];
              var bgColorBool = false;
              if (bgData.indexOf('#') >= 0) {
                bgColorBool = true;
              }
              response.result['skinBgColorBool'] = bgColorBool;
            }
            // if(response.result && response.result.productMapping && response.result.productMapping.length>0){
            //   response.result.productMapping.map(function(a) {    
            //     if(a.enabled===true && a.productname==="Click & Collect") {
            //       response.result['clickCollectPageSetupUI'] = true;
            //     } else if(a.enabled===false && a.productname==="Click & Collect"){
            //       response.result['clickCollectPageSetupUI'] = false;
            //     }
            //     if(a.enabled===true && a.productname==="Return") {
            //       response.result['returnPageSetupUI'] = true;
            //     } else if(a.enabled===false && a.productname==="Return") {
            //       response.result['returnPageSetupUI'] = false;
            //     }
            //     if(response.result['clickCollectPageSetupUI']===true || response.result.returnPageSetupUI===true) {
            //       response.result['showPageSetupUI'] = true;
            //     } else {
            //       response.result['showPageSetupUI'] = false;
            //     }
            //   });
            // }

            if (_this3.get('ecommerceCourierDetails.ecomrproductmappinglist') && _this3.get('ecommerceCourierDetails.ecomrproductmappinglist').length > 0) {
              var tempChildProductMap = [];
              var parentProductsObj = _this3.get('ecommerceCourierDetails.ecomrproductmappinglist');
              parentProductsObj.map(function (a) {
                if (response.result && response.result.productMapping && response.result.productMapping.length > 0) {
                  var childProductBool = false;
                  response.result.productMapping.map(function (b) {
                    if (a.enabled === true && a.productname === b.productname) {
                      tempChildProductMap.push(b);
                      childProductBool = true;
                    }
                  });
                  if (childProductBool === false) {
                    var tempObj = {
                      "id": a.id,
                      "productname": a.productname,
                      "developeraccountid": response.result.id,
                      "enabled": false,
                      "apiproductid": a.apiproductid
                    };
                    tempChildProductMap.push(tempObj);
                  }
                } else {
                  var tempObj = {
                    "id": a.id,
                    "productname": a.productname,
                    "developeraccountid": response.result.id,
                    "enabled": false,
                    "apiproductid": a.apiproductid
                  };
                  tempChildProductMap.push(tempObj);
                }
              });
              if (tempChildProductMap.length > 0) {
                tempChildProductMap.map(function (a) {
                  if (a.enabled === true && a.productname === "Click & Collect") {
                    response.result['clickCollectPageSetupUI'] = true;
                  } else if (a.enabled === false && a.productname === "Click & Collect") {
                    response.result['clickCollectPageSetupUI'] = false;
                  }
                  if (a.enabled === true && a.productname === "Return") {
                    response.result['returnPageSetupUI'] = true;
                  } else if (a.enabled === false && a.productname === "Return") {
                    response.result['returnPageSetupUI'] = false;
                  }
                  if (response.result['clickCollectPageSetupUI'] === true || response.result.returnPageSetupUI === true) {
                    response.result['showPageSetupUI'] = true;
                  } else {
                    response.result['showPageSetupUI'] = false;
                  }
                });
              }
              response.result.productMapping = tempChildProductMap;
            }
            response.result['ecommerceReturnURL'] = _this3.get('config.ecommerceReturnURL');
            _this3.set("viewContent", response.result);
            _ember['default'].$('.ui.modal.courier-detail-view').modal('show').scrollTop(0, 0);
          } else {
            _ember['default'].$('body').removeClass('editCourierClass');
            _this3.get("loadingSpinner").hide();
            _this3.get('notify').success('Error encountered.');
          }
        }, console.log);
      }
    }
  });
});