define('admin-portal/pods/reports/index/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        config: _ember['default'].inject.service('config'),

        setupController: function setupController(controller) {
            controller.set('newTransactionReport', null);

            var monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var currentDate = new Date();

            var firstMonth = currentDate.getMonth();
            var secondMonth = currentDate.getMonth();
            var thirdMonth = currentDate.getMonth();

            var firstYear = currentDate.getFullYear();
            var secondYear = currentDate.getFullYear();
            var thirdYear = currentDate.getFullYear();

            if (currentDate.getMonth() - 1 < 0) {
                firstMonth = 12 + (currentDate.getMonth() - 1);
                firstYear = currentDate.getFullYear() - 1;
            } else {
                firstMonth = currentDate.getMonth() - 1;
            }
            if (currentDate.getMonth() - 2 < 0) {
                secondMonth = 12 + (currentDate.getMonth() - 2);
                secondYear = currentDate.getFullYear() - 1;
            } else {
                secondMonth = currentDate.getMonth() - 2;
            }
            if (currentDate.getMonth() - 3 < 0) {
                thirdMonth = 12 + (currentDate.getMonth() - 3);
                thirdYear = currentDate.getFullYear() - 1;
            } else {
                thirdMonth = currentDate.getMonth() - 3;
            }
            var monthlyArr = [];
            monthlyArr.push({ "month": firstMonth, "year": firstYear, "display_name": monthArr[firstMonth] + " " + firstYear });
            monthlyArr.push({ "month": secondMonth, "year": secondYear, "display_name": monthArr[secondMonth] + " " + secondYear });
            monthlyArr.push({ "month": thirdMonth, "year": thirdYear, "display_name": monthArr[thirdMonth] + " " + thirdYear });

            controller.set('newTransactionReport', { "month": firstMonth, "year": firstYear, "display_name": monthArr[firstMonth] + " " + firstYear });
            controller.set('monthlyDropDown', monthlyArr);
        }
    });
});