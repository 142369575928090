define('admin-portal/shapes/parcel-status', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  var Validations = (0, _emberCpValidations.buildValidations)({
    consignmentNumber: {
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        regex: /^[a-zA-Z0-9, ][a-zA-Z0-9, \-]*$/,
        message: 'Invalid characters'
      })]
    },
    article: {
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        regex: /^[a-zA-Z0-9 ][a-zA-Z0-9 \-]*$/,
        message: 'Invalid characters'
      })]
    },
    customerName: {
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        regex: /^[a-zA-Z0-9 ][a-zA-Z0-9 \-]*$/,
        message: 'Invalid characters'
      })]
    }
  });
  exports['default'] = _ember['default'].Object.extend(Validations, {
    name: null,
    phone: null
  });
});