define('admin-portal/pods/locations/download/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'admin-portal/shapes/import-locations'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _adminPortalShapesImportLocations) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        config: _ember['default'].inject.service('config'),
        ajax: _ember['default'].inject.service("ajax"),

        setupController: function setupController(controller) {
            controller.set('importNewData', _adminPortalShapesImportLocations['default'].create(_ember['default'].getOwner(controller).ownerInjection()));
            controller.set('importNewData.fileData', null);
            controller.set('uploadingNewBool', false);

            controller.set('importUpdateData', _adminPortalShapesImportLocations['default'].create(_ember['default'].getOwner(controller).ownerInjection()));
            controller.set('importUpdateData.fileData', null);
            controller.set('uploadingUpdateBool', false);
        }
    });
});