define("admin-portal/pods/components/file-input/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 81
          }
        },
        "moduleName": "admin-portal/pods/components/file-input/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "input", [], ["type", "file", "id", ["subexpr", "concat", [["get", "elementId", ["loc", [null, [1, 31], [1, 40]]], 0, 0, 0, 0], "-input"], [], ["loc", [null, [1, 23], [1, 50]]], 0, 0], "value", ["subexpr", "@mut", [["get", "fileName", ["loc", [null, [1, 57], [1, 65]]], 0, 0, 0, 0]], [], [], 0, 0], "accept", ["subexpr", "@mut", [["get", "accept", ["loc", [null, [1, 73], [1, 79]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [1, 81]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});