define('admin-portal/helpers/find-returns', ['exports', 'ember'], function (exports, _ember) {
  exports.findReturnRequested = findReturnRequested;

  function findReturnRequested(params) {
    var parcelsArr = params[0];
    var findReturnRequestedBool = false;
    for (var i = 0; i < parcelsArr.length; i++) {
      if (parcelsArr[i].state === 3) {
        findReturnRequestedBool = true;
      }
    }
    return findReturnRequestedBool;
  }

  exports['default'] = _ember['default'].Helper.helper(findReturnRequested);
});