define('admin-portal/pods/courier/couriers/controller', ['exports', 'ember', 'admin-portal/shapes/newcourier'], function (exports, _ember, _adminPortalShapesNewcourier) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    config: _ember['default'].inject.service('config'),
    session: _ember['default'].inject.service('session'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    notify: _ember['default'].inject.service(),

    loading: false,
    page: 1,
    totalPages: 1,
    couriersData: _ember['default'].A([]),
    viewContent: null,
    apiKeyValue: null,
    courierDeletionDetails: null,
    isBillingOpen: null,
    isGeneratingBool: false,
    populateBillingBool: false,
    carrier_partners: [{ name: "Couriers Please", code: "cpl" }, { name: "Toll", code: "toll" }, { name: "None", code: "none" }],
    timezone_list: config.timezone_list,

    buildUrl: function buildUrl(page) {
      var url = 'v2/admin/couriers?page=' + page + '&perPage=20';
      if (this.get('courierName') !== '' && this.get('courierName') !== undefined) {
        url += '&courierName=' + this.get("courierName").trim();
      }
      if (this.get('courierCode') !== '' && this.get('courierCode') !== undefined) {
        url += '&courierCode=' + this.get("courierCode").trim();
      }
      if (this.get('orderBy') !== '' && this.get('orderBy') !== undefined) {
        url += '&orderBy=' + this.get("orderBy");
      } else {
        url += '&orderBy=created';
      }
      if (this.get('order') !== '' && this.get('order') !== undefined) {
        url += '&order=' + this.get("order");
      } else {
        url += '&order=DESC';
      }
      return url;
    },
    actions: {
      getPage: function getPage(page) {
        var self = this;
        self.set("loading", true);
        this.get('ajax').request(this.buildUrl(page)).then(function (result) {
          self.set('page', result.page);
          self.set('totalPages', result.totalPages);
          self.set('couriersData', _ember['default'].A(result.results));
          self.set("loading", false);
          self.get("loadingSpinner").hide();
        }, function () /*error*/{
          self.set("loading", false);
        });
      },
      search: function search() {
        this.send("getPage", 1);
      },
      orderBy: function orderBy(orderValue, orderAs) {
        this.set('orderBy', orderValue);
        this.set('order', orderAs);
        this.send("getPage", 1);
      },
      editCourier: function editCourier(code) {
        this.transitionTo('courier.update', code);
      },
      openViewModal: function openViewModal(code) {
        _ember['default'].$('body').addClass('editCourierClass');
        this.set("apiKeyValue", code.hubbedUser.apikey);
        if (code.hubbedUser.timezoneId) {
          var timezoneObj = this.get('timezone_list');
          for (var i = 0; i < timezoneObj.length; i++) {
            if (timezoneObj[i].id === code.hubbedUser.timezoneId) {
              code['timezone'] = timezoneObj[i].timezone;
              break;
            }
          }
        }
        this.set("viewContent", code);
        _ember['default'].$('.ui.modal.courier-detail-view').modal('show').scrollTop(0, 0);
      },
      deleteModalOpen: function deleteModalOpen(courierDetails) {
        this.set("courierDeletionDetails", courierDetails);
        _ember['default'].$('.ui.modal.courier-deletion').modal('show');
      },
      deleteExternalCourier: function deleteExternalCourier(objData) {
        var _this = this;

        // this.get('ajax').delete(`v2/admin/couriers/${objData.hubbedUser.id}`, {
        //   dataType: "text"
        // }).then(() => {
        this.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/SoftDeleteUser/' + objData.hubbedUser.id, {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          }
        }).then(function () /*result*/{
          _this.get('notify').success(objData.courier.code + ' is Successfully deleted.');
          _this.set("courierDeletionDetails", null);
          _ember['default'].$('.ui.modal.courier-deletion').modal('hide');
          _this.get("loadingSpinner").show();
          _this.send("getPage", _this.get('page'));
        }, function () /*error*/{
          _this.get('notify').error('Could not delete ' + objData.courier.code + '. Try refreshing this page.');
        });
      },
      regenerateAPIKey: function regenerateAPIKey(code) {
        var _this2 = this;

        var r = confirm("Are you sure to re-generate API Key?");
        if (r === true) {
          this.set("isGeneratingBool", true);
          this.get('ajax').post('v2/admin/couriers/' + code + '/refreshToken', {
            contentType: 'application/json; charset=utf-8',
            crossDomain: true
          }).then(function (result) {
            _this2.set("apiKeyValue", result.hubbedUser.apikey);
            _this2.get('notify').success('API Key has been successfully Regenerated.');
            _this2.set("isGeneratingBool", false);
            _this2.send("getPage", _this2.get('page'));
          }, function () /*error*/{
            _this2.get('notify').error('Error regenerating API Key.');
            _this2.set("isGeneratingBool", false);
          });
        }
      },
      closeEditModal: function closeEditModal() {
        this.set("courierUnit", null);
        this.set("apiKeyValue", null);
        this.set("webhook_events", null);
        this.set("isBillingOpen", true);
        this.set('populateBillingBool', true);
        _ember['default'].$('body').removeClass('editCourierClass');
        _ember['default'].$('.ui.edit-courier-details.modal').modal('hide');
        //this.send("getPage", 1);
      },
      editCourierModal: function editCourierModal(code) {
        var _this3 = this;

        _ember['default'].$('body').addClass('editCourierClass');
        this.set("isGeneratingBool", false);
        this.get("loadingSpinner").show();
        this.set("apiKeyValue", null);
        this.set("webhook_events", null);
        this.get('ajax').request('v2/admin/couriers/' + code).then(function (results) {
          _this3.set("apiKeyValue", results.hubbedUser.apikey);
          _this3.set("webhook_events", results.webhookEvents);

          if (results.apiuser.address.address === results.apiuser.billingAddress.address && results.apiuser.address.address2 === results.apiuser.billingAddress.address2 && results.apiuser.address.suburb === results.apiuser.billingAddress.suburb && results.apiuser.address.state === results.apiuser.billingAddress.state && results.apiuser.address.postcode === results.apiuser.billingAddress.postcode && results.apiuser.address.country === results.apiuser.billingAddress.country) {
            _this3.set("isBillingOpen", true);
            _this3.set('populateBillingBool', true);
          } else {
            _this3.set("isBillingOpen", false);
            _this3.set('populateBillingBool', false);
          }
          var carrier_partner_str = 'none';
          if (results.apiuser.carrier_partner && results.apiuser.carrier_partner !== "") {
            carrier_partner_str = results.apiuser.carrier_partner;
          }
          _this3.set("courierUnit", _adminPortalShapesNewcourier['default'].create(_ember['default'].getOwner(_this3).ownerInjection(), {
            courierCompanyName: results.courier.name,
            courierCode: results.courier.code,
            carrier_partner: carrier_partner_str,
            timezone: results.hubbedUser.timezoneId,
            active: results.hubbedUser.active,
            active_notification: results.apiuser.active_notification,
            name: results.courier.contactName,
            email: results.apiuser.email,
            phone: results.courier.contactPhone,
            address1: results.apiuser.address.address,
            address2: results.apiuser.address.address2,
            suburb: results.apiuser.address.suburb,
            state: results.apiuser.address.state,
            postcode: results.apiuser.address.postcode,
            country: results.apiuser.address.country,
            billing_address: results.apiuser.billingAddress.address,
            billing_address2: results.apiuser.billingAddress.address2,
            billing_suburb: results.apiuser.billingAddress.suburb,
            billing_state: results.apiuser.billingAddress.state,
            billing_postcode: results.apiuser.billingAddress.postcode,
            billing_country: results.apiuser.billingAddress.country,
            notificationUserName: results.apiuser.notificationUsername,
            notificationPassword: results.apiuser.notificationPassword,
            notificationURL: results.apiuser.notificationURL,
            logo: results.courier.logo,
            language_code: results.apiuser.languageCode,
            id: results.apiuser.id,
            token: results.apiuser.token,
            isDeleted: results.apiuser.isDeleted,
            username: results.hubbedUser.username,
            firstname: results.hubbedUser.firstname,
            lastname: results.hubbedUser.lastname,
            hubbedUserId: results.hubbedUser.id,
            duplicate_allowed_days: results.apiuser.duplicateAllowedDays,
            roleId: results.hubbedUser.roleId
          }));
          _this3.get("loadingSpinner").hide();
          _ember['default'].$('.ui.edit-courier-details.modal').modal('show');
        }, function () /*error*/{
          _this3.get("loadingSpinner").hide();
          _ember['default'].$('body').removeClass('editCourierClass');
          _this3.get('notify').error('Some internal error, please try again.');
        });
      },
      billingOpen: function billingOpen(courierUnit) {
        if (this.get('isBillingOpen')) {
          this.set('populateBillingBool', false);
          this.set('courierUnit.billing_address', null);
          this.set('courierUnit.billing_address2', null);
          this.set('courierUnit.billing_suburb', null);
          this.set('courierUnit.billing_state', null);
          this.set('courierUnit.billing_postcode', null);
          this.set('courierUnit.billing_country', null);
          this.set('isBillingOpen', false);
        } else {
          this.set('populateBillingBool', true);
          this.set('courierUnit.billing_address', courierUnit.address1);
          this.set('courierUnit.billing_address2', courierUnit.address2);
          this.set('courierUnit.billing_suburb', courierUnit.suburb);
          this.set('courierUnit.billing_state', courierUnit.state);
          this.set('courierUnit.billing_postcode', courierUnit.postcode);
          this.set('courierUnit.billing_country', courierUnit.country);
          this.set('isBillingOpen', true);
        }
      },
      populateBillingAddress: function populateBillingAddress(courierUnit) {
        if (this.get('populateBillingBool')) {
          this.set('courierUnit.billing_address', courierUnit.address1);
          this.set('courierUnit.billing_address2', courierUnit.address2);
          this.set('courierUnit.billing_suburb', courierUnit.suburb);
          this.set('courierUnit.billing_state', courierUnit.state);
          this.set('courierUnit.billing_postcode', courierUnit.postcode);
          this.set('courierUnit.billing_country', courierUnit.country);
        }
      },
      update: function update() {
        var _this4 = this;

        var self = this;
        var webhook_events = self.get("webhook_events").map(function (a) {
          if (a.active) {
            return {
              "id": Number(a.id),
              "eventId": Number(a.eventId),
              "eventName": a.eventName,
              "displayName": a.displayName,
              "active": true
            };
          } else {
            return {
              "id": Number(a.id),
              "eventId": Number(a.eventId),
              "eventName": a.eventName,
              "displayName": a.displayName,
              "active": false
            };
          }
        });
        var duplicateAllowedDays = this.get("courierUnit.duplicate_allowed_days");
        if (this.get("courierUnit.duplicate_allowed_days") === null || this.get("courierUnit.duplicate_allowed_days") === undefined || this.get("courierUnit.duplicate_allowed_days") === "") {
          duplicateAllowedDays = 0;
        }
        var selected_carrier_partner = null;
        if (this.get('courierUnit.carrier_partner') && this.get('courierUnit.carrier_partner') !== "none") {
          selected_carrier_partner = this.get('courierUnit.carrier_partner');
        }
        var activeBool = this.get("courierUnit.active") && this.get("courierUnit.active") !== "" && this.get("courierUnit.active") !== undefined ? this.get("courierUnit.active") : false;
        var courierDetails = {
          "name": this.get('courierUnit.courierCompanyName'),
          "code": this.get('courierUnit.courierCode'),
          "contactName": this.get('courierUnit.name'),
          "courierType": 1,
          "logo": this.get('courierUnit.logo') && this.get('courierUnit.logo') !== "" ? this.get('courierUnit.logo') : null,
          "contactPhone": this.get('courierUnit.phone'),
          "active": this.get("courierUnit.active") && this.get("courierUnit.active") !== "" && this.get("courierUnit.active") !== undefined ? this.get("courierUnit.active") : false
        };
        var apiuserDetails = {
          "email": this.get('courierUnit.email'),
          "notificationUsername": this.get('courierUnit.notificationUserName'),
          "billingAddress": {
            state: this.get('courierUnit.billing_state'),
            postcode: this.get('courierUnit.billing_postcode'),
            country: this.get('courierUnit.billing_country'),
            suburb: this.get('courierUnit.billing_suburb'),
            address: this.get('courierUnit.billing_address'),
            address2: this.get('courierUnit.billing_address2')
          },
          "duplicateAllowedDays": duplicateAllowedDays,
          "active_notification": this.get("courierUnit.active_notification") && this.get("courierUnit.active_notification") !== "" && this.get("courierUnit.active_notification") !== undefined ? this.get("courierUnit.active_notification") : false,
          "languageCode": this.get('courierUnit.language_code'),
          "notificationPassword": this.get('courierUnit.notificationPassword'),
          "notificationURL": this.get('courierUnit.notificationURL'),
          "id": this.get('courierUnit.id'),
          "isDeleted": this.get('courierUnit.isDeleted'),
          "token": this.get('courierUnit.token'),
          "carrier_partner": selected_carrier_partner,
          "address": {
            state: this.get('courierUnit.state'),
            postcode: this.get('courierUnit.postcode'),
            country: this.get('courierUnit.country'),
            suburb: this.get('courierUnit.suburb'),
            address: this.get('courierUnit.address1'),
            address2: this.get('courierUnit.address2')
          }
        };
        var hubbedUserObj = {
          "apikey": this.get('apiKeyValue'),
          "timezoneId": this.get('courierUnit.timezone'),
          "couriercode": this.get('courierUnit.courierCode'),
          "email": this.get('courierUnit.email'),
          "username": this.get('courierUnit.username'),
          "firstname": this.get('courierUnit.firstname'),
          "lastname": this.get('courierUnit.lastname'),
          "id": this.get('courierUnit.hubbedUserId'),
          "roleId": this.get('courierUnit.roleId')
        };
        this.get('ajax').put('v2/admin/couriers', {
          data: JSON.stringify({
            courier: courierDetails,
            apiuser: apiuserDetails,
            hubbedUser: hubbedUserObj,
            webhookEvents: webhook_events
          }),
          contentType: 'application/json; charset=utf-8',
          crossDomain: true
        }).then(function () {
          _this4.set("apiKeyValue", null);
          _this4.set("webhook_events", null);
          _ember['default'].$('body').removeClass('editCourierClass');
          _ember['default'].$('.ui.edit-courier-details.modal').modal('hide');
          _this4.get('notify').success('Courier has been successfully Updated.');

          //update status
          _this4.get('ajax').request(_this4.get("config.carrierApiURL") + 'ecommerceuser/isactive?hubbed_user_id=' + _this4.get('courierUnit.hubbedUserId') + '&status=' + activeBool, {
            headers: {
              'Accept': 'application/json',
              'Authorization': "Bearer " + _this4.get('session.data.authenticated.dotnet_jwt')
            }
          }).then(function () /*result*/{
            _this4.send("getPage", _this4.get('page'));
          }, function () /*error*/{
            _this4.send("getPage", _this4.get('page'));
          });
        })['catch'](function (error) {
          if (error && error.errors) {
            var errorMsg = error.errors.map(function (e) {
              return e.detail;
            });

            if (errorMsg[0] && errorMsg[0].message) {
              _this4.get('notify').error(errorMsg[0].message);
            } else if (error.errors && error.errors.length > 0 && error.errors[0].title) {
              _this4.get('notify').error(error.errors[0].title);
            } else {
              _this4.get('notify').error('Error updating details.');
            }
          } else {
            _this4.get('notify').success('Courier has been successfully Updated.');
            _this4.set("apiKeyValue", null);
            _this4.set("webhook_events", null);
            _ember['default'].$('body').removeClass('editCourierClass');
            _ember['default'].$('.ui.edit-courier-details.modal').modal('hide');

            //update status
            _this4.get('ajax').request(_this4.get("config.carrierApiURL") + 'ecommerceuser/isactive?hubbed_user_id=' + _this4.get('courierUnit.hubbedUserId') + '&status=' + activeBool, {
              headers: {
                'Accept': 'application/json',
                'Authorization': "Bearer " + _this4.get('session.data.authenticated.dotnet_jwt')
              }
            }).then(function () /*result*/{
              _this4.send("getPage", _this4.get('page'));
            }, function () /*error*/{
              _this4.send("getPage", _this4.get('page'));
            });
          }
        });
      },
      setCountry: function setCountry(country_name) {
        this.set('courierUnit.country', country_name);
      }
      // closeView() {
      //   this.set("edit", null);
      //   this.set("apiKeyValue", null);
      //   Ember.$('.ui.edit-promo-code.modal').modal('hide');         
      // }   
    }
  });
});