define('admin-portal/pods/reports/aged/controller', ['exports', 'ember', 'ember-ajax/errors', 'admin-portal/shapes/email-address'], function (exports, _ember, _emberAjaxErrors, _adminPortalShapesEmailAddress) {
  exports['default'] = _ember['default'].Controller.extend({
    config: _ember['default'].inject.service('config'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    notify: _ember['default'].inject.service(),
    loading: false,
    page: 1,
    totalPages: 1,
    buildUrl: function buildUrl(page) {
      var url = this.get("config.carrierApiURL") + 'AgedParcel/GetCouriersWithAgedParcelSetting?pageno=' + page + '&perPage=20';
      return url;
    },
    actions: {
      getPage: function getPage(page) {
        var self = this;
        self.set("loading", true);
        this.get('ajax').request(this.buildUrl(page), {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          }
        }).then(function (res) {
          if (res.pageno === 1 && res.totalPages === 0) {
            self.set('totalPages', 1);
          } else if (res.totalPages) {
            self.set('totalPages', res.totalPages);
          } else {
            self.set('totalPages', 1);
          }
          if (res.pageno) {
            self.set('page', res.pageno);
          } else {
            self.set('page', 1);
          }

          self.set('reportData', _ember['default'].A(res.result));
          self.set("loading", false);
          self.get("loadingSpinner").hide();
        }, function () /*error*/{
          self.set("loading", false);
        });
      },
      changeActive: function changeActive(data) {
        var _this = this;

        _ember['default'].set(data, "agedParcelReport_SendReport", !data.agedParcelReport_SendReport);
        this.get("loadingSpinner").show();
        var postDataObj = {
          "courierCode": data.courier_Code,
          "email": data.agedParcelReport_Email,
          "sendReport": data.agedParcelReport_SendReport
        };
        if (data.agedParcelReport_Id && data.agedParcelReport_Id !== 0) {
          postDataObj['id'] = data.agedParcelReport_Id;
        }
        this.get('ajax').post(this.get("config.carrierApiURL") + 'AgedParcel/SaveAgedParcelReportSetting', {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          },
          data: JSON.stringify(postDataObj),
          contentType: "application/json",
          crossDomain: true
        }).then(function (dl) {
          if (dl.responseCode !== 200 && dl.responseCode !== "200" && dl.responseMessage) {
            _this.get("notify").error(dl.responseMessage);
            _ember['default'].set(data, "agedParcelReport_SendReport", !data.agedParcelReport_SendReport);
          }
          _this.get("loadingSpinner").hide();
        }, function (error) {
          if ((0, _emberAjaxErrors.isConflictError)(error)) {
            _this.get("notify").error("Conflicting in Updation");
          } else {
            _this.get("notify").error("Unknown error");
          }
          _ember['default'].set(data, "agedParcelReport_SendReport", !data.agedParcelReport_SendReport);
          _this.get("loadingSpinner").hide();
        });
      },
      editOpen: function editOpen(code) {
        this.set("edit", _adminPortalShapesEmailAddress['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
          id: code.agedParcelReport_Id,
          email: code.agedParcelReport_Email,
          name: code.courier_Name,
          code: code.courier_Code,
          sendReport: code.agedParcelReport_SendReport && code.agedParcelReport_SendReport !== null ? code.agedParcelReport_SendReport : false
        }));
        _ember['default'].$('.ui.edit-aged-code.modal').modal('show');
      },
      closeEdit: function closeEdit() {
        this.set("edit", null);
        _ember['default'].$('.ui.edit-aged-code.modal').modal('hide');
      },
      saveEdit: function saveEdit(edit) {
        var _this2 = this;

        this.get("loadingSpinner").show();
        var postDataObj = {
          "courierCode": edit.code,
          "email": edit.email,
          "sendReport": edit.sendReport
        };
        if (edit.id && edit.id !== 0) {
          postDataObj['id'] = edit.id;
        }
        this.get('ajax').post(this.get("config.carrierApiURL") + 'AgedParcel/SaveAgedParcelReportSetting', {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          },
          data: JSON.stringify(postDataObj),
          contentType: "application/json",
          crossDomain: true
        }).then(function () /*dl*/{
          _this2.send("getPage", _this2.get('page'));
          _this2.get("loadingSpinner").hide();
          _ember['default'].$('.ui.edit-aged-code.modal').modal('hide');
          _this2.get("notify").success('Successfully updated.');
        }, function () /*error*/{
          _this2.get("loadingSpinner").hide();
          _this2.get("notify").error('Error edited.');
        });
      }
    }
  });
});