define('admin-portal/pods/components/consignment-warranty/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    session: _ember['default'].inject.service('session'),
    loadingSpinner: _ember['default'].inject.service('loading'),

    name: 'consignment-warranty',
    classNames: ['consignment-warranty'],

    onHide: function onHide() {
      this.set("warranty_details", null);
      _ember['default'].$('.ui.modal.consignment-warranty').modal('hide');
    },
    actions: {
      cancel: function cancel() {
        this.set("warranty_details", null);
        _ember['default'].$('.ui.modal.consignment-warranty').modal('hide');
      }
    }
  });
});