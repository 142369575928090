define('admin-portal/pods/invoicing/controller', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Controller.extend({
    config: _ember['default'].inject.service('config'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),
    fileData: null,
    regenerateFileData: null,
    generatedSuccessBool: false,
    uploadingBool: false,

    downloadCarrierLevelReport: _ember['default'].computed('session.data.authenticated.dotnet_jwt', 'config.carrierApiURL', function () {
      var jwt = this.get('session.data.authenticated.dotnet_jwt');
      var queryStr = 'token=' + jwt;
      return this.get("config.carrierApiURL") + 'admin/InvoicingReport/GetCarrierLevelReport?' + queryStr;
    }),
    downloadStoreLevelReport: _ember['default'].computed('session.data.authenticated.dotnet_jwt', 'config.carrierApiURL', function () {
      var jwt = this.get('session.data.authenticated.dotnet_jwt');
      var queryStr = 'token=' + jwt;
      return this.get("config.carrierApiURL") + 'admin/InvoicingReport/GetStoreLevelReport?' + queryStr;
    }),
    downloadCarrierLevelXeroReport: _ember['default'].computed('session.data.authenticated.dotnet_jwt', 'config.carrierApiURL', function () {
      var jwt = this.get('session.data.authenticated.dotnet_jwt');
      var queryStr = 'invoicingNumber=' + this.get('invoicing.invoice_number') + '&token=' + jwt;
      return this.get("config.carrierApiURL") + 'admin/InvoicingReport/GetCarrierLevelReportForXero?' + queryStr;
    }),
    downloadStorerLevelXeroReport: _ember['default'].computed('session.data.authenticated.dotnet_jwt', 'config.carrierApiURL', function () {
      var jwt = this.get('session.data.authenticated.dotnet_jwt');
      var queryStr = 'token=' + jwt;
      return this.get("config.carrierApiURL") + 'admin/InvoicingReport/GetStoreLevelReportForXero?' + queryStr;
    }),
    downloadRecalculateTransactionReport: _ember['default'].computed('session.data.authenticated.dotnet_jwt', 'config.carrierApiURL', function () {
      // let jwt = this.get('session.data.authenticated.dotnet_jwt');
      // let queryStr = 'token='+jwt;
      // return `${this.get("config.carrierApiURL")}admin/InvoicingReport/downloadRecalculatedTransactionReport?${queryStr}`;
      $.ajax({
        url: this.get("config.carrierApiURL") + 'admin/InvoicingReport/downloadRecalculatedTransactionReport',
        type: 'GET',
        headers: {
          'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
        },
        contentType: "application/json",
        crossDomain: true,
        success: function success(result) {
          console.log('success', result);
          var a = document.createElement('a');
          var blob = new Blob([result], { type: "octet/stream" });
          var url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = 'Recalculate_transaction_analysis_report.csv';
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        },
        error: function error(jqXHR, exception) {
          console.log('error', jqXHR);
        }
      });
    }),
    downloadBPStoresDayWiseReport: _ember['default'].computed('session.data.authenticated.dotnet_jwt', 'config.carrierApiURL', function () {
      var jwt = this.get('session.data.authenticated.dotnet_jwt');
      var queryStr = 'token=' + jwt;
      return this.get("config.carrierApiURL") + 'admin/InvoicingReport/GetBpStoresDayWiseReport?' + queryStr;
    }),
    downloadPreviousTransactionReport: _ember['default'].computed('transactionMonthValSelected', 'session.data.authenticated.dotnet_jwt', 'config.carrierApiURL', function () {
      var jwt = this.get('session.data.authenticated.dotnet_jwt');
      var selectedRange = this.get("transactionMonthValSelected");
      var selectedRangeArr = selectedRange.split("-");

      var queryStr = 'token=' + jwt;
      queryStr += '&monthAsNumber=' + selectedRangeArr[0];
      queryStr += '&year=' + selectedRangeArr[1];
      return this.get("config.carrierApiURL") + 'admin/InvoicingReport/GetTransactionReportFromArchiveTable?' + queryStr;
    }),
    downloadPreviousStoreLevelReport: _ember['default'].computed('summaryReportMonthValSelected', 'session.data.authenticated.dotnet_jwt', 'config.carrierApiURL', function () {
      var jwt = this.get('session.data.authenticated.dotnet_jwt');
      var selectedRange = this.get("summaryReportMonthValSelected");
      var selectedRangeArr = selectedRange.split("-");

      var queryStr = 'token=' + jwt;
      queryStr += '&monthAsNumber=' + selectedRangeArr[0];
      queryStr += '&year=' + selectedRangeArr[1];
      return this.get("config.carrierApiURL") + 'admin/InvoicingReport/GetStoreLevelReportFromArchiveTable?' + queryStr;
    }),
    downloadPreviousCarrierLevelReport: _ember['default'].computed('summaryReportMonthValSelected', 'session.data.authenticated.dotnet_jwt', 'config.carrierApiURL', function () {
      var jwt = this.get('session.data.authenticated.dotnet_jwt');
      var selectedRange = this.get("summaryReportMonthValSelected");
      var selectedRangeArr = selectedRange.split("-");

      var queryStr = 'token=' + jwt;
      queryStr += '&monthAsNumber=' + selectedRangeArr[0];
      queryStr += '&year=' + selectedRangeArr[1];
      return this.get("config.carrierApiURL") + 'admin/InvoicingReport/GetCarrierLevelReportFromArchiveTable?' + queryStr;
    }),

    newTransactionReportURL: _ember['default'].computed('newTransactionReport', 'session.data.authenticated', 'config.carrierApiURL', function () {
      var selectedRange = this.get("newTransactionReport");
      var firstDate = new Date(selectedRange['year'], selectedRange['month'], 1);
      var lastDate = new Date(selectedRange['year'], selectedRange['month'] + 1, 0);

      var startDate = (0, _moment['default'])(firstDate).format("YYYY-MM-DD") + ' 00:00';
      var toDate = (0, _moment['default'])(lastDate).format("YYYY-MM-DD") + ' 23:59';

      var queryString = '';

      if (startDate !== null) {
        queryString += '&fromdate=' + startDate;
      } else {
        queryString += '&fromdate=';
      }
      if (toDate !== null) {
        queryString += '&todate=' + toDate;
      } else {
        queryString += '&todate=';
      }
      queryString += '&t=' + this.get('session.data.authenticated.dotnet_jwt');
      return this.get("config.carrierApiURL") + 'dashboard/downloadreport?reporttype=admintransactions' + queryString;
    }),
    actions: {

      newDownloadReport: function newDownloadReport() {
        var self = this;
        var selectedRange = this.get("newTransactionReport");
        var firstDate = new Date(selectedRange['year'], selectedRange['month'], 1);
        var abc = new Date(selectedRange['month'] + 1, 0);
        var lastDate = new Date(selectedRange['year'], selectedRange['month'] + 1, 0);
        var startDate = (0, _moment['default'])(firstDate).format("YYYY-MM-DD") + encodeURIComponent(' 00:00');
        var toDate = (0, _moment['default'])(lastDate).format("YYYY-MM-DD") + encodeURIComponent(' 23:59');
        var queryString = '';
        if (startDate !== null) {
          queryString += '&fromdate=' + startDate;
        } else {
          queryString += '&fromdate=';
        }
        if (toDate !== null) {
          queryString += '&todate=' + toDate;
        } else {
          queryString += '&todate=';
        }
        var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        var downldMonth = monthNames[selectedRange.month].toLowerCase();
        var downldyear = selectedRange.year;

        var FileNameTo = downldMonth + '_' + downldyear;
        $.ajax({
          url: this.get("config.carrierApiURL") + 'dashboard/downloadreport?reporttype=admintransactions' + queryString,
          type: 'GET',
          headers: {
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          },
          contentType: "application/json",
          crossDomain: true,
          success: function success(result) {
            console.log('success', result);
            var a = document.createElement('a');
            var blob = new Blob([result], { type: "octet/stream" });
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = 'transaction_analysis_report_' + FileNameTo + ' .csv';
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
          },
          error: function error(jqXHR, exception) {
            console.log('error', jqXHR);
          }
        });
      },

      RecalculatedDownloadReport: function RecalculatedDownloadReport() {
        $.ajax({
          url: this.get("config.carrierApiURL") + 'admin/InvoicingReport/downloadRecalculatedTransactionReport',
          type: 'GET',
          headers: {
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          },
          contentType: "application/json",
          crossDomain: true,
          success: function success(result) {
            console.log('success', result);
            var a = document.createElement('a');
            var blob = new Blob([result], { type: "octet/stream" });
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = 'Recalculate_transaction_analysis_report.csv';
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
          },
          error: function error(jqXHR, exception) {
            console.log('error', jqXHR);
          }
        });
      },

      fetch_previous_transaction_month: function fetch_previous_transaction_month(text, val) {
        this.set('transactionMonthTextSelected', text);
        this.set('transactionMonthValSelected', val);
        this.set('showTransactionMonthBool', true);
      },
      fetch_previous_summary_month: function fetch_previous_summary_month(text, val) {
        this.set('summaryReportMonthTextSelected', text);
        this.set('summaryReportMonthValSelected', val);
        this.set('showSummaryReportMonthBool', true);
      },
      dismiss_alert: function dismiss_alert() {
        this.set('showErrorBool', false);
      },
      dismiss_regenerate_alert: function dismiss_regenerate_alert() {
        this.set('showReGenerateSuccessBool', false);
      },
      reUploadTransactionReport: function reUploadTransactionReport() {
        var fileContent = document.getElementById('regenertae-file-upload').files[0];
        this.set('regenerateFileData', fileContent);
      },
      onUpload1: function onUpload1() {
        var fileContent = document.getElementById('file-upload-input').files[0];
        this.set('invoicing.fileData', fileContent);
      },
      re_generate_report: function re_generate_report() {
        var self = this;
        // self.set('showReGenerateSuccessBool', true);
        // self.set("generatedTransactionSuccessBool", true);
        // if(false) {
        if (this.get('regenerateFileData')) {
          this.set("uploadingTransactionBool", true);

          var formDataUpload = new FormData();
          formDataUpload.append('file', this.get('regenerateFileData'));

          $.ajax({
            url: this.get("config.carrierApiURL") + 'admin/invoicingReport/recalculateTransactionReport',
            headers: {
              'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
            },
            data: formDataUpload,
            processData: false,
            contentType: false,
            type: 'POST',
            success: function success(data) {
              self.set("uploadingTransactionBool", false);

              if (data && data.responseCode && data.responseCode === "200") {

                self.set('showReGenerateSuccessBool', true);
                self.set("generatedTransactionSuccessBool", true);
              } else {
                self.get('notify').error(data.responseMessage);
              }
            },
            error: function error(jqXHR, exception) {
              self.set("uploadingTransactionBool", false);
              var msg = '';
              if (jqXHR.status === 0) {
                msg = 'Not connect.\n Verify Network.';
              } else if (jqXHR.status === 404) {
                msg = 'Requested page not found. [404]';
              } else if (jqXHR.status === 500) {
                msg = 'Internal Server Error [500].';
              } else if (exception === 'parsererror') {
                msg = 'Requested JSON parse failed.';
              } else if (exception === 'timeout') {
                msg = 'Time out error.';
              } else if (exception === 'abort') {
                msg = 'Ajax request aborted.';
              } else {
                msg = 'Uncaught Error.\n' + jqXHR.responseText;
              }
              self.get('notify').error(msg);
            }
          });
        } else {
          this.get('notify').error('Please upload the file');
        }
        // }
      },
      generate_report: function generate_report() {
        // var self = this;
        // self.set("generatedSuccessBool", true);
        // this.set('showErrorBool', true);
        // if(false) {
        if (this.get('invoicing.fileData')) {
          this.set("uploadingBool", true);

          var formDataUpload = new FormData();
          formDataUpload.append('file', this.get('invoicing.fileData'));
          formDataUpload.append('invoice_number', this.get('invoicing.invoice_number'));
          var self = this;

          $.ajax({
            url: this.get("config.carrierApiURL") + 'admin/invoicingReport/importTransactionAnalysisReportCsv',
            headers: {
              'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
            },
            data: formDataUpload,
            processData: false,
            contentType: false,
            type: 'POST',
            success: function success(data) {
              self.set("uploadingBool", false);
              if (data && data.responseCode && data.responseCode === "200") {
                self.set("generatedSuccessBool", true);
                self.set('showErrorBool', true);
              } else {
                self.get('notify').error(data.responseMessage);
              }
            },
            error: function error(jqXHR, exception) {
              self.set("uploadingBool", false);
              var msg = '';
              if (jqXHR.status === 0) {
                msg = 'Not connect.\n Verify Network.';
              } else if (jqXHR.status === 404) {
                msg = 'Requested page not found. [404]';
              } else if (jqXHR.status === 500) {
                msg = 'Internal Server Error [500].';
              } else if (exception === 'parsererror') {
                msg = 'Requested JSON parse failed.';
              } else if (exception === 'timeout') {
                msg = 'Time out error.';
              } else if (exception === 'abort') {
                msg = 'Ajax request aborted.';
              } else {
                msg = 'Uncaught Error.\n' + jqXHR.responseText;
              }
              self.get('notify').error(msg);
            }
          });
        } else {
          this.get('notify').error('Please upload the file');
        }
        // }
      }
    }
  });
});