define('admin-portal/pods/admin-users/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    notify: _ember['default'].inject.service('notify'),
    loading: _ember['default'].inject.service('loading'),

    users: [],
    'new': {
      name: '',
      email: '',
      password: ''
    },

    actions: {
      create: function create() {
        var _this = this;

        if (this.get("new.name").length > 0 && this.get("new.email").length > 0 && this.get("new.password").length > 0) {
          (function () {
            var self = _this;
            self.get("loading").show();
            _this.get('ajax').post('v2/admin/adminUsers', {
              data: JSON.stringify({ name: self.get("new.name"), email: self.get("new.email"), password: self.get("new.password") }),
              contentType: "application/json",
              crossDomain: true
            }).then(function () /*dl*/{
              self.send("reload");
              self.set("new", {
                name: '',
                email: '',
                password: ''
              });
              self.get("loading").hide();
              self.get("notify").success('Successfully added new admin user.');
            }, function () /*error*/{
              self.get("loading").hide();
              self.get("notify").error('Error adding new admin user.');
            });
          })();
        }
      },

      updateRoles: function updateRoles(user, roleid) {
        var self = this;
        self.get("loading").show();
        var roles = this.get('roles');
        this.get('user.id');
        var request = {
          userRole: roleid
        };
        this.get('ajax').put('v2/admin/adminUsers/' + user.id, {
          data: JSON.stringify(request),
          contentType: "application/json",
          crossDomain: true
        }).then(function () /*dl*/{
          self.get("loading").hide();
          self.get("notify").success('Role has been updated successfully.');
        }, function () /*error*/{});
      },
      reload: function reload() {
        var self = this;
        this.get('ajax').request('v2/admin/adminUsers').then(function (users) {
          self.set('users', users);
        });
      }
    }

  });
});