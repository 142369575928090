define('admin-portal/shapes/network-management', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  var Validations = (0, _emberCpValidations.buildValidations)({
    filter_by_country: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true })],
    filter_by_channel: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true })],
    filter_by_product: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true })]
  });
  exports['default'] = _ember['default'].Object.extend(Validations, {
    filter_by_country: null,
    filter_by_channel: null,
    filter_by_product: null
  });
});