define('admin-portal/pods/ecommerce/customer/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'admin-portal/shapes/developer-account'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _adminPortalShapesDeveloperAccount) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    config: _ember['default'].inject.service('config'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),

    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller, model) {

      controller.set("defaultTemplateBool", true);
      controller.set("showThemeOptionBool", false);
      controller.set("updateLocationBool", false);
      controller.set("skinBgImageBool", true);
      controller.set("skinBgColorBool", false);
      controller.set('isBillingOpen', false);
      controller.set('isSavingBool', false);
      controller.set('notValidReturnLocation', false);
      controller.set('showPageSetupUI', false);
      controller.set('clickCollectPageSetupUI', false);
      controller.set('returnPageSetupUI', false);
      controller.set("products_list_bool", false);
      controller.set('webhookEventList', null);
      controller.set('returnLocations', []);
      controller.set('developerAccount', null);
      controller.set('developerAccount', _adminPortalShapesDeveloperAccount['default'].create(_ember['default'].getOwner(controller).ownerInjection()));

      controller.set('developerAccount.ecommercecourierid', model.id);
      controller.set('developerAccount.template', "default");
      controller.set("developerAccount.customNetwork", 0);

      controller.set('backgroundcolor', null);
      controller.set('fontcolor', null);
      controller.set('buttoncolor', null);
      controller.set('headertextcolor', null);
      controller.set('stepsbackgroundcolor', null);

      this.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/edit/' + model.id, {
        headers: {
          'Accept': 'application/json',
          'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
        }
      }).then(function (responseData) {
        if (responseData && responseData.result) {
          var response = responseData.result;
          controller.set('consolidatedAddress', responseData.result.consolidatedDetails);
          controller.set("selectedEcomCourier", model.id);
          controller.set("selectedEcomCourierName", response.company_name);

          if (response.template && (response.template !== "" || response.template !== null) && response.template !== "default") {
            controller.set('defaultTemplateBool', false);
            controller.set('developerAccount.template', response.template);
            // controller.set("showThemeOptionBool", true);
          } else {
              controller.set("defaultTemplateBool", true);
              controller.set('developerAccount.template', "default");
            }
          controller.set("webhookEventList", response.webhookEventMapping);
          if (response.ecommerceChannelMapping && response.ecommerceChannelMapping.length > 0) {
            var channelDataSet = [];
            var channelData = response.ecommerceChannelMapping;
            for (var i = 0; i < channelData.length; i++) {
              if (channelData[i].active && channelData[i].self_active) {
                channelDataSet.push(channelData[i]);
              }
            }
            controller.set("channel_list", channelDataSet);
          }
          if (response.isCustomNetwork && response.isCustomNetwork === true) {
            controller.set("developerAccount.customNetwork", 1);
          }
          if (response.ecommerceProductMapping && response.ecommerceProductMapping.length > 0) {

            var dataSet = [];
            var data = response.ecommerceProductMapping;
            var countProducts = 0;
            for (var i = 0; i < data.length; i++) {
              if (data[i].enabled && data[i].self_enabled) {
                controller.set("products_list_bool", true);
                dataSet.push(data[i]);
                if (data[i].product_name === "Click & Collect" && data[i].enabled) {
                  controller.set("clickCollectPageSetupUI", true);
                }
                if (data[i].product_name === "Return" && data[i].enabled) {
                  controller.set("returnPageSetupUI", true);
                }
                countProducts++;
              }
            }
            controller.set("products_list", dataSet);
            if (controller.get('returnPageSetupUI') === true || controller.get('clickCollectPageSetupUI') === true) {
              controller.set('showPageSetupUI', true);
            } else {
              controller.set('showPageSetupUI', false);
            }
          }
        }
      }, console.log);

      this.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/getmastertableslist', {
        headers: {
          'Accept': 'application/json',
          'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
        }
      }).then(function (response) {
        // if(response && response.result && response.result.channels){
        //   controller.set("channel_list", response.result.channels);
        // }
        if (response && response.result && response.result.roles) {
          for (var i = 0; i <= response.result.roles.length; i++) {
            if (response.result.roles[i].rolename === "EcommerceCustomer") {
              controller.set('role_id', response.result.roles[i].id);
              break;
            }
          }
        }
      }, console.log);

      // fetch countries
      this.get('ajax').request('v2/admin/countries').then(function (response) {
        controller.set("countries", response);
      }, console.log);
    }
  });
});