define('admin-portal/pods/components/courier-checkin-modal/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    session: _ember['default'].inject.service('session'),

    name: 'courier-checkin',
    classNames: ['courier-checkin'],

    courier: null,
    items: [],
    newBarcode: null,

    onHide: function onHide() {
      this.set("courier", null);
      this.set("items", []);
      this.set("newBarcode", null);
      this.set("locationCode", null);
    },

    actions: {
      onBarcodeScan: function onBarcodeScan() {
        var _this = this;

        if (this.get("newBarcode").length > 0) {
          var found = this.get("sampleData").find(function (a) {
            return a.barcode === _this.get("newBarcode");
          });
          if (found) {
            this.get("items").pushObject(found);
          } else {
            console.log("not found");
          }
          this.set("newBarcode", null);
        }
      },

      onLocationScan: function onLocationScan() {
        if (this.get("items").length > 0 && this.get("locationCode").length > 0) {
          this.get("onCheckin")({
            dlb: this.get("locationCode"),
            items: this.get("items")
          });
          this.set("courier", null);
          this.set("items", []);
          this.set("newBarcode", null);
          this.set("locationCode", null);
          this.execute("hide");
        }
      }
    }
  });
});