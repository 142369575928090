define('admin-portal/pods/locations/store-types/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service(),

    model: function model() {
      return this.get('ajax').request('v2/admin/storeTypes');
    },

    setupController: function setupController(controller, model) {
      controller.set('storeTypes', model);
    }
  });
});