define('admin-portal/router', ['exports', 'ember', 'admin-portal/config/environment'], function (exports, _ember, _adminPortalConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _adminPortalConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('login');
    this.route('dashboard');

    this.route('reports', function () {
      this.route('pods');
    });
    this.route('invoicing');

    this.route('reports.aged', { path: '/aged-report' });
    this.route('network-management');
    this.route('admin-users');
    this.route('consignments', function () {
      this.route('pods');
      this.route('outbound');
      // this.route('alternate-names'); Removed so hubbed cant do it
    });
    this.route('locations', function () {
      this.route('new');
      this.route('search');
      this.route('store-types');
      this.route('download');
    });
    this.route('consumer', function () {
      this.route('promo-codes');
      this.route('users');
      this.route('invalid-consignments');
      this.route('consignment', { path: '/consignments/:consignment_id' });
      this.route('promo-test');
    });
    this.route('lockers', function () {
      this.route('locker-units');
      this.route('locker-unit', { path: '/locker-units/:locker_id' });
      this.route('consignments', { path: '/consignments/:id' });
      this.route('exception-notices');
      this.route('audit-logs');
    });
    this.route('transactions', function () {
      this.route('exports');
      this.route('unexported');
    });
    this.route('courier', function () {
      this.route('couriers');
      this.route('new');
      this.route('update', { path: '/:code' });
    });
    this.route('ecommerce', function () {
      this.route('list');
      this.route('new');
      this.route('customer', { path: '/customer/:id' });
      this.route('details', { path: '/details/:id' });
      this.route('view', { path: '/view/:id' });
      this.route('edit', { path: '/edit/:id' });
      this.route('cedit', { path: '/cedit/:id' });
    });
    this.route('unlinked', function () {
      this.route('consignments');
      this.route('consignment', { path: '/consignments/:barcode_id' });
    });
    this.route('upload-image');
    this.route('parcel-status');
    this.route('tracking');
    this.route('seven-eleven');
    this.route('manifest');
    this.route('sim');

    this.route('not-found', { path: '/*path' });
  });

  exports['default'] = Router;
});