define('admin-portal/pods/upload-image/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        config: _ember['default'].inject.service('config'),
        ajax: _ember['default'].inject.service("ajax"),

        setupController: function setupController(controller) {
            var _this = this;

            controller.set('getNotificationID', '');
            controller.set('existingImageBlob', '');
            controller.set('notification_name', '');
            controller.set('description', 'Added by Admin User');
            //var currentDate = new Date();
            //var formatedDateStr  = currentDate.getFullYear()+'-'+("0" + (currentDate.getMonth() + 5)).slice(-2)+'-'+("0" + currentDate.getDate()).slice(-2);
            var formatedDateStr = '2100-12-31'; //hardcode as per mentioned

            controller.set('expiry', formatedDateStr);
            //controller.set('notification_name', 'Update Agent Notifications');
            controller.set('enabled', false);

            this.get('ajax').request('v2/admin/agent/notifications?perPage=1').then(function (response) {
                if (response && response.results && response.results.length > 0) {
                    controller.set('notification_name', response.results[0].notificationName);
                    controller.set('description', response.results[0].description);
                    controller.set('enabled', response.results[0].enabled);
                    controller.set("getNotificationID", response.results[0].id);
                    _this.get('ajax').request('v2/agent/notification/' + response.results[0].id).then(function (imageBlob) {
                        controller.set("existingImageBlob", imageBlob);
                    }, console.log);
                }
            }, console.log);
        }
    });
});