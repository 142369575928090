define('admin-portal/pods/network-management/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    config: _ember['default'].inject.service('config'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    notify: _ember['default'].inject.service(),

    loading: false,
    showCarrierFilterBool: false,
    showPartnerFilterBool: false,
    showFilterBool: false,
    regenerateFileData: null,
    page: 1,
    totalPages: 1,

    downloadLocationURL: _ember['default'].computed('session.data.authenticated.dotnet_jwt', 'config.carrierApiURL', 'network.filter_by_country', 'network.filter_by_channel', 'network.filter_by_product', 'sub_filter_by_carrier', 'sub_filter_by_partner', 'sub_filter_by_customer', 'assign_carrier', 'assign_partner', 'Search_Store', 'assign_customer', function () {

      var jwt = this.get('session.data.authenticated.dotnet_jwt');
      var queryStr = 'token=' + jwt;
      if (this.get('network.filter_by_country') !== '' && this.get('network.filter_by_country') !== undefined) {
        queryStr += '&country=' + this.get("network.filter_by_country").trim();
      }
      if (this.get('network.filter_by_channel') !== '' && this.get('network.filter_by_channel') !== undefined) {
        queryStr += '&channelId=' + this.get("network.filter_by_channel");
      }
      // if(this.get('network.filter_by_product') !== '' && this.get('network.filter_by_product') !== undefined) { queryStr += '&productId='+ this.get("network.filter_by_product"); }

      if (this.get('network.filter_by_product') !== '' && this.get('network.filter_by_product') !== undefined && (this.get('network.filter_by_product') === 0 || this.get('network.filter_by_product') === "0")) {
        queryStr += '&productId=3';
      } else if (this.get('network.filter_by_product') !== '' && this.get('network.filter_by_product') !== undefined) {
        queryStr += '&productId=' + this.get("network.filter_by_product");
      }

      if (this.get('showCarrierFilterBool') === true && this.get('sub_filter_by_carrier') !== '' && this.get('sub_filter_by_carrier') !== null && this.get('sub_filter_by_carrier') !== "null") {
        queryStr += '&filterByCourierCode=' + this.get("sub_filter_by_carrier");
      }
      if (this.get('showPartnerFilterBool') === true && this.get('sub_filter_by_partner') !== '' && this.get('sub_filter_by_partner') !== null && this.get('sub_filter_by_partner') !== "null") {
        queryStr += '&filterByPartnerId=' + this.get("sub_filter_by_partner");
      }
      if (this.get('showPartnerFilterBool') === true && this.get('sub_filter_by_customer') !== '' && this.get('sub_filter_by_customer') !== null && this.get('sub_filter_by_customer') !== "null") {
        queryStr += '&filterByCustomerId=' + this.get("sub_filter_by_customer");
      }

      if (this.get('showCarrierFilterBool') === true && this.get('assign_carrier') !== '' && this.get('assign_carrier') !== null && this.get('assign_carrier') !== null) {
        queryStr += '&selectedCourierCode=' + this.get("assign_carrier");
      }
      if (this.get('showPartnerFilterBool') === true && this.get('assign_partner') !== '' && this.get('assign_partner') !== null && this.get('assign_partner') !== null) {
        queryStr += '&selectedPartnerId=' + this.get("assign_partner");
      }
      if (this.get('showPartnerFilterBool') === true && this.get('assign_customer') !== '' && this.get('assign_customer') !== null && this.get('assign_customer') !== null) {
        queryStr += '&selectedCustomerId=' + this.get("assign_customer");
      }

      return this.get("config.carrierApiURL") + 'admin/networkManagement/exportAllocatedLocationsToCsv?' + queryStr;
    }),
    StoreURL: _ember['default'].computed('config.carrierApiURL', 'StoreLocation', {
      get: function get() {
        if (this.get('StoreLocation') !== '' && this.get("valueNotAsssignedBool") === true) {
          this.set('StoreLocation', '');
        }
        var queryStrr = "";
        if (this.get('network.filter_by_country') !== '' && this.get('network.filter_by_country') !== undefined) {
          queryStrr += '&country=' + this.get("network.filter_by_country").trim();
        }

        if (this.get('network.filter_by_channel') !== '' && this.get('network.filter_by_channel') !== undefined) {
          queryStrr += '&channelId=' + this.get("network.filter_by_channel");
        }

        if (this.get('network.filter_by_product') !== '' && this.get('network.filter_by_product') !== undefined && (this.get('network.filter_by_product') === 0 || this.get('network.filter_by_product') === "0")) {
          queryStrr += '&productId=3';
        } else if (this.get('network.filter_by_product') !== '' && this.get('network.filter_by_product') !== undefined) {
          queryStrr += '&productId=' + this.get("network.filter_by_product");
        }
        return this.get("config.carrierApiURL") + 'admin/NetworkManagement/GetDropLocations?' + queryStrr + '&name={query}';
      }
    }),
    locationsResponseWrap: function locationsResponseWrap(response) {
      var responseData = JSON.parse(response);
      return {
        results: responseData.result
      };
    },

    init: function init() {
      _ember['default'].$.fn.search.settings.templates["locationResult"] = function (response, fields) {
        var html = '';
        if (response[fields.results] !== undefined) {
          _ember['default'].$.each(response[fields.results], function (index, result) {
            html += '<a class="result">';
            html += '<div class="content">' + result["name"] + '</div>';
            html += '</a>';
          });
          return html;
        }
        return false;
      };
      _ember['default'].$.fn.search.settings.templates["message"] = function (message, type) {
        var html = '<div class="result nodataFound">No record found</div>';
        return html;
      };
    },

    actions: {
      remove_filters: function remove_filters() {
        this.set("removeFiltersBool", false);
        this.set("customersArr", null);

        this.set('sub_filter_by_partner', null);
        this.set('sub_filter_by_customer', null);
        this.set('sub_filter_by_carrier', null);

        this.set('temp_sub_filter_by_partner', null);
        this.set('temp_sub_filter_by_customer', null);
        this.set('temp_sub_filter_by_carrier', null);
        // this.set('temp_sub_filter_by_product', null);

        this.set("valueNotAsssignedBool", true);

        this.set('enabledAllocateLocationsArr', null);
        this.set('disabledAllocateLocationsArr', null);

        this.set('assign_carrier', null);
        this.set('assign_partner', null);
        this.set('assign_customer', null);

        this.send("getPage", 1);
      },
      search_by_Store: function search_by_Store(value) {
        this.set("StoreLocation", value.name);
        this.set("StoreSearchId", value.id);
        this.send("getPage", 1);
      },

      change_search_Store: function change_search_Store() {
        if (this.get("StoreLocation") === "") {
          this.set("StoreSearchId", undefined);
          this.send("getPage", 1);
        }
      },
      fetch_customers: function fetch_customers(val) {
        var _this = this;

        this.set("customersArr", null);
        this.set('temp_sub_filter_by_customer', null);
        this.set("sub_filter_by_customer", null);

        if (val !== null && val !== "null") {
          this.get('ajax').request(this.get("config.carrierApiURL") + 'admin/networkManagement/getCustomers?partnerId=' + val, {
            headers: {
              'Accept': 'application/json',
              'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
            }
          }).then(function (response) {
            _this.set('temp_sub_filter_by_customer', null);
            if (response.partners && response.partners.length > 0) {
              _this.set("customersArr", response.partners);
            } else {
              _this.set("customersArr", []);
            }
          }, console.log);
        } else {
          this.set("customersArr", []);
        }
      },
      allocation_data_by_partner: function allocation_data_by_partner(val) {
        var _this2 = this;

        this.set("assign_partner", val);
        this.set('StoreLocation', null);
        this.set('StoreSearchId', null);
        if (val === 'null') {
          this.set("valueNotAsssignedBool", true);
        } else {
          this.set("valueNotAsssignedBool", false);
        }
        this.set("disableUploadbutton", false);
        //get customer based on partner selected
        this.set("allocateCustomersArr", null);
        this.set("assign_customer", null);

        if (val !== null && val !== "null") {
          this.get('ajax').request(this.get("config.carrierApiURL") + 'admin/networkManagement/getCustomers?partnerId=' + val, {
            headers: {
              'Accept': 'application/json',
              'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
            }
          }).then(function (response) {
            _this2.set("allocateCustomersArr", response.partners);
          }, console.log);
        }
        this.send("getPage", 1);
      },
      allocation_data_by_courier: function allocation_data_by_courier(val) {
        this.set('StoreLocation', null);
        this.set('StoreSearchId', null);
        this.set("assign_carrier", val);
        if (val === 'null') {
          this.set("valueNotAsssignedBool", true);
        } else {
          this.set("valueNotAsssignedBool", false);
        }
        this.send("getPage", 1);
      },
      allocation_data_by_customer: function allocation_data_by_customer(val) {
        this.set("assign_customer", val);
        this.set("valueNotAsssignedBool", false);
        this.set("StoreLocation", null);
        this.set('StoreSearchId', null);
        this.send("getPage", 1);
      },

      reportCsvUpload: function reportCsvUpload() {
        var fileContent = document.getElementById('csv-file-upload').files[0];
        this.set('regenerateFileData', fileContent);
      },

      upload_csv_report: function upload_csv_report() {
        var self = this;
        // if(false) {
        if (this.get('regenerateFileData')) {
          this.set("uploadingTransactionBool", true);

          var formDataUpload = new FormData();
          formDataUpload.append('file', this.get('regenerateFileData'));

          $.ajax({
            url: this.get("config.carrierApiURL") + 'admin/NetworkManagement/AllocateStoreLocationsByCsv',
            headers: {
              'Accept': 'application/json',
              'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
            },
            data: formDataUpload,
            processData: false,
            contentType: false,
            type: 'POST',
            success: function success(data) {
              self.set("uploadingTransactionBool", false);
              if (data && data.responseCode && data.responseCode === "200") {
                self.get('notify').success(data.responseMessage);

                self.send("getPage", 1);
              } else {
                self.get('notify').error(data.responseMessage);
              }
            },
            error: function error(jqXHR, exception) {
              self.set("uploadingTransactionBool", false);
              var msg = '';

              if (jqXHR.status === 0) {
                console.log('what in it', jqXHR);
                msg = 'Not connect.\n Verify Network.';
              } else if (jqXHR.status === 404) {
                msg = 'Requested page not found. [404]';
              } else if (jqXHR.status === 500) {
                msg = 'Internal Server Error [500].';
              } else if (exception === 'parsererror') {
                msg = 'Requested JSON parse failed.';
              } else if (exception === 'timeout') {
                msg = 'Time out error.';
              } else if (exception === 'abort') {
                msg = 'Ajax request aborted.';
              } else if (jqXHR.status === 400) {
                msg = jqXHR.responseJSON.responseMessage;
              } else {
                msg = 'Uncaught Error.\n' + jqXHR.responseText;
              }
              self.get('notify').error(msg);
            }
          });
        } else {
          this.get('notify').error('Please upload the file');
        }
        // }
      },
      getPage: function getPage(page) {
        var _this3 = this;

        var self = this;
        self.get("loadingSpinner").show();
        this.set("noAllocationEnabledBool", true);
        this.set("enableDownloadCSVBool", false);
        this.set("alreadyAllocationExists", false);
        var queryStr = '?pageNo=' + page + '&perPageLocations=10';
        if (this.get('network.filter_by_country') !== '' && this.get('network.filter_by_country') !== undefined) {
          queryStr += '&country=' + this.get("network.filter_by_country").trim();
        }
        if (this.get('network.filter_by_channel') !== '' && this.get('network.filter_by_channel') !== undefined) {
          queryStr += '&channelId=' + this.get("network.filter_by_channel");
        }
        if (this.get('network.filter_by_product') !== '' && this.get('network.filter_by_product') !== undefined && (this.get('network.filter_by_product') === 0 || this.get('network.filter_by_product') === "0")) {
          queryStr += '&productId=3';
        } else if (this.get('network.filter_by_product') !== '' && this.get('network.filter_by_product') !== undefined) {
          queryStr += '&productId=' + this.get("network.filter_by_product");
        }

        if (this.get('showCarrierFilterBool') === true && this.get('sub_filter_by_carrier') !== '' && this.get('sub_filter_by_carrier') !== null && this.get('sub_filter_by_carrier') !== "null") {
          queryStr += '&filterByCourierCode=' + this.get("sub_filter_by_carrier");
        }
        if (this.get('showPartnerFilterBool') === true && this.get('sub_filter_by_partner') !== '' && this.get('sub_filter_by_partner') !== null && this.get('sub_filter_by_partner') !== "null") {
          queryStr += '&filterByPartnerId=' + this.get("sub_filter_by_partner");
        }
        if (this.get('showPartnerFilterBool') === true && this.get('sub_filter_by_customer') !== '' && this.get('sub_filter_by_customer') !== "null" && this.get('sub_filter_by_customer') !== null) {
          queryStr += '&filterByCustomerId=' + this.get("sub_filter_by_customer");
        }

        if (this.get('showCarrierFilterBool') === true && this.get('assign_carrier') !== '' && this.get('assign_carrier') !== null && this.get('assign_carrier') !== "null") {
          queryStr += '&selectedCourierCode=' + this.get("assign_carrier");
        }
        if (this.get('showPartnerFilterBool') === true && this.get('assign_partner') !== '' && this.get('assign_partner') !== null && this.get('assign_partner') !== "null") {
          queryStr += '&selectedPartnerId=' + this.get("assign_partner");
        }
        if (this.get('showPartnerFilterBool') === true && this.get('assign_customer') !== '' && this.get('assign_customer') !== null && this.get('assign_customer') !== "null") {
          queryStr += '&selectedCustomerId=' + this.get("assign_customer");
        }

        if (this.get('StoreSearchId') !== '' && this.get('StoreSearchId') !== undefined) {
          queryStr += '&selectedLocationId=' + this.get("StoreSearchId");
        }

        this.get('ajax').request(this.get("config.carrierApiURL") + 'admin/networkManagement/getStoreLocations' + queryStr, {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          }
        }).then(function (responseData) {
          if (responseData && responseData.locations) {
            var result = responseData;
            self.set('page', result.pageNo);
            if (result.pageNo === 1 && result.totalPages === 0) {
              self.set('totalPages', 1);
            } else {
              self.set('totalPages', result.totalPages);
            }
            if (result.locations && result.locations.length > 0) {
              var locationsArr = result.locations;
              var enabledLocationsArr = [];
              var disabledLocationsArr = [];

              for (var i = 0; i < locationsArr.length; i++) {
                if (locationsArr[i].enabled === true) {
                  enabledLocationsArr.push(locationsArr[i].id);
                } else {
                  disabledLocationsArr.push(locationsArr[i].id);
                }
              }
              if (enabledLocationsArr.length >= 1) {
                _this3.set("alreadyAllocationExists", true);
                _this3.set("enableDownloadCSVBool", true);
                _this3.set("noAllocationEnabledBool", false);
              }

              _this3.set('enabledAllocateLocationsArr', enabledLocationsArr);
              _this3.set('disabledAllocateLocationsArr', disabledLocationsArr);
            }
            document.getElementById('csv-file-upload').value = '';
            self.set('storeDataObj', _ember['default'].A(result.locations));
            self.set("loading", false);
            self.get("loadingSpinner").hide();
          } else {
            self.set('storeDataObj', _ember['default'].A([]));

            self.set("loading", false);
            self.get("loadingSpinner").hide();
          }
        }, function () /*error*/{
          _this3.get("loadingSpinner").hide();
        });
      },

      filter_data: function filter_data() {
        if (this.get('temp_sub_filter_by_partner') !== null || this.get('temp_sub_filter_by_customer') !== null || this.get('temp_sub_filter_by_carrier') !== null) {

          this.set("sub_filter_by_customer", this.get('temp_sub_filter_by_customer'));
          this.set("sub_filter_by_partner", this.get('temp_sub_filter_by_partner'));
          this.set("sub_filter_by_carrier", this.get('temp_sub_filter_by_carrier'));
          // this.set("sub_filter_by_product", this.get('temp_sub_filter_by_product'));
          this.set("enableDownloadCSVBool", true);
          this.set("removeFiltersBool", true);
          this.set("valueNotAsssignedBool", true);
          this.set("allocateCustomersArr", null);
          this.set('enabledAllocateLocationsArr', null);
          this.set('disabledAllocateLocationsArr', null);
          this.set('StoreLocation', null);
          this.set('StoreSearchId', null);
          this.set('assign_carrier', null);
          this.set('assign_partner', null);
          this.set('assign_customer', null);
          this.set('Search_Store', null);
          this.send("getPage", 1);
        }
      },
      search_by: function search_by() {
        this.set("customersArr", null);
        this.set("allocateCustomersArr", null);
        this.set("showFilterBool", false);
        this.set("showCarrierFilterBool", false);
        this.set("showPartnerFilterBool", false);
        this.set("valueNotAsssignedBool", true);
        this.set("removeFiltersBool", false);
        this.set('enabledAllocateLocationsArr', null);
        this.set('disabledAllocateLocationsArr', null);
        this.set('assign_carrier', null);
        this.set('assign_partner', null);
        this.set('assign_customer', null);
        // this.set('StoreSearchId', null);
        this.set('sub_filter_by_partner', null);
        this.set('sub_filter_by_customer', null);
        this.set('sub_filter_by_carrier', null);
        this.set('StoreLocation', null);
        // this.set('sub_filter_by_product', null);
        this.set('temp_sub_filter_by_partner', null);
        this.set('temp_sub_filter_by_customer', null);
        this.set('temp_sub_filter_by_carrier', null);
        // this.set('temp_sub_filter_by_product', null);
        this.set('StoreSearchId', null);

        var selected_products = parseInt(this.get("network.filter_by_product"));
        if (selected_products === 1 || selected_products === 2 || selected_products === 0) {
          this.set("showPartnerFilterBool", true);
          this.set("showFilterBool", true);
        }
        if (selected_products === 3 || selected_products === 4) {
          this.set("showCarrierFilterBool", true);
          this.set("showFilterBool", true);
        }
        this.send("getPage", 1);
      },
      select_locations_for_allocation: function select_locations_for_allocation(data) {
        this.set("enableDownloadCSVBool", true);
        if (data.enabled === null) {
          _ember['default'].set(data, "enabled", false);
        }
        _ember['default'].set(data, "enabled", !data.enabled);

        var enabledLocations = this.get('enabledAllocateLocationsArr');
        var disabledLocations = this.get('disabledAllocateLocationsArr');

        if (data.enabled === true) {
          enabledLocations.push(data.id);

          if (disabledLocations.indexOf(data.id) !== -1) {
            disabledLocations.splice(disabledLocations.indexOf(data.id), 1);
          }
        } else {
          disabledLocations.push(data.id);
          if (enabledLocations.indexOf(data.id) !== -1) {
            enabledLocations.splice(enabledLocations.indexOf(data.id), 1);
          }
        }
        // if(enabledLocations.length>=1) {
        //   this.set("enableDownloadCSVBool", true);
        // }
        if (enabledLocations.length >= 1 || this.get("alreadyAllocationExists") === true) {
          this.set("noAllocationEnabledBool", false);
        } else {
          this.set("noAllocationEnabledBool", true);
        }
        this.set('enabledAllocateLocationsArr', enabledLocations);
        this.set('disabledAllocateLocationsArr', disabledLocations);
      },

      allocated_locations: function allocated_locations() {
        var _this4 = this;

        var enabledLocations = this.get('enabledAllocateLocationsArr');
        var disabledLocations = this.get('disabledAllocateLocationsArr');

        var dataObj = {
          enabledStoreLocationsIdList: enabledLocations,
          disabledStoreLocationsIdList: disabledLocations,
          type: this.get("showCarrierFilterBool") && this.get("showCarrierFilterBool") === true ? "Couriers" : "Users"
        };
        if (this.get('network.filter_by_product') !== '' && this.get('network.filter_by_product') !== undefined && (this.get('network.filter_by_product') === 0 || this.get('network.filter_by_product') === "0")) {
          dataObj['productId'] = 3;
        } else if (this.get('network.filter_by_product') !== '' && this.get('network.filter_by_product') !== undefined) {
          dataObj['productId'] = this.get('network.filter_by_product');
        }
        var customerVal = null;
        if (this.get('assign_customer') !== "null") {
          customerVal = this.get('assign_customer');
        }
        if (this.get('showCarrierFilterBool') === true) {
          dataObj['courierCode'] = this.get('assign_carrier');
        } else {
          dataObj['partnerId'] = this.get('assign_partner');
          dataObj['customerId'] = customerVal;
        }
        this.get('ajax').post(this.get("config.carrierApiURL") + 'admin/networkManagement/allocateStoreLocations', {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          },
          data: JSON.stringify(dataObj),
          contentType: 'application/json; charset=utf-8',
          crossDomain: true
        }).then(function (data) {
          _this4.set("isSavingBool", false);

          if (data.responseCode === "200" || data.responseCode === 200) {
            _this4.get('notify').success(data.responseMessage);
            _this4.send("getPage", 1);
          } else {
            _this4.get('notify').error(data.responseMessage);
          }
        })['catch'](function (error) {
          _this4.set("isSavingBool", false);
          var errorMsg = error.errors.map(function (e) {
            return e.detail;
          });
          if (errorMsg[0] && errorMsg[0].message) {
            _this4.get('notify').error(errorMsg[0].message);
          } else if (errorMsg[0] && errorMsg[0].result && errorMsg[0].result.length > 0) {
            _this4.get('notify').error(errorMsg[0].result[0].description);
          } else {
            _this4.get('notify').error('Error while saving, please try again.');
          }
        });
      }
    }

  });
});