define('admin-portal/helpers/to-fixed', ['exports', 'ember'], function (exports, _ember) {
  exports.toFixed = toFixed;

  function toFixed(params) {
    var num = params[0];
    var digits = params[1];
    return num.toFixed(digits);
  }

  exports['default'] = _ember['default'].Helper.helper(toFixed);
});