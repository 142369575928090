define('admin-portal/pods/reports/index/controller', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    config: _ember['default'].inject.service('config'),

    couriers: [{ name: "All", code: null }, { name: "CouriersPlease", code: "cpl" }, { name: "DHL Express", code: "dhl" }, { name: "Direct Freight Express", code: "DIRECT_FREIGHT_EXPRESS_1563770922" }, { name: "DHL NZ", code: "dhlnz" }, { name: "DHL Supply Chain", code: "dsc" }, { name: "TNT", code: "tnt" }, { name: "TOLL", code: "toll" }, { name: "TOLL NZ", code: "TOLL_NZ_1562218540" }, { name: "UPS", code: "UPS_1562219440" }],

    activityReport: {
      startDate: new Date(),
      endDate: new Date(),
      courier: null
    },
    activityReportURL: _ember['default'].computed('activityReport.startDate', 'activityReport.endDate', 'activityReport.courier', 'session.data.authenticated', 'config.apiURL', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      var startDate = (0, _moment['default'])(this.get("activityReport.startDate")).format("YYYY-MM-DD");
      var endDate = (0, _moment['default'])(this.get("activityReport.endDate")).format("YYYY-MM-DD");
      var courier = this.get("activityReport.courier");
      if (courier && courier.code) {
        return this.get("config.apiURL") + '/v2/admin/exports/newsagentConsignmentActivity?start=' + startDate + '&end=' + endDate + '&courier=' + courier.code + '&token=' + jwt;
      } else {
        return this.get("config.apiURL") + '/v2/admin/exports/newsagentConsignmentActivity?start=' + startDate + '&end=' + endDate + '&token=' + jwt;
      }
    }),

    deliveryChoicesURL: _ember['default'].computed('session.data.authenticated', 'config.apiURL', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      return this.get("config.apiURL") + '/v2/admin/exports/deliveryChoices?token=' + jwt;
    }),

    awaitingCollectionURL: _ember['default'].computed('session.data.authenticated', 'config.apiURL', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      return this.get("config.apiURL") + '/v2/admin/exports/awaitingCollection?token=' + jwt;
    }),

    consumerPortalUserURL: _ember['default'].computed('session.data.authenticated', 'config.apiURL', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      return this.get("config.apiURL") + '/v2/admin/exports/consumerPortal/users?token=' + jwt;
    }),

    consumerPortalReferralCodesURL: _ember['default'].computed('session.data.authenticated', 'config.apiURL', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      return this.get("config.apiURL") + '/v2/admin/exports/consumerPortal/referralCodes?token=' + jwt;
    }),

    noSignaturesReport: {
      startDate: new Date(),
      endDate: new Date()
    },
    noSignaturesReportURL: _ember['default'].computed('noSignaturesReport.startDate', 'noSignaturesReport.endDate', 'session.data.authenticated', 'config.apiURL', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      var startDate = (0, _moment['default'])(this.get("noSignaturesReport.startDate")).format("YYYY-MM-DD");
      var endDate = (0, _moment['default'])(this.get("noSignaturesReport.endDate")).format("YYYY-MM-DD");
      return this.get("config.apiURL") + '/v2/admin/exports/noSignatures?start=' + startDate + '&end=' + endDate + '&token=' + jwt;
    }),

    unauthenticReport: {
      startDate: new Date(),
      endDate: new Date()
    },
    unauthenticReportURL: _ember['default'].computed('unauthenticReport.startDate', 'unauthenticReport.endDate', 'session.data.authenticated', 'config.apiURL', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      var startDate = (0, _moment['default'])(this.get("unauthenticReport.startDate")).format("YYYY-MM-DD");
      var endDate = (0, _moment['default'])(this.get("unauthenticReport.endDate")).format("YYYY-MM-DD");
      return this.get("config.apiURL") + '/v2/admin/exports/unauthenticManual?start=' + startDate + '&end=' + endDate + '&token=' + jwt;
    }),

    consumerPortalConsignments: {
      startDate: new Date(),
      endDate: new Date()
    },
    consumerPortalConsignmentsReportURL: _ember['default'].computed('consumerPortalConsignments.startDate', 'consumerPortalConsignments.endDate', 'session.data.authenticated', 'config.apiURL', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      var startDate = (0, _moment['default'])(this.get("consumerPortalConsignments.startDate")).format("YYYY-MM-DD");
      var endDate = (0, _moment['default'])(this.get("consumerPortalConsignments.endDate")).format("YYYY-MM-DD");
      return this.get("config.apiURL") + '/v2/admin/exports/consumerPortal/consignments?start=' + startDate + '&end=' + endDate + '&token=' + jwt;
    }),

    consumerPortalCredit: {
      date: new Date()
    },
    consumerPortalCreditReportURL: _ember['default'].computed('consumerPortalCredit.date', 'session.data.authenticated', 'config.apiURL', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      var date = (0, _moment['default'])(this.get("consumerPortalCredit.date")).format("YYYY-MM");
      return this.get("config.apiURL") + '/v2/admin/exports/consumerPortal/creditAccounts?yearMonth=' + date + '&token=' + jwt;
    }),

    transactionReport: {
      startDate: new Date(),
      endDate: new Date()
    },
    transactionReportURL: _ember['default'].computed('transactionReport.startDate', 'transactionReport.endDate', 'session.data.authenticated', 'config.carrierApiURL', function () {
      var jwt = this.get('session.data.authenticated.dotnet_jwt');
      var startDate = (0, _moment['default'])(this.get("transactionReport.startDate")).format("YYYY-MM-DD 00:00");
      var endDate = (0, _moment['default'])(this.get("transactionReport.endDate")).format("YYYY-MM-DD 23:59");

      return this.get("config.carrierApiURL") + 'dashboard/downloadTransactionReportByDateRange?fromdate=' + { startDate: startDate } + '&todate=' + { endDate: endDate } + '&token=' + { jwt: jwt }, {
        headers: {
          'Accept': 'application/json',
          'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
        }
      };

      // return `${this.get("config.carrierApiURL")}dashboard/downloadTransactionReportByDateRange?fromdate=${startDate}&todate=${endDate}&token=${jwt}`;
      //return `${this.get("config.apiURL")}/v2/admin/transactions/exports/csvDateFiltered?start=${startDate}&end=${endDate}&token=${jwt}`;
    }),

    sevenTransactionReport: {
      startDate: new Date(),
      endDate: new Date()
    },
    sevenTransactionReportURL: _ember['default'].computed('sevenTransactionReport.startDate', 'sevenTransactionReport.endDate', 'session.data.authenticated', 'config.apiURL', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      var startDate = (0, _moment['default'])(this.get("sevenTransactionReport.startDate")).format("YYYY-MM-DD");
      var endDate = (0, _moment['default'])(this.get("sevenTransactionReport.endDate")).format("YYYY-MM-DD");
      return this.get("config.apiURL") + '/v2/admin/exports/sevenEleven/income?start=' + startDate + '&end=' + endDate + '&token=' + jwt;
    }),

    sevenConsignmentReport: {
      startDate: new Date(),
      endDate: new Date()
    },
    sevenConsignmentReportURL: _ember['default'].computed('sevenConsignmentReport.startDate', 'sevenConsignmentReport.endDate', 'session.data.authenticated', 'config.apiURL', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      var startDate = (0, _moment['default'])(this.get("sevenConsignmentReport.startDate")).format("YYYY-MM-DD");
      var endDate = (0, _moment['default'])(this.get("sevenConsignmentReport.endDate")).format("YYYY-MM-DD");
      return this.get("config.apiURL") + '/v2/admin/exports/sevenEleven/consignments?start=' + startDate + '&end=' + endDate + '&token=' + jwt;
    }),

    newTransactionReportURL: _ember['default'].computed('newTransactionReport', 'session.data.authenticated', 'config.carrierApiURL', function () {
      var selectedRange = this.get("newTransactionReport");
      var firstDate = new Date(selectedRange['year'], selectedRange['month'], 1);
      var lastDate = new Date(selectedRange['year'], selectedRange['month'] + 1, 0);

      var startDate = (0, _moment['default'])(firstDate).format("YYYY-MM-DD") + ' 00:00';
      var toDate = (0, _moment['default'])(lastDate).format("YYYY-MM-DD") + ' 23:59';

      var queryString = '';

      if (startDate !== null) {
        queryString += '&fromdate=' + startDate;
      } else {
        queryString += '&fromdate=';
      }
      if (toDate !== null) {
        queryString += '&todate=' + toDate;
      } else {
        queryString += '&todate=';
      }
      // queryString+='&t='+ this.get('session.data.authenticated.dotnet_jwt');

      return this.get(this.get("config.carrierApiURL") + 'dashboard/downloadreport?reporttype=admintransactions' + queryString, {
        headers: {
          'Accept': 'text/html,application/xhtml+xml,application/xml',
          'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
        }
      });

      //return `${this.get("config.carrierApiURL")}dashboard/downloadreport?reporttype=admintransactions${queryString}`;
    }),
    podCompliance: {
      startDate: new Date(),
      endDate: new Date(),
      courier: null
    },

    locationsURL: _ember['default'].computed('session.data.authenticated', 'config.apiURL', {
      get: function get() {
        var jwt = this.get('session.data.authenticated.jwt');
        return this.get("config.apiURL") + '/v2/admin/dropLocations/search?name={query}&token=' + jwt;
      }
    }),

    locationsResponseWrap: function locationsResponseWrap(response) {
      return {
        results: JSON.parse(response)
      };
    },

    sevenLockerActivityReport: {
      startDate: new Date(),
      endDate: new Date()
    },

    sevenLockerActivityReportURL: _ember['default'].computed('sevenLockerActivityReport.startDate', 'sevenLockerActivityReport.endDate', 'session.data.authenticated', 'config.apiURL', {
      get: function get() {
        var jwt = this.get('session.data.authenticated.jwt');
        var startDate = (0, _moment['default'])(this.get("sevenLockerActivityReport.startDate")).format("YYYY-MM-DD");
        var endDate = (0, _moment['default'])(this.get("sevenLockerActivityReport.endDate")).format("YYYY-MM-DD");
        return this.get("config.apiURL") + '/v2/admin/exports/sevenEleven/lockers/overall?start=' + startDate + '&end=' + endDate + '&token=' + jwt;
      }
    }),

    lockerCapacityReportNowURL: _ember['default'].computed('session.data.authenticated', 'config.apiURL', {
      get: function get() {
        var jwt = this.get('session.data.authenticated.jwt');
        return this.get("config.apiURL") + '/v2/admin/exports/lockers/capacity?token=' + jwt;
      }
    }),

    smsFeeReport: {
      date: new Date(new Date().getFullYear(), new Date().getMonth() - 1),
      error: true,
      status: null
    },
    smsFeeReportURL: _ember['default'].computed('smsFeeReport.date', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      var date = (0, _moment['default'])(this.get("smsFeeReport.date")).format("YYYY-MM");
      return this.get("config.apiURL") + '/v2/admin/exports/smsFees?yearMonth=' + date + '&token=' + jwt;
    }),
    smsFeeReportStatusURL: _ember['default'].computed('smsFeeReport.date', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      var date = (0, _moment['default'])(this.get("smsFeeReport.date")).format("YYYY-MM");
      return 'v2/admin/exports/smsFees/status?yearMonth=' + date + '&token=' + jwt;
    }),
    smsFeeReportPreProcessURL: _ember['default'].computed('smsFeeReport.date', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      var date = (0, _moment['default'])(this.get("smsFeeReport.date")).format("YYYY-MM");
      return 'v2/admin/exports/smsFees/preprocess?yearMonth=' + date + '&token=' + jwt;
    }),

    smsFeeReportObserver: _ember['default'].observer('smsFeeReport.date', function () {
      this.send("smsFeeReportStatus");
    }),

    init: function init() {
      this.send("smsFeeReportStatus");
      _ember['default'].$.fn.search.settings.templates["locationResult"] = function (response, fields) {
        var html = '';
        console.log(response);
        if (response[fields.results] !== undefined) {
          _ember['default'].$.each(response[fields.results], function (index, result) {
            html += '<a class="result">';
            html += '<div class="content">';
            html += '<div class="title">' + result["name"] + '</div>';
            if (result["cpData"]) {
              html += '<div class="description">' + result["cpData"]["dlb"] + '</div>';
            }
            if (result["dhlData"]) {
              html += '<div class="description">' + result["dhlData"]["locationCode"] + '</div>';
            }
            html += '</div>';
            html += '</a>';
          });
          return html;
        }
        return false;
      };
    },

    actions: {
      selectLocation: function selectLocation(value) {
        this.set("podCompliance.location", value.id);
      },

      newDownloadReportAnalysis: function newDownloadReportAnalysis() {

        var startDate = (0, _moment['default'])(this.get("transactionReport.startDate")).format("YYYY-MM-DD 00:00");
        var endDate = (0, _moment['default'])(this.get("transactionReport.endDate")).format("YYYY-MM-DD 23:59");
        var AppendedURl = startDate + '&todate=' + endDate;
        var startDateFile = (0, _moment['default'])(this.get("transactionReport.startDate")).format("YYYY-MM-DD");
        var endDateFile = (0, _moment['default'])(this.get("transactionReport.endDate")).format("YYYY-MM-DD");
        var FileName = startDateFile + '_to_' + endDateFile;
        $.ajax({
          url: this.get("config.carrierApiURL") + 'dashboard/downloadTransactionReportByDateRange?fromdate=' + AppendedURl,
          type: 'GET',
          headers: {
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          },
          contentType: "application/json",
          crossDomain: true,
          success: function success(result) {
            console.log('success', result);
            var a = document.createElement('a');
            var blob = new Blob([result], { type: "octet/stream" });
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = 'transaction_analysis_report_' + FileName + '.csv';
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
          },
          error: function error(jqXHR, exception) {
            console.log('error', jqXHR);
          }
        });
      },

      podComplianceClear: function podComplianceClear() {
        this.set("podCompliance.locationDisplay", null);
        this.set("podCompliance.location", null);
      },

      smsFeeReportStatus: function smsFeeReportStatus() {
        var self = this;
        this.get('ajax').request(this.get("smsFeeReportStatusURL")).then(function (result) {
          self.set('smsFeeReport.error', false);
          self.set('smsFeeReport.status', result);
          if (self.get('smsFeeReport.status.started') && !self.get('smsFeeReport.status.finishedLinking')) {
            _ember['default'].run.later(function () {
              self.send('smsFeeReportStatus');
            }, 5000);
          }
        }, function (e) {
          console.log(e);
          self.set('smsFeeReport.error', true);
          self.set('smsFeeReport.status', null);
        });
      },

      retrySmsProcessingOpen: function retrySmsProcessingOpen() {
        _ember['default'].$('.ui.retry-sms-processing.modal').modal('show');
      },
      retrySmsProcessingClose: function retrySmsProcessingClose() {
        _ember['default'].$('.ui.retry-sms-processing.modal').modal('hide');
      },
      retrySmsProcessing: function retrySmsProcessing() {
        this.send('smsFeeReportPreProcess');
        _ember['default'].$('.ui.retry-sms-processing.modal').modal('hide');
      },

      smsFeeReportPreProcess: function smsFeeReportPreProcess() {
        var self = this;
        this.get('ajax').post(this.get("smsFeeReportPreProcessURL")).then(function () {
          _ember['default'].run.later(function () {
            self.send('smsFeeReportStatus');
          }, 100);
        }, function (e) {
          console.log(e);
        });
      },

      podComplianceGo: function podComplianceGo() {
        var startDate = (0, _moment['default'])(this.get("podCompliance.startDate")).format("YYYY-MM-DD");
        var endDate = (0, _moment['default'])(this.get("podCompliance.endDate")).format("YYYY-MM-DD");
        var courier = this.get("podCompliance.courier") ? this.get("podCompliance.courier").code : null;
        var location = this.get("podCompliance.location");
        this.transitionToRoute('reports.pods', { queryParams: { start: startDate, end: endDate, courier: courier, location: location } });
      }
    }

  });
});