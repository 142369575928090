define('admin-portal/helpers/find-count', ['exports', 'ember'], function (exports, _ember) {
  exports.findArrayCount = findArrayCount;

  function findArrayCount(params) {
    var parcelsArr = params[0];

    return parcelsArr.length;
  }

  exports['default'] = _ember['default'].Helper.helper(findArrayCount);
});