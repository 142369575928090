define('admin-portal/pods/transactions/exports/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service(),

    model: function model() {
      return this.get('ajax').request('v2/admin/transactions/exports');
    },

    setupController: function setupController(controller, model) {
      controller.set('exports', model);
    }
  });
});