define('admin-portal/pods/components/consignment-aged-parcel/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    session: _ember['default'].inject.service('session'),
    loadingSpinner: _ember['default'].inject.service('loading'),

    name: 'consignment-aged',
    classNames: ['consignment-aged'],

    onHide: function onHide() {
      this.get("loadingSpinner").hide();
    },

    actions: {
      confirm: function confirm() {
        this.get("onConfirm")(this.get("consignment"));
      },
      cancel: function cancel() {
        this.get("loadingSpinner").hide();
        _ember['default'].$('.ui.modal.consignment-aged').modal('hide');
      }
    }
  });
});