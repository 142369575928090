define('admin-portal/pods/consumer/users/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    config: _ember['default'].inject.service('config'),
    notify: _ember['default'].inject.service(),
    loadingService: _ember['default'].inject.service('loading'),

    queryParams: ["page", "user_id"],

    user: null,
    user_id: null,

    userCreditAccount: null,
    creditEditing: false,
    userCreditAccountHistory: {
      totalPages: 0,
      page: 0,
      results: []
    },

    loading: false,
    page: 1,
    totalPages: 1,
    consignments: _ember['default'].A([]),

    usersUrl: _ember['default'].computed('session.data.authenticated', 'config.apiURL', {
      get: function get() {
        var jwt = this.get('session.data.authenticated.jwt');
        return this.get('config.apiURL') + '/v2/consumerPortal/admin/users/search?name={query}&token=' + jwt;
      }
    }),

    jwt: _ember['default'].computed.alias('session.data.authenticated.jwt'),

    init: function init() {
      _ember['default'].$.fn.search.settings.templates["userResult"] = function (response, fields) {
        var html = '';
        console.log(response);
        if (response[fields.results] !== undefined) {
          _ember['default'].$.each(response[fields.results], function (index, result) {
            html += '<a class="result">';
            html += '<div class="content">';
            html += '<div class="title">' + result["firstName"] + " " + result["lastName"] + '</div>';
            html += '<div class="description">' + result["email"] + '</div>';
            html += '</div>';
            html += '</a>';
          });
          return html;
        }
        return false;
      };
    },

    creditReportURL: _ember['default'].computed('user_id', 'session.data.authenticated', 'config.apiURL', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      return this.get("config.apiURL") + '/v2/consumerPortal/admin/users/' + this.get("user_id") + '/creditAccount/history/csv?&token=' + jwt;
    }),

    actions: {

      selectUser: function selectUser(value) {
        this.set("user_id", value.id);
        this.send("getPage", 1);
        this.send("loadUser");
      },

      getPage: function getPage(page) {
        var self = this;
        this.get('ajax').request('v2/consumerPortal/admin/users/' + this.get("user_id") + '/consignments?page=' + page + '&perPage=10').then(function (result) {
          self.set('page', result.page);
          self.set('totalPages', result.totalPages);
          self.set('consignments', _ember['default'].A(result.results));
        }, function (error) {
          console.log(error);
        });
      },

      createCreditAccount: function createCreditAccount(userId) {
        var self = this;
        this.get("loadingService").show();
        this.get('ajax').post('v2/consumerPortal/admin/users/' + userId + '/creditAccount', {
          data: JSON.stringify({
            creditLimit: 100000
          }),
          contentType: "application/json",
          dataType: "text",
          crossDomain: true
        }).then(function () /*dl*/{
          self.send("loadUser");
          self.get("loadingService").hide();
          self.get("notify").success('Successfully added credit account.');
        }, function () /*error*/{
          self.get("loadingService").hide();
          self.get("notify").error('Error adding credit account.');
        });
      },

      loadUser: function loadUser() {
        var self = this;
        this.set("creditEditing", false);
        this.set("newLimit", 0);
        this.set("userCreditAccount", null);
        this.set("userCreditAccountHistory", {
          totalPages: 0,
          page: 0,
          results: []
        });
        this.get('ajax').request('v2/consumerPortal/admin/users/' + this.get("user_id")).then(function (result) {
          self.set('user', result);
        }, function (error) {
          console.log(error);
        });
        this.get('ajax').request('v2/consumerPortal/admin/users/' + this.get("user_id") + '/creditAccount').then(function (result) {
          self.set('userCreditAccount', result);
        }, function (error) {
          console.log(error);
        });
        self.send("getCreditAccountHistory", 1);
      },

      startEdit: function startEdit() {
        this.set("creditEditing", true);
        this.set("newLimit", (this.get("userCreditAccount.limit") / 100).toFixed(2));
      },

      cancelEdit: function cancelEdit() {
        this.set("creditEditing", false);
      },

      saveEdit: function saveEdit() {
        var self = this;
        this.get("loadingService").show();
        this.get('ajax').patch('v2/consumerPortal/admin/users/' + this.get("user_id") + '/creditAccount', {
          data: JSON.stringify({
            creditLimit: (parseFloat(this.get("newLimit")) * 100).toFixed(0)
          }),
          contentType: "application/json",
          dataType: 'text',
          crossDomain: true
        }).then(function () /*dl*/{
          self.send("loadUser");
          self.get("loadingService").hide();
          self.get("notify").success('Successfully edited credit account.');
        }, function () /*error*/{
          self.get("loadingService").hide();
          self.get("notify").error('Error editing credit account.');
        });
      },

      changeCreditAccount: function changeCreditAccount(value) {
        var self = this;
        this.get("loadingService").show();
        this.get('ajax').patch('v2/consumerPortal/admin/users/' + this.get("user_id") + '/creditAccount', {
          data: JSON.stringify({
            onHold: !value
          }),
          contentType: "application/json",
          dataType: 'text',
          crossDomain: true
        }).then(function () /*dl*/{
          self.send("loadUser");
          self.get("loadingService").hide();
          self.get("notify").success('Successfully edited credit account.');
        }, function () /*error*/{
          self.get("loadingService").hide();
          self.get("notify").error('Error editing credit account.');
        });
      },

      getCreditAccountHistory: function getCreditAccountHistory(page) {
        var self = this;
        this.get('ajax').request('v2/consumerPortal/admin/users/' + this.get("user_id") + '/creditAccount/history?page=' + page).then(function (result) {
          self.set('userCreditAccountHistory', result);
        }, function (error) {
          console.log(error);
        });
      }

    }

  });
});