define('admin-portal/pods/components/consignment-alternate-names-modal/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    session: _ember['default'].inject.service('session'),

    name: 'consignment-alternate-names-modal',
    classNames: ['consignment-alternate-names-modal'],

    consignment: null,

    onHide: function onHide() {
      this.set("consignment", null);
    },

    actions: {
      saveNewName: function saveNewName(name) {
        this.get("onSaveNewName")(name);
      }
    }

  });
});