define('admin-portal/pods/lockers/consignments/controller', ['exports', 'ember'], function (exports, _ember) {
		//import { isConflictError } from 'ember-ajax/errors';

		exports['default'] = _ember['default'].Controller.extend({
				config: _ember['default'].inject.service(),
				ajax: _ember['default'].inject.service(),
				session: _ember['default'].inject.service('session'),
				loadingSpinner: _ember['default'].inject.service('loading'),
				notify: _ember['default'].inject.service(),

				loading: false,
				displayButton: false,
				locker_id: '',
				page: 1,
				totalPages: 1,
				couriersData: _ember['default'].A([]),
				courierDeletionDetails: null,

				buildUrl: function buildUrl(page) {
						this.set('consArr', []);
						var url = 'v2/admin/' + this.get('locker_id') + '/getDropboxConsignmentNumber?page=' + page + '&perpage=20';
						return url;
				},

				AuDate: new Date().toLocaleString("en-GB", { timeZone: "Australia/Sydney" }),

				actions: {
						setLocker: function setLocker(locker_id) {
								this.set('locker_id', locker_id);
								this.send("getPage", 1);
						},
						getPage: function getPage(page) {
								this.set('displayButton', false);
								var self = this;
								self.set("loading", true);
								this.get('ajax').request(this.buildUrl(page)).then(function (responseData) {
										if (responseData && responseData.results) {
												self.set('page', responseData.page);
												if (responseData.page === 1 && responseData.page === 0) {
														self.set('totalPages', 1);
												} else {
														self.set('totalPages', responseData.totalPages);
												}
												self.set('dropboxDetails', _ember['default'].A(responseData.results));
												self.set("loading", false);
												self.get("loadingSpinner").hide();
										} else {
												self.set("loading", false);
										}
								}, function () /*error*/{
										self.set("loading", false);
								});
						},

						onCbChange: function onCbChange(consignmentList, e) {

								// console.log(consignmentList);
								// console.log(`onCbChange: el.checked=${e.target.checked}`);

								var state = '' + e.target.checked;
								//console.log(state);
								//this.set('consArr', consignmentList);
								if (state == 'true') {
										this.get('consArr').pushObject(consignmentList);
										this.set('displayButton', true);
								} else {
										this.get('consArr').removeObject(consignmentList);
								}

								if (this.get('consArr') && this.get('consArr').length == 0) {
										this.set('displayButton', false);
								}
								//console.log('consArr === ', this.get('consArr'));
						},

						Markoffbutton: function Markoffbutton(details) {

								this.set('popupValue', null);

								_ember['default'].$('.ui.parcel-data.modal').modal('show');

								var consignmentNumber = this.get('consArr').join(", ");

								//console.log('consignmentMarkoffList', consignmentNumber);

								this.set('consignmentMarkoffList', consignmentNumber);

								this.set('popupValue', consignmentNumber);
								this.set('reasondropdown', null);
								this.set('parcelStatus', null);
								this.set('isd', null);
								this.set('notify.enabled', null);
						},

						removeLockerUnitClose: function removeLockerUnitClose() {
								_ember['default'].$('.ui.remove-locker-unit.modal').modal('hide');
						},
						closeParcelModal: function closeParcelModal() {
								this.set('reasondropdown', null);
								this.set('popupValue', null);
								this.set('parcelStatus', null);
								this.set('isd', null);
								_ember['default'].$('.ui.parcel-data.modal').modal('hide');
						},

						Updatemarkoffbutton: function Updatemarkoffbutton() {
								var _this = this;

								var consignments = {};consignments = this.get('popupValue');
								var locker_id = this.get("drop_locker_id");
								var soso = this.get("reasondropdown");
								this.get('ajax').post('/v2/admin/lockers/dropbox/empty', {
										data: JSON.stringify({
												isd: this.get('isd'),
												eventType: "courier-pickup",
												lockerUnit: this.get("locker_id"),
												lockerId: locker_id,
												notifyCourier: false,
												reason: this.get('reasondropdown'),
												consignmentNumbers: this.get('consArr')
										}),
										contentType: "application/json",
										crossDomain: true
								}).then(function () {
										_this.get('notify').success('Successfully updated dropbox.');
										_ember['default'].$('.ui.parcel-data.modal').modal('hide');
										_this.send("getPage", 1);
										_this.set('reasondropdown', null);
										_this.set('parcelStatus', null);
										_this.set('isd', null);
										_this.set('notify.enabled', null);
										_this.set('popupValue', null);
										_this.set('displayButton', false);
										_this.set('consArr', []);
								}, function (error) {

										// this.set('reasondropdown',null);
										// this.set('parcelStatus',null);
										// this.set('isd',null);
										// this.set('notify.enabled',null);
										// this.set('popupValue',null);
										// this.set('consArr', []);

										//console.log(error.message);
										if (error.message === "Unexpected end of JSON input" || error.message === "JSON.parse: unexpected end of data at line 1 column 1 of the JSON data" || error.message === 'JSON Parse error: Unexpected EOF') {
												_this.get('notify').success('Successfully updated dropbox.');
												_this.send("getPage", 1);
												_this.set('displayButton', false);
												_ember['default'].$('.ui.parcel-data.modal').modal('hide');
										} else {
												_this.get('notify').error('Could not empty dropbox. Try refreshing this page.');
										}
								});
						},
						backTo: function backTo() {
								this.transitionToRoute('lockers.locker-unit', this.get('locker_id'));
								//return `${this.get("config.apiURL")}/v2/admin/lockerUnits/115`;
						}
				}

		});
});