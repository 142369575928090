define('admin-portal/helpers/courier-helper', ['exports', 'ember'], function (exports, _ember) {
  exports.courierHelper = courierHelper;

  function courierHelper(params) {
    var courier = '';
    if (params[0] !== null) {
      courier = params[0];
    }
    switch (courier) {
      case "dhl":
        return "DHL Express";
      case "cti":
        return "CTI";
      case "cpl":
        return "Couriers Please";
      case "toll":
        return "Toll";
      case "dsc":
        return "DHL Supply Chain";
      default:
        // return "Unknown Courier";
        if (courier !== undefined && courier.lastIndexOf("_") === -1) {
          return courier;
        } else if (courier !== undefined) {
          if (parseInt(courier.substr(courier.lastIndexOf("_") + 1))) {
            courier = courier.substr(0, courier.lastIndexOf("_"));
            courier = courier.replace(/_/g, ' ');
            return courier;
          } else {
            return courier;
          }
        } else {
          return courier;
        }
    }
  }

  exports['default'] = _ember['default'].Helper.helper(courierHelper);
});