define('admin-portal/pods/parcel-status/controller', ['exports', 'ember', 'moment', 'admin-portal/shapes/newcourier'], function (exports, _ember, _moment, _adminPortalShapesNewcourier) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    config: _ember['default'].inject.service('config'),
    session: _ember['default'].inject.service('session'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    notify: _ember['default'].inject.service(),

    startDate: '',
    endDate: '',
    loading: false,
    page: 1,
    totalPages: 1,
    listingData: _ember['default'].A([]),
    tokenStr: '11',
    markOff: false,

    locationsURL: _ember['default'].computed('config.carrierApiURL', {
      get: function get() {
        return this.get("config.carrierApiURL") + 'hubbed/droplocations/{query}';
        // const jwt = this.get('session.data.authenticated.jwt');
        // return `${this.get('config.apiURL')}/v2/admin/dropLocations/search?name={query}&token=${jwt}`;
      }
    }),
    locationsResponseWrap: function locationsResponseWrap(response) {
      var responseData = JSON.parse(response);
      return {
        results: responseData.result
      };
    },
    init: function init() {
      _ember['default'].$.fn.search.settings.templates["locationResult"] = function (response, fields) {
        var html = '';
        if (response[fields.results] !== undefined) {
          _ember['default'].$.each(response[fields.results], function (index, result) {
            html += '<a class="result">';
            html += '<div class="content">' + result["name"] + '</div>';
            html += '</a>';
          });
          return html;
        }
        return false;
      };
      _ember['default'].$.fn.search.settings.templates["message"] = function (message, type) {
        var html = '<a class="result">No record found</a>';
        return html;
      };
    },

    actions: {
      selectLocation: function selectLocation(value) {
        this.set("locationQuery", value.name);
        this.set("storeLocationId", value.id);
      },
      getPage: function getPage(page) {
        var _this = this;

        var self = this;
        self.set("loading", true);
        self.get("loadingSpinner").show();
        self.set("page_number", page);

        var startDate = '',
            endDate = '',
            selectedLocation = 0;

        var selectedStartDate = this.get("startDate") && this.get("startDate") !== "" && this.get("startDate") !== undefined ? this.get("startDate") : "";
        var selectedEndDate = this.get("endDate") && this.get("endDate") !== "" && this.get("endDate") !== undefined ? this.get("endDate") : "";
        if (selectedStartDate !== "" && selectedStartDate !== null) {
          startDate = (0, _moment['default'])(selectedStartDate).format("DD/MM/YYYY");
        }
        if (selectedEndDate !== "" && selectedEndDate !== null) {
          endDate = (0, _moment['default'])(selectedEndDate).format("DD/MM/YYYY");
        }
        if (this.get("locationQuery") && this.get("locationQuery") !== "" && this.get("locationQuery") !== undefined) {
          selectedLocation = this.get("storeLocationId");
        }
        this.get('ajax').post(this.get("config.carrierApiURL") + 'consignment/Searching', {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          },
          data: JSON.stringify({
            pageNumber: page,
            perPageRecords: '20',
            consignmentNumber: this.get("parcelStatusForm.consignmentNumber") && this.get("parcelStatusForm.consignmentNumber") !== "" && this.get("parcelStatusForm.consignmentNumber") !== undefined ? this.get("parcelStatusForm.consignmentNumber") : "",
            barcode: this.get("parcelStatusForm.article") && this.get("parcelStatusForm.article") !== "" && this.get("parcelStatusForm.article") !== undefined ? this.get("parcelStatusForm.article") : "",
            dropLocationId: selectedLocation,
            customerName: this.get("parcelStatusForm.customerName") && this.get("parcelStatusForm.customerName") !== "" && this.get("parcelStatusForm.customerName") !== undefined ? this.get("parcelStatusForm.customerName") : "",
            courierCode: this.get("courierCode") && this.get("courierCode") !== "" && this.get("courierCode") !== undefined ? this.get("courierCode") : "",
            parcelCheckInDateRange: {
              fromDate: startDate,
              toDate: endDate
            }
          }),
          contentType: 'application/json; charset=utf-8',
          crossDomain: true
        }).then(function (responseData) {

          if (responseData.responseCode == '400') {
            _this.get('notify').error(responseData.responseMessage);
          }
          self.set('page', responseData.pageNumber);
          self.set('totalPages', responseData.totalPages);
          self.set('listingData', _ember['default'].A(responseData.data));
          self.set("loading", false);
          self.get("loadingSpinner").hide();
        }, function () /*error*/{
          self.set('listingData', null);
          self.set("loading", false);
          self.get("loadingSpinner").hide();
        });
      },
      search: function search() {
        this.send("getPage", 1);
      },
      request_return: function request_return(consignment) {
        this.set("consignmentReturnState", 'recover');
        this.set("returnBarcode", null);
        this.set("returnConsignmentNumber", consignment.consignmentNumber);
        this.set("returnConsignmentId", consignment.consignmentId);
        _ember['default'].$(".ui.modal.consignment-request-return").modal('show');
      },
      aged_parcel: function aged_parcel(consignment) {
        var _this2 = this;

        //this.checkout_parcel(this.set("consignmentId", consignment.consignmentId),false);
        var constantConsignmentData;
        this.get('ajax').request(this.get("config.carrierApiURL") + 'consignment/GetCheckoutPopUpData/' + consignment.consignmentId, {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          }
        }).then(function (responseData) {
          _this2.get("loadingSpinner").hide();
          //this.set("consignmentCheckoutDetails", Ember.A(responseData.data));
          constantConsignmentData = responseData.data;
          //this.set("constantConsignmentData", newConsignmentData);
          _this2.get("loadingSpinner").hide();
          if (constantConsignmentData != undefined) {
            _this2.set("dropLocation", constantConsignmentData.dropLocationId);
            _this2.set("consignmentNumberNew", constantConsignmentData.consignmentNumber);
            _this2.set("receiverNameNew", constantConsignmentData.receiverName);
            _this2.set("barcodes", constantConsignmentData.barcodes[0]);
          }
          //Ember.$(".ui.modal.consignment-checkout").modal('show').scrollTop(0,0);
        }, function () {
          _this2.get("loadingSpinner").hide();
          //Ember.$('body').removeClass('updateCheckoutClass');
          _this2.get('notify').error('Some internal error, please try again.');
        });

        this.set("consignmentId", consignment.consignmentId);

        this.set("consignmentNumberold", consignment.consignmentNumber);
        this.set("droplocationID", consignment.dropLocationId);
        this.set("customerName", consignment.customerName);

        this.set("returnBarcode", consignment.barcodes);
        //this.set("returnConsignmentId", consignment.consignmentId);
        _ember['default'].$(".ui.modal.consignment-aged").modal('show');
      },
      returnagedParcelAPI: function returnagedParcelAPI(data) {
        var _this3 = this;

        var self = this;
        self.set("loading", true);
        self.get("loadingSpinner").show();
        var apiURLStr = "";
        var barcodedata = [this.get('returnBarcode')];
        var barcodevalue = barcodedata[0];

        var newBarcodeArr = [];
        var tempObj = {
          "barcodeNew": this.get('barcodes'),
          "barcodeOld": barcodevalue
        };
        newBarcodeArr.push(tempObj);

        apiURLStr = this.get("config.carrierApiURL") + 'consignment/Checkout';
        var requestData = {

          consignmentId: this.get("consignmentId"),
          consignmentNumberOld: this.get("consignmentNumberold"),
          consignmentNumberNew: this.get("consignmentNumberNew"),
          barcodeList: newBarcodeArr,
          iswishExpiredParcel: true,
          dropLocationIdNew: this.get("dropLocation"),
          dropLocationIdOld: this.get("droplocationID"),
          receiverNameOld: this.get("customerName"),
          receiverNameNew: this.get("receiverNameNew"),
          reasonForStatusChange: "Expired parcel - Discarded",
          adminUserId: this.get('admin_user_id')
        };
        this.get('ajax').post(apiURLStr, {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          },
          data: JSON.stringify(requestData),
          contentType: 'application/json; charset=utf-8',
          crossDomain: true
        }).then(function (responseData) {
          self.set("loading", false);
          if (responseData.responseCode && responseData.responseCode === "200") {
            _this3.set("consignmentNumberold", null);
            _this3.set("consignmentId", '');
            _this3.set("consignmentNumberNew", null);
            _this3.set("dropLocation", null);
            _this3.set("droplocationID", null);
            _this3.set("customerName", '');
            _this3.set("receiverNameNew", null);
            _this3.set("returnBarcode", null);
            _this3.set("barcodes", null);

            _ember['default'].$(".ui.modal.consignment-aged").modal('hide');
            _this3.send("getPage", _this3.get('page_number'));
            self.get("loadingSpinner").hide();
          } else if (responseData && responseData.responseMessage) {
            _this3.get('notify').error(responseData.responseMessage);
            self.set("loading", false);
          }
        }, function () /*error*/{
          _this3.get('notify').error('Some internal error occured, please try again.');
          self.set("loading", false);
        });
      },
      return_parcel: function return_parcel(consignment, barcode) {
        this.set("consignmentReturnState", 'return');
        barcode['consignmentNumber'] = consignment.consignmentNumber;
        barcode['droplocationId'] = consignment.dropLocationId;
        this.set("returnBarcode", barcode);
        this.set("returnConsignmentNumber", consignment.consignmentNumber);
        this.set("returnConsignmentId", consignment.consignmentId);
        _ember['default'].$(".ui.modal.consignment-request-return").modal('show');
      },
      checkout_parcel: function checkout_parcel(consignment, markOff) {
        var _this4 = this;

        this.set("markOff", markOff);
        _ember['default'].$('body').addClass('updateCheckoutClass');
        this.get("loadingSpinner").show();
        this.set("constantConsignmentData", null);
        this.set("consignmentCheckoutDetails", null);
        this.get('ajax').request(this.get("config.carrierApiURL") + 'consignment/GetCheckoutPopUpData/' + consignment.consignmentId, {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          }
        }).then(function (responseData) {
          _this4.get("loadingSpinner").hide();
          _this4.set("consignmentCheckoutDetails", _ember['default'].A(responseData.data));
          var constantConsignmentData = responseData.data;
          var newConsignmentData = {
            conId: constantConsignmentData.consignmentId,
            consignmentNo: constantConsignmentData.consignmentNumber,
            barcodeArr: constantConsignmentData.barcodes,
            dropLocation: constantConsignmentData.dropLocationId,
            dropLocationName: constantConsignmentData.dropLocationName,
            receiver: constantConsignmentData.receiverName,
            ismarkOff: markOff
          };
          _this4.set("constantConsignmentData", newConsignmentData);
          _this4.get("loadingSpinner").hide();
          _ember['default'].$(".ui.modal.consignment-checkout").modal('show').scrollTop(0, 0);
        }, function () {
          _this4.get("loadingSpinner").hide();
          _ember['default'].$('body').removeClass('updateCheckoutClass');
          _this4.get('notify').error('Some internal error, please try again.');
        });
      },
      show_parcel_info: function show_parcel_info(parcelId) {
        var _this5 = this;

        this.set("consignmentInfoDetails", null);
        this.get('ajax').request(this.get("config.carrierApiURL") + 'consignment/GetReturnedConsignmentInfo/' + parcelId, {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          }
        }).then(function (responseData) {
          _this5.get("loadingSpinner").hide();
          _this5.set("consignmentInfoDetails", responseData.result);
        }, function () {
          _this5.get("loadingSpinner").hide();
        });
        _ember['default'].$(".ui.modal.consignment-info").modal('show');
      },
      checkoutParcelAPI: function checkoutParcelAPI(data) {
        // just refresh data here as this is called on success
        _ember['default'].$('body').removeClass('updateCheckoutClass');
        _ember['default'].$(".ui.modal.consignment-checkout").modal('hide');
        this.send("getPage", this.get('page_number'));
      },
      returnParcelAPI: function returnParcelAPI(data) {
        var _this6 = this;

        var self = this;
        self.set("loading", true);
        self.get("loadingSpinner").show();

        var apiURLStr = this.get("config.carrierApiURL") + 'consignment/requestReturn';
        var requestData = {
          consignmentId: this.get('returnConsignmentId'),
          adminUserId: this.get('admin_user_id')
        };
        if (this.get("consignmentReturnState") === "return") {
          apiURLStr = this.get("config.carrierApiURL") + 'consignment/CompleteReturn';
          requestData = {
            consignmentId: this.get('returnConsignmentId'),
            adminUserId: this.get('admin_user_id'),
            barcodes: [this.get('returnBarcode')]
          };
        }
        this.get('ajax').post(apiURLStr, {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          },
          data: JSON.stringify(requestData),
          contentType: 'application/json; charset=utf-8',
          crossDomain: true
        }).then(function (responseData) {
          self.set("loading", false);
          if (responseData.responseCode && responseData.responseCode === "200") {
            _this6.set("returnConsignmentId", null);
            _this6.set("consignmentReturnState", '');
            _this6.set("returnConsignmentNumber", null);
            _this6.set("returnBarcode", null);

            _ember['default'].$(".ui.modal.consignment-request-return").modal('hide');
            _this6.send("getPage", _this6.get('page_number'));
            self.get("loadingSpinner").hide();
          } else if (responseData && responseData.responseMessage) {
            _this6.get('notify').error(responseData.responseMessage);
            self.set("loading", false);
          }
        }, function () /*error*/{
          _this6.get('notify').error('Some internal error occured, please try again.');
          self.set("loading", false);
        });
      },
      show_parcel_pod: function show_parcel_pod(consignment) {
        var _this7 = this;

        this.set("consignmentPODDetails", null);
        this.get("loadingSpinner").show();
        if (consignment.intCurrentStatus === 2) {
          this.get('ajax').request('v2/admin/consignments/' + consignment.consignmentId + '/pod').then(function (results) {
            if (results.resentSMS && results.resentSMS[0]) {
              results.latestResendNumber = results.resentSMS[0].to;
            }
            results.pickupReason = results.pickupReceipt.reason;
            results.pickupReceiver = results.pickupReceipt.name;

            _this7.get("loadingSpinner").hide();
            _this7.set("consignmentPODDetails", results);
          }, function () {
            _this7.get("loadingSpinner").hide();
          });
        } else {
          var setResponse = {
            pickupReceipt: {
              "receiver": {
                "name": consignment.customerName
              },
              "consignmentNumber": consignment.consignmentNumber,
              "consignmentId": consignment.consignmentId
            }
          };
          this.get("loadingSpinner").hide();
          this.set("consignmentPODDetails", setResponse);
        }

        _ember['default'].$(".ui.modal.consignment-pod").modal('show');
      }
    }
  });
});