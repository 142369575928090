define('admin-portal/pods/courier/new/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'admin-portal/shapes/newcourier'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _adminPortalShapesNewcourier) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service(),
    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller) {
      controller.set('newcourier', null);
      controller.set("webhook_events", null);
      controller.set('isBillingOpen', true);

      controller.set('newcourier', _adminPortalShapesNewcourier['default'].create(_ember['default'].getOwner(controller).ownerInjection()));
      this.get('ajax').request('v2/admin/getWebhookEvents').then(function (response) {
        if (response.results) {
          controller.set("webhook_events", response.results);
        }
      }, console.log);

      this.get('ajax').request('v2/admin/countries').then(function (response) {
        controller.set("countries", response);
      }, console.log);
    }
  });
});