define('admin-portal/pods/manifest/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'admin-portal/shapes/manifest'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _adminPortalShapesManifest) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    config: _ember['default'].inject.service('config'),
    session: _ember['default'].inject.service('session'),
    ajax: _ember['default'].inject.service(),

    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller) {
      controller.set('tags', []);
      controller.set('selectedLocationsArr', []);
      controller.set('searchedLocationsArr', []);

      controller.set("isSavingBool", false);
      controller.set("locationSearch", '');
      controller.set("searchCourier", '');
      controller.set("searchLocationId", '');

      controller.set("addLocationQuery", '');
      controller.set("locationQuery", '');
      controller.set("updateBool", false);
      controller.set("openUpdateFormBool", false);

      controller.set("manifestData", []);

      controller.set('manifest', _adminPortalShapesManifest['default'].create(_ember['default'].getOwner(controller).ownerInjection()));
      controller.set('update_manifest', _adminPortalShapesManifest['default'].create(_ember['default'].getOwner(controller).ownerInjection()));

      controller.send("getPageData", 1);
    }
  });
});