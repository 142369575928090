define('admin-portal/pods/application/route', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    session: _ember['default'].inject.service('session'),
    config: _ember['default'].inject.service('config'),

    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);

      //This is to fix link highligting
      //this.transitionTo("dashboard");
      this.transitionTo("parcel-status");
    }
  });
});