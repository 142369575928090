define('admin-portal/pods/locations/store-types/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    notify: _ember['default'].inject.service('notify'),
    storeTypes: [],
    'new': {
      name: ''
    },

    actions: {
      create: function create() {
        var _this = this;

        if (this.get("new.name").length > 0) {
          (function () {
            var self = _this;
            _this.get('ajax').post('v2/admin/storeTypes', {
              data: JSON.stringify({ name: self.get("new.name") }),
              contentType: "application/json",
              crossDomain: true,
              dataType: 'text'
            }).then(function () /*dl*/{
              self.get("notify").success('Successfully added new group.');
              self.send("reload");
              self.set("new", {
                name: ''
              });
            }, function () /*error*/{
              self.get("notify").error('Error adding new group.');
            });
          })();
        } else {
          this.get("notify").error('Please enter group name.');
        }
      },

      reload: function reload() {
        var self = this;
        this.get('ajax').request('v2/admin/storeTypes').then(function (results) {
          self.set('storeTypes', results);
        });
      }
    }

  });
});