define('admin-portal/shapes/promo-code', ['exports', 'ember', 'ember-cp-validations', 'moment'], function (exports, _ember, _emberCpValidations, _moment) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    code: [(0, _emberCpValidations.validator)('presence', true)],
    promoStart: [(0, _emberCpValidations.validator)('presence', true)],
    fractionalDiscount: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: _ember['default'].computed("model.type", function () {
        return this.get("model.type") !== "Percentage";
      }).volatile()
    })],
    flatDiscount: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: _ember['default'].computed("model.type", function () {
        return this.get("model.type") !== "Fixed";
      }).volatile()
    })],
    userLimit: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: _ember['default'].computed("model.userLimitUnlimited", function () {
        return this.get("model.userLimitUnlimited");
      }).volatile()
    })],
    overallLimit: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: _ember['default'].computed("model.overallLimitUnlimited", function () {
        return this.get("model.overallLimitUnlimited");
      }).volatile()
    })]
  });

  exports['default'] = _ember['default'].Object.extend(Validations, {
    code: null,
    description: null,
    type: "Percentage",
    flatDiscount: null,
    fractionalDiscount: null,
    userLimit: null,
    userLimitUnlimited: true,
    overallLimit: null,
    overallLimitUnlimited: true,
    promoStart: (0, _moment['default'])().toDate(),
    promoEnd: (0, _moment['default'])().add(1, 'week').toDate(),
    promoNeverEnds: false,

    toJSON: function toJSON() {
      return {
        code: this.code,
        description: this.description,
        flatDiscount: this.type === "Fixed" ? parseInt(this.flatDiscount * 100) : null,
        fractionalDiscount: this.type === "Percentage" ? parseFloat(this.fractionalDiscount / 100) : null,
        userLimit: this.userLimitUnlimited ? null : parseInt(this.userLimit),
        overallLimit: this.overallLimitUnlimited ? null : parseInt(this.overallLimit),
        userCategory: null,
        promoStart: (0, _moment['default'])(this.promoStart).toISOString(),
        promoEnd: !this.promoNeverEnds ? (0, _moment['default'])(this.promoEnd).toISOString() : null,
        serviceType: this.serviceType ? this.serviceType.id : null
      };
    }
  });
});