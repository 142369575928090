define('admin-portal/pods/ecommerce/edit/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'admin-portal/shapes/ecommerce-courier'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _adminPortalShapesEcommerceCourier) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    config: _ember['default'].inject.service('config'),
    notify: _ember['default'].inject.service('notify'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),

    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      controller.get("loadingSpinner").show();

      controller.set("customizedChannel", 0);
      controller.set("showCustomizedNetwork", false);
      controller.set("isSavingBool", false);
      controller.set("isClickBillingCheckbox", false);
      controller.set("productMappingList", null);
      controller.set("webhooksMappingList", null);
      controller.set("billableServiceMapping", null);
      controller.set("reseetPasswordBool", false);

      controller.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/edit/' + model.id, {
        headers: {
          'Accept': 'application/json',
          'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
        }
      }).then(function (response) {
        if (response && response.result) {
          controller.set("tempPassword", 'Hubbed!1@990');
          var results = response.result;
          var billableServicesArr = [];
          results.billableServiceMapping.map(function (a) {
            billableServicesArr.push(a.billableservice_id);
          });
          results['customername'] = results.company_name;
          results['timezone'] = results.timeZoneId;

          results['contactname'] = results.contact_name;
          results['email'] = results.contact_email;
          results['phone'] = results.contact_phone;
          results['position'] = results.contact_position;

          results['address1'] = results.contact_address;
          results['address2'] = results.contact_address2;
          results['suburb'] = results.contact_suburb;
          results['postcode'] = results.contact_postcode;

          results['state'] = results.contact_state;
          results['country'] = results.contact_country;
          results['notificationURL'] = results.notification_url;
          results['notificationUserName'] = results.notification_username;
          var carrier_partner_str = 'none';
          if (results.carrier_partner && results.carrier_partner !== "") {
            carrier_partner_str = results.carrier_partner;
          }
          results['carrier_partner'] = carrier_partner_str;

          if (results.notification_username && results.notification_url && results.notification_username !== "" && results.notification_url !== "") {
            results['notificationPassword'] = controller.get('tempPassword');
          } else {
            results['notificationPassword'] = '';
          }

          if (results.contact_name === results.billing_contactname && results.contact_position === results.billing_position && results.contact_email === results.billing_email && results.contact_phone === results.billing_phone && results.contact_address === results.billing_address && results.contact_address2 === results.billing_address2 && results.contact_suburb === results.billing_suburb && results.contact_state === results.billing_state && results.contact_postcode === results.billing_postcode && results.contact_country === results.billing_country) {
            controller.set("isBillingOpen", true);
            controller.set('populateBillingBool', true);
          } else {
            controller.set("isBillingOpen", false);
            controller.set('populateBillingBool', false);
            controller.set("isClickBillingCheckbox", true);
          }
          results['billableservice'] = billableServicesArr;

          controller.set("productMappingList", results.ecommerceProductMapping);
          controller.set("webhooksMappingList", results.webhookEventMapping);
          controller.set("billableServiceMapping", results.billableServiceMapping);
          controller.set("ecommerceChannelMapping", results.ecommerceChannelMapping);
          if (results.ecommerceChannelMapping && results.ecommerceChannelMapping.length > 0) {
            controller.set("customizedChannel", 0);
          }
          if (results.showCustomNetworkRadioButton && results.showCustomNetworkRadioButton === true) {
            controller.set("showCustomizedNetwork", true);
            if (results.isCustomNetwork && results.isCustomNetwork === true) {
              controller.set("customizedChannel", 1);
            }
          }

          controller.set("newcourier", _adminPortalShapesEcommerceCourier['default'].create(_ember['default'].getOwner(controller).ownerInjection(), results));
          controller.get("loadingSpinner").hide();
        } else {
          _this.get('notify').error('Error : Not able to fetch details, please try again.');
          controller.get("loadingSpinner").hide();
        }
      }, console.log);

      // this.get('ajax').request(this.get("config.carrierApiURL")+'ecommerceuser/getmastertableslist', {
      //   headers: {
      //     'Accept': 'application/json',
      //     'Authorization': "Bearer "+this.get('session.data.authenticated.dotnet_jwt')
      //   }
      // }).then((response) => {
      //   if(response && response.result && response.result.billableService){
      //     controller.set("billable_services", response.result.billableService);
      //   }
      // }, console.log);

      // fetch countries
      this.get('ajax').request('v2/admin/countries').then(function (response) {
        controller.set("countries", response);
      }, console.log);
    }
  });
});