define('admin-portal/pods/consumer/consignment/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        ajax: _ember['default'].inject.service(),

        model: function model(params) {
            return this.get('ajax').request('v2/consumerPortal/admin/consignments/' + params.consignment_id);
        },

        setupController: function setupController(controller, model) {
            controller.set("consignment", model);
        }
    });
});