define('admin-portal/pods/components/cancel-shipment/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    session: _ember['default'].inject.service('session'),

    name: 'cancel-shipment',
    classNames: ['cancel-shipment'],

    onHide: function onHide() {
      this.set("cancellationConsignment", null);
    },

    actions: {
      confirm: function confirm() {
        this.get("onDelete")(this.get("consignment_number"));
      },
      cancel: function cancel() {
        this.set("cancellationConsignment", null);
        _ember['default'].$('.ui.modal.cancel-shipment').modal('hide');
      }
    }
  });
});