define('admin-portal/pods/admin-users/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service(),

    model: function model() {
      return this.get('ajax').request('v2/admin/adminUsers');
    },
    setupController: function setupController(controller, model) {
      controller.set('users', model);
      this.get('ajax').request('v2/admin/roles').then(function (Userroles) {
        controller.set('Userroles', Userroles);
        controller.set('id', Userroles.id);
      });
    }

  });
});