define('admin-portal/pods/network-management/route', ['exports', 'ember', 'rsvp', 'ember-simple-auth/mixins/authenticated-route-mixin', 'admin-portal/shapes/network-management'], function (exports, _ember, _rsvp, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _adminPortalShapesNetworkManagement) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        config: _ember['default'].inject.service('config'),
        ajax: _ember['default'].inject.service("ajax"),

        model: function model() {
            return _rsvp['default'].hash({
                countries: this.get('ajax').request('v2/admin/countries')
            });
        },

        setupController: function setupController(controller, model) {
            controller.set("countries", model.countries);

            controller.set("showFilterBool", false);
            controller.set("noAllocationEnabledBool", true);
            controller.set("enableDownloadCSVBool", false);
            controller.set("alreadyAllocationExists", false);
            controller.set("showCarrierFilterBool", false);
            controller.set("showPartnerFilterBool", false);
            controller.set("removeFiltersBool", false);
            controller.set('valueNotAsssignedBool', true);
            controller.set("StoreLocation", '');
            controller.set("StoreSearchId", '');
            controller.set('enabledAllocateLocationsArr', null);
            controller.set('disabledAllocateLocationsArr', null);
            controller.set('assign_carrier', null);
            controller.set('assign_partner', null);
            controller.set('assign_customer', null);

            controller.set('temp_sub_filter_by_partner', null);
            controller.set('temp_sub_filter_by_customer', null);
            controller.set('temp_sub_filter_by_carrier', null);
            // controller.set('temp_sub_filter_by_product', null);

            controller.set('sub_filter_by_partner', null);
            controller.set('sub_filter_by_customer', null);
            controller.set('sub_filter_by_carrier', null);
            // controller.set('sub_filter_by_product', null);

            controller.set("customersArr", null);
            controller.set("allocateCustomersArr", null);
            controller.set('storeDataObj', _ember['default'].A([]));

            controller.set('network', null);
            controller.set('network', _adminPortalShapesNetworkManagement['default'].create(_ember['default'].getOwner(controller).ownerInjection()));

            this.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/getmastertableslist', {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
                }
            }).then(function (listingData) {
                if (listingData.responseCode === "200" || listingData.responseCode === 200) {
                    var listingDataObj = listingData.result;

                    var productListing = listingDataObj.products;
                    var newProductListing = [];

                    if (productListing.length > 0) {
                        for (var i = 0; i < productListing.length; i++) {
                            if (productListing[i].productname === 'Send') {
                                newProductListing.push({ "id": productListing[i].id, "productname": "Send - Carrier", "status": productListing[i].status });
                                newProductListing.push({ "id": 0, "productname": "Send - Partner / Customer", "status": productListing[i].status });
                            } else {
                                newProductListing.push(productListing[i]);
                            }
                        }
                    }
                    controller.set("productList", newProductListing);
                    controller.set("channel_list", listingDataObj.channels);
                }
            });

            this.get('ajax').request(this.get("config.carrierApiURL") + 'admin/networkManagement/getCouriers', {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
                }
            }).then(function (response) {
                controller.set("couriers", response.couriers);
            }, console.log);

            this.get('ajax').request(this.get("config.carrierApiURL") + 'admin/networkManagement/getPartners', {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
                }
            }).then(function (response) {
                controller.set("partnersArr", response.partners);
            }, console.log);
        }
    });
});