define('admin-portal/pods/components/consignment-checkout-modal/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    config: _ember['default'].inject.service('config'),

    name: 'consignment-checkout',
    classNames: ['consignment-checkout'],
    updateConBool: false,
    updateBarcodeBool: false,
    updateDropLocationBool: false,
    updateCustomerBool: false,
    showErrorBool: false,
    showErrorMessage: '',
    updatedConsignmentDetails: null,
    consignment: null,
    idTypes: ["Driver's Licence", "Passport", "Other"],
    isSavingBool: false,
    constantConsignmentData: null,
    barc: [],

    willRender: function willRender() {
      this.set("constantConsignmentData", this.get('constantData'));

      _ember['default'].$.fn.search.settings.templates["locationResult"] = function (response, fields) {
        var html = '';
        if (response[fields.results] !== undefined) {
          _ember['default'].$.each(response[fields.results], function (index, result) {
            html += '<a class="result">';
            html += '<div class="content">' + result["name"] + '</div>';
            html += '</a>';
          });
          return html;
        }
        return false;
      };
    },
    onHide: function onHide() {
      this.set("constantConsignmentData", null);
      this.set('barc', []);
      this.set('updateConBool', false);
      this.set('isSavingBool', false);
      this.set('updateBarcodeBool', false);
      this.set('updateDropLocationBool', false);
      this.set('updateCustomerBool', false);
      this.set('showErrorBool', false);
      this.set('showErrorMessage', '');
    },
    locationsURL: _ember['default'].computed('session.data.authenticated', 'config.apiURL', {
      get: function get() {
        var jwt = this.get('session.data.authenticated.jwt');
        return this.get('config.apiURL') + '/v2/admin/dropLocations/search?name={query}&token=' + jwt;
      }
    }),
    locationsResponseWrap: function locationsResponseWrap(response) {
      return {
        results: JSON.parse(response)
      };
    },
    actions: {
      dismiss_alert: function dismiss_alert() {
        this.set('showErrorBool', false);
        this.set('showErrorMessage', '');
      },
      cancel: function cancel() {
        this.set("consignment", null);
        this.set("constantConsignmentData", null);
        this.set('updateConBool', false);
        this.set('isSavingBool', false);
        this.set('updateBarcodeBool', false);
        this.set('barc', []);
        this.set('updateDropLocationBool', false);
        this.set('updateCustomerBool', false);
        this.set('showErrorBool', false);
        this.set('showErrorMessage', '');
        _ember['default'].$('body').removeClass('updateCheckoutClass');
        _ember['default'].$('.ui.modal.consignment-checkout').modal('hide');
      },
      updateValue: function updateValue(index) {
        var newValue = event.target.value;
        var tempArr = this.get("consignment.barcodes");
        var newArr = this.get('barc');
        var newArrLength = newArr.length;
        for (var i = 0; i < tempArr.length; i++) {
          if (newArrLength === 0) {
            if (i === index) {
              newArr[i] = newValue;
            } else {
              newArr[i] = tempArr[i];
            }
          } else {
            if (i === index) {
              newArr[i] = newValue;
            }
          }
        }
        this.set('barc', newArr);
      },
      confirm: function confirm() {
        var _this = this;

        this.set('isSavingBool', true);
        this.set('showErrorBool', false);
        this.set('showErrorMessage', '');

        var self = this;
        var validatedFormBool = true;
        var digitError = '';
        var barcodeArr = this.get("barc");

        if (barcodeArr.length === 0) {
          barcodeArr = this.get('consignment.barcodes');
        }
        if (this.get("consignment.reason") === "" || this.get("consignment.reason") === "undefined" || this.get("consignment.reason") === undefined || this.get("consignment.reason") === null) {
          validatedFormBool = false;
        }
        if (this.get("consignment.consignmentId") === "" || this.get("consignment.consignmentId") === "undefined" || this.get("consignment.consignmentId") === undefined || this.get("consignment.consignmentId") === null) {
          validatedFormBool = false;
        }
        if (this.get("consignment.consignmentNumber") === "" || this.get("consignment.consignmentNumber") === "undefined" || this.get("consignment.consignmentNumber") === undefined || this.get("consignment.consignmentNumber") === null) {
          validatedFormBool = false;
        }
        if (this.get("consignment.dropLocationId") === "" || this.get("consignment.dropLocationId") === "undefined" || this.get("consignment.dropLocationId") === undefined || this.get("consignment.dropLocationId") === null) {
          validatedFormBool = false;
        }
        if (this.get("consignment.receiverName") === "" || this.get("consignment.receiverName") === "undefined" || this.get("consignment.receiverName") === undefined || this.get("consignment.receiverName") === null) {
          validatedFormBool = false;
        }

        // if(this.get("consignment.idLast4")!=="" && this.get("consignment.idLast4")!==undefined && this.get("consignment.idLast4")!==null && this.get("consignment.idLast4")!=="undefined" && ((this.get("consignment.idLast4").length)!==4 || (Number.isInteger(parseInt(this.get("consignment.idLast4")))===false))) {
        //   validatedFormBool = false;
        //   digitError = 'Please enter integer value for 4 digits of Identification.';
        // }

        if (barcodeArr.length <= 0) {
          validatedFormBool = false;
        }
        if (validatedFormBool) {
          var constantBarcodeArr = this.get("constantConsignmentData.barcodeArr");

          var newBarcodeArr = [];
          if (barcodeArr.length > 0) {
            for (var k = 0; k < barcodeArr.length; k++) {
              var tempObj = {
                "barcodeNew": barcodeArr[k],
                "barcodeOld": constantBarcodeArr[k]
              };
              newBarcodeArr.push(tempObj);
            }
          }

          var requestCheckoutData = {
            adminUserId: this.get('admin_user'),
            consignmentId: this.get("consignment.consignmentId"),
            consignmentNumberOld: this.get("constantConsignmentData.consignmentNo"),
            consignmentNumberNew: this.get("consignment.consignmentNumber"),
            barcodeList: newBarcodeArr,
            dropLocationIdOld: this.get("constantConsignmentData.dropLocation"),
            dropLocationIdNew: this.get("consignment.dropLocationId"),
            receiverNameOld: this.get("constantConsignmentData.receiver"),
            receiverNameNew: this.get("consignment.receiverName"),
            identityType: this.get("consignment.idType"),
            identityTypeLast4: this.get("consignment.idLast4"),
            reasonForStatusChange: this.get("consignment.reason"),
            dontSendPodToCarrier: this.get("consignment.dontSendPodToCarrier"),
            markAsNonCompliant: this.get("consignment.markAsNonCompliant"),
            isMarkOff: this.get("constantConsignmentData.ismarkOff")

          };
          var apiURLStr = this.get("config.carrierApiURL") + 'consignment/Checkout';
          this.get('ajax').post(apiURLStr, {
            headers: {
              'Accept': 'application/json',
              'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
            },
            data: JSON.stringify(requestCheckoutData),
            contentType: 'application/json; charset=utf-8',
            crossDomain: true
          }).then(function (responseData) {
            if (responseData.responseCode === 200 || responseData.responseCode === "200") {
              _this.set("consignment", null);
              _this.set("constantConsignmentData", null);
              _this.set('updateConBool', false);
              _this.set('barc', []);
              _this.set('updateBarcodeBool', false);
              _this.set('updateDropLocationBool', false);
              _this.set('updateCustomerBool', false);
              _this.set('showErrorBool', false);
              _this.set('showErrorMessage', '');
              _this.get("onConfirm")(requestCheckoutData);
              _this.set('isSavingBool', false);
            } else if (responseData.responseMessage !== "") {
              _this.set('isSavingBool', false);
              self.set('showErrorBool', true);
              self.set('showErrorMessage', responseData.responseMessage);
            }
          }, function (error) {
            _this.set('isSavingBool', false);
            self.set('showErrorBool', true);
            if (error.errors && error.errors.length > 0) {
              self.set('showErrorMessage', error.errors[0].detail.responseMessage);
            } else {
              self.set('showErrorMessage', 'Oops! Error');
            }
          });
        } else {
          this.set('isSavingBool', false);
          self.set('showErrorBool', true);
          if (digitError !== "") {
            self.set('showErrorMessage', digitError);
          } else {
            self.set('showErrorMessage', 'Please enter all the required fields!');
          }
        }
      },
      selectLocation: function selectLocation(value) {
        this.set("consignment.dropLocationName", value.name);
        this.set("consignment.dropLocationId", value.id);
      },
      updateConsignmentNumber: function updateConsignmentNumber() {
        this.set('updateConBool', true);
      },
      cancelConsignmentNumber: function cancelConsignmentNumber(data) {
        this.set('updateConBool', false);
        this.set('consignment.consignmentNumber', data);
      },
      updateBarcode: function updateBarcode() {
        this.set('updateBarcodeBool', true);
      },
      cancelArticleBarcode: function cancelArticleBarcode(data) {
        this.set('updateBarcodeBool', false);
        this.set('consignment.barcodes', data);
        this.set('barc', []);
      },
      updateDropLocation: function updateDropLocation() {
        this.set('updateDropLocationBool', true);
      },
      cancelDropLocation: function cancelDropLocation(name, id) {
        this.set('updateDropLocationBool', false);
        this.set("consignment.dropLocationName", name);
        this.set("consignment.dropLocationId", id);
      },
      cancelReceiver: function cancelReceiver(data) {
        this.set('updateCustomerBool', false);
        this.set('consignment.receiverName', data);
      },
      updateCustomerName: function updateCustomerName() {
        this.set('updateCustomerBool', true);
      }
    }
  });
});