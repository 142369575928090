define('admin-portal/pods/login/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),

    email: "",
    password: "",

    actions: {
      login: function login() {
        var self = this;

        var _getProperties = this.getProperties('email', 'password');

        var email = _getProperties.email;
        var password = _getProperties.password;

        this.get('session').authenticate('authenticator:jwt', email, password)['catch'](function (reason) {
          self.set('errorMessage', reason);
        });
      }
    }
  });
});