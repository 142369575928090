define('admin-portal/pods/dashboard/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    //ajax: Ember.inject.service(),

    // to redirect the dashboard to parcel status page permanently
    beforeModel: function beforeModel() {
      this.transitionTo('parcel-status');
    }

  });
});
/*setupController(controller) {
  this.get('ajax').request('v2/admin/dropLocations').then((locations) => {
    var markers = locations.filter(function (f) {return f.enabled; }).map(function(l) {
      return  {title: l.name, lat: l.latitude, lng: l.longitude, location: l};
    });
      var nswMarkers = markers.filter(function(m) {return m.location.address.state === "NSW";});
    var saMarkers = markers.filter(function(m) {return m.location.address.state === "SA";});
    var actMarkers = markers.filter(function(m) {return m.location.address.state === "ACT";});
    var waMarkers = markers.filter(function(m) {return m.location.address.state === "WA";});
    var ntMarkers = markers.filter(function(m) {return m.location.address.state === "NT";});
    var tasMarkers = markers.filter(function(m) {return m.location.address.state === "TAS";});
    var vicMarkers = markers.filter(function(m) {return m.location.address.state === "VIC";});
    var qldMarkers = markers.filter(function(m) {return m.location.address.state === "QLD";});
      controller.set('nswMarkers', Ember.A(nswMarkers));
    controller.set('saMarkers', Ember.A(saMarkers));
    controller.set('actMarkers', Ember.A(actMarkers));
    controller.set('waMarkers', Ember.A(waMarkers));
    controller.set('ntMarkers', Ember.A(ntMarkers));
    controller.set('tasMarkers', Ember.A(tasMarkers));
    controller.set('qldMarkers', Ember.A(qldMarkers));
    controller.set('vicMarkers', Ember.A(vicMarkers));
  });
    this.get('ajax').request('v2/admin/dashboard').then((dashboard) => {
    controller.set('dashboard.all', dashboard);
  });
}*/