define('admin-portal/helpers/in-array', ['exports', 'ember'], function (exports, _ember) {
  exports.inArray = inArray;

  function inArray(params) {
    var value = params[1];
    var array = params[0];
    if (array && array.length > 0) {
      return array.indexOf(value) !== -1;
    } else {
      return false;
    }
  }

  exports['default'] = _ember['default'].Helper.helper(inArray);
});