define('admin-portal/pods/tracking/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    config: _ember['default'].inject.service(),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),
    loading: false,
    loadingSpinner: _ember['default'].inject.service('loading'),
    consignment: '',
    barcode: '',
    missed: '',
    customername: '',
    customeremail: '',
    merchant: '',
    page: 1,
    totalPages: 1,
    foundConsignments: _ember['default'].A([]),
    searchText: '',
    searchBy: '',

    buildUrl: function buildUrl() {
      var url = this.get("config.carrierApiURL") + 'TrackAndTrace/GetConsignmentsDetail';
      return url;
    },
    actions: {

      updateValue: function updateValue(newValue) {
        this.set('searchBy', newValue);
        //console.log(this.get("searchBy"));
      },

      toggleInfo: function toggleInfo(data) {
        if (data.toogleDetails) {
          _ember['default'].set(data, "toogleDetails", !data.toogleDetails);
        } else {
          _ember['default'].set(data, "toogleDetails", true);
        }
      },
      getPage: function getPage(page) {
        var self = this;
        var searchSuccessfully = true,
            errorMsgStr = '';

        // console.log(this.get("consignment"));
        // console.log(this.get("barcode"));
        // console.log(this.get('missed'));
        // console.log(this.get('customername'));
        // console.log(this.get('customeremail'));
        // console.log(this.get('merchant'));

        var con = '';
        var barcode = '';
        var mdc = '';
        var cname = '';
        var cemail = '';
        var merchant = '';

        if (this.get("searchBy") !== '') {
          // check length
          var searchValArr = this.get('searchText').split(",");
          if (searchValArr.length >= 6) {
            searchSuccessfully = false;
            errorMsgStr = 'You can only search upto 5 values at a time';
          }
          if (this.get("searchBy") === "consignment") {
            con = this.get('searchText');
          } else if (this.get("searchBy") === "barcode") {
            barcode = this.get('searchText');
          } else if (this.get("searchBy") === "missed") {
            mdc = this.get('searchText');
          } else if (this.get("searchBy") === "customername") {
            cname = this.get('searchText');
          } else if (this.get("searchBy") === "customeremail") {
            cemail = this.get('searchText');
          } else if (this.get("searchBy") === "merchant") {
            merchant = this.get('searchText');
          }
        } else {
          searchSuccessfully = false;
          errorMsgStr = 'Please select one search by option and enter value for search';
        }

        /*if(this.get('consignment')!=='' || this.get('barcode')!=='' || this.get('missed')!==''
        || this.get('customername')!==''|| this.get('customeremail')!=='' || this.get('merchant')!==''
        ){
        	if(searchValArr.length>=6) {
        		searchSuccessfully = false;
        		errorMsgStr = 'You can only search upto 5 values at a time';
        	}
        } else{
        	searchSuccessfully = false;
        	errorMsgStr = 'Please enter consignment number or article barcode or missed delivery cards or consumers name or consumers email or customer name for search';
        }
         */
        /*if(this.get('consignmentNumbers')!=='' || this.get('barcodes')!=='' || this.get('missedDeliveryNumber')!==''
           || this.get('receiverNames')!==''|| this.get('receiverEmails')!=='' || this.get('retailerNames')!==''
           ){
             var barcodeArr = this.get('barcodes').split(","); alert(barcodeArr);
             var consignmentNumberArr = this.get('consignmentNumbers').split(",");
             var missedDeliveryNumberArr = this.get('missedDeliveryNumber').split(",");
             var consumerNameArr = this.get('receiverNames').split(",");
             var consumersEmailArr = this.get('receiverEmails').split(",");
             var customerRetailerArr = this.get('retailerNames').split(",");
             if(consignmentNumberArr.length>=6 || barcodeArr.length>=6 || missedDeliveryNumberArr.length>=6 || consumerNameArr.length>=6 || consumersEmailArr.length>=6 || customerRetailerArr.length>=6) {
               searchSuccessfully = false;
               errorMsgStr = 'You can only search upto 5 consignment numbers or article barcodes or missed delivery cards or consumers name or consumers email address or customer name.';
             }
           } else{
             searchSuccessfully = false;
             errorMsgStr = 'Please enter consignment number or article barcode or missed delivery cards or consumers name or consumers email or customer name for search';
           }*/

        if (searchSuccessfully) {
          self.set("loading", true);
          this.get("loadingSpinner").show();

          this.get('ajax').post(this.buildUrl(page), {
            headers: {
              'Accept': 'application/json',
              'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
            },
            data: JSON.stringify({
              pageNo: page,
              perPageRecords: 20,
              consignmentNumbers: con,
              barcodes: barcode,
              missedDeliveryNumber: mdc,
              receiverNames: cname,
              receiverEmails: cemail,
              retailerNames: merchant
            }),
            contentType: 'application/json; charset=utf-8',
            processData: false,
            crossDomain: true
          }).then(function (result) {
            self.set('page', result.pageNo);
            self.set('totalPages', result.totalPages);
            self.set('foundConsignments', _ember['default'].A(result.data));
            self.set("loading", false);
            self.get("loadingSpinner").hide();
          }, function (error) {
            console.log(error);
          });
        } else {
          this.get('notify').error(errorMsgStr);
        }
      },
      applyFilter: function applyFilter() {
        this.send("getPage", 1);
      }
    }
  });
});