define('admin-portal/pods/seven-eleven/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),

    results: null,
    q: "",
    queryParams: ['q'],

    pin: null,
    voucher: null,

    actions: {
      search: function search() {
        var self = this;
        this.get('ajax').request('v2/admin/consignments/search?q=' + this.get("q")).then(function (results) {
          self.set('results', results);
        }, function (error) {
          console.log(error);
        });
      },

      retreivePin: function retreivePin() {
        var self = this;
        var consignment = this.get("current");

        this.get('ajax').post('v2/admin/7-eleven/consignments/' + consignment.id + '/pin', {
          data: JSON.stringify({
            mobile: this.get("mobile"),
            documentNo: this.get("document")
          })
        }).then(function (result) {
          if (result.lockerPin) {
            self.set('pin', result.lockerPin);
          } else {
            self.set('pin', result.code);
          }
          self.set("document", null);
          self.set("mobile", null);
        }, function (error) {
          console.log(error);
        });
      },

      showPinVoucherForm: function showPinVoucherForm(consignment) {
        this.set("pin", null);
        this.set("voucher", null);
        this.set("current", consignment);
        _ember['default'].$(".ui.modal.kiosk").modal('show');
      },

      retrieveVoucher: function retrieveVoucher() {
        var self = this;
        var consignment = this.get("current");

        this.get('ajax').post('v2/admin/7-eleven/consignments/' + consignment.id + '/voucher', {
          data: JSON.stringify({
            mobile: this.get("mobile"),
            documentNo: this.get("document")
          })
        }).then(function (result) {
          self.set('voucher', result.code);
          self.set("document", null);
          self.set("mobile", null);
        }, function (error) {
          console.log(error);
        });
      }

    }
  });
});