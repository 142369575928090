define('admin-portal/pods/ecommerce/cedit/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),
    loading: _ember['default'].inject.service('loading'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    selectedEcomCourier: null,
    billable_services: ["Software", "Transactions", "Support", "Development", "Other"],
    return_types: ["RMA", "Pre-Auth RMA", "No RMA"],
    return_carrier_partners: [{ name: "Toll", code: "toll" }],
    integration_methods: ["Standard API", "Custom API", "Shopify", "WooCommerce", "Magento", "Other"],
    product_payment_methods: ["Customer Pays", "Consumer Pays", "Combination"],
    skin_background_options: ["Image", "Color"],
    skinBgImageBool: true,
    skinBgColorBool: false,
    isBillingOpen: null,
    isGeneratingBool: false,
    populateBillingBool: false,
    showPageSetupUI: false,
    clickCollectPageSetupUI: false,
    returnPageSetupUI: false,
    notValidReturnLocation: false,
    returnLocations: [],
    returnPortalTemplateArr: config.returnPortalTemplate,
    timezone_list: config.timezone_list,
    locationData: null,

    actions: {
      selected_template: function selected_template(val) {
        this.set("developerAccount.template", val);
        if (val === "default") {
          this.set("defaultTemplateBool", true);
        } else {
          this.set("defaultTemplateBool", false);
        }
      },
      setCountry: function setCountry(country_name) {
        this.set('developerAccount.contact_country', country_name);
      },
      setCounsolidatedAddress: function setCounsolidatedAddress(consolidated_addressId) {
        this.set('consolidatedLocationAddresssId', consolidated_addressId);
      },
      backTo: function backTo() {
        this.transitionToRoute('ecommerce.details', this.get("selectedEcomCourier"));
      },
      skinBackgroundOption: function skinBackgroundOption(data) {
        this.set("developerAccount.ecommerceAccountSettings.skinbackground", "");
        if (data === "Color") {
          this.set("skinBgColorBool", true);
          this.set("skinBgImageBool", false);
        } else {
          this.set("skinBgColorBool", false);
          this.set("skinBgImageBool", true);
        }
      },
      update: function update() {
        var _this = this;

        this.set('notValidReturnLocation', false);
        var self = this;
        var webhook_events = [];
        var consoliAddressID = self.consolidatedAddress;
        if (self.get("webhookEventList")) {
          webhook_events = self.get("webhookEventList").map(function (a) {
            return a;
          });
        }

        var products_list = self.get("productMappingList").map(function (p) {
          return p;
        });
        var returnLocationsData = this.get('returnLocations');
        if (this.get('returnPageSetupUI') !== true) {
          returnLocationsData = [];
        }

        var channelListArr = self.get("channelMappingList").map(function (p) {
          return p;
        });

        var getinvalue = this.get('developerAccount.ecommerceAccountSettings.title');
        if (this.get("developerAccount.ecommerceAccountSettings.title") !== null && this.get("developerAccount.ecommerceAccountSettings.title") !== undefined && this.get("developerAccount.title") !== "") {
          getinvalue = getinvalue.replace(/(<([^>]+)>)/gi, "");
          this.set('developerAccount.ecommerceAccountSettings.title', getinvalue);
        }

        var getabout = this.get('developerAccount.about_us');
        if (this.get("developerAccount.about_us") !== null && this.get("developerAccount.about_us") !== undefined && this.get("developerAccount.about_us") !== "") {
          getabout = getabout.replace(/(<([^>]+)>)/gi, "");
          this.set('developerAccount.about_us', getabout);
        }

        var passwordStr = '';
        if (this.get('reseetPasswordBool') === true) {
          passwordStr = this.get('developerAccount.notification_password');
        }
        var saveAccountBool = false,
            rateValidationBool = false;
        if (returnLocationsData.length > 0 && this.get('returnPageSetupUI')) {
          saveAccountBool = true;
        } else if (this.get('returnPageSetupUI') !== true) {
          saveAccountBool = true;
        }
        if (this.get("developerAccount.sale_rate") < 0) {
          saveAccountBool = false;
          rateValidationBool = true;
        }
        if (this.get("developerAccount.buy_rate") < 0) {
          saveAccountBool = false;
          rateValidationBool = true;
        }
        if (this.get("developerAccount.additional_charge") < 0) {
          saveAccountBool = false;
          rateValidationBool = true;
        }
        var sale_rate_val = 0,
            buy_rate_val = 0,
            additional_charge_val = 0;
        if (this.get("developerAccount.sale_rate") !== null && this.get("developerAccount.sale_rate") !== undefined && this.get("developerAccount.sale_rate") !== "") {
          sale_rate_val = parseFloat(this.get("developerAccount.sale_rate"));
        }
        if (this.get("developerAccount.buy_rate") !== null && this.get("developerAccount.buy_rate") !== undefined && this.get("developerAccount.buy_rate") !== "") {
          buy_rate_val = parseFloat(this.get("developerAccount.buy_rate"));
        }
        if (this.get("developerAccount.additional_charge") !== "" && this.get("developerAccount.additional_charge") !== undefined && this.get("developerAccount.additional_charge") !== null) {
          additional_charge_val = parseFloat(this.get("developerAccount.additional_charge"));
        }
        var ecommerceAccountSettings = this.get("developerAccount.ecommerceAccountSettings");
        ecommerceAccountSettings['website'] = this.get("developerAccount.website");
        ecommerceAccountSettings['logo'] = this.get("developerAccount.logo");
        ecommerceAccountSettings['termsofserviceurl'] = this.get("developerAccount.terms_of_service");
        ecommerceAccountSettings['returnpolicy'] = this.get("developerAccount.returnpolicy");
        ecommerceAccountSettings['parcelinstructionsurl'] = this.get("developerAccount.parcel_info");
        ecommerceAccountSettings['aboutus'] = this.get("developerAccount.about_us");

        if (saveAccountBool) {
          this.set("isSavingBool", true);
          this.get('ajax').post(this.get("config.carrierApiURL") + 'ecommerceuser/update', {
            headers: {
              'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
            },
            data: JSON.stringify({
              role_id: this.get('developerAccount.role_id'),
              hubbed_user_id: this.get('developerAccount.hubbed_user_id'),
              is_deleted: this.get('developerAccount.is_deleted'),
              isLinkedCourier: this.get('developerAccount.isLinkedCourier'),
              company_name: this.get('developerAccount.company_name'),
              timeZoneId: this.get('developerAccount.timezoneId'),
              notification_username: this.get('developerAccount.notification_username'),
              notification_password: passwordStr,
              contact_name: this.get("developerAccount.contact_name"),
              template: this.get("developerAccount.template"),
              contact_position: this.get("developerAccount.contact_position"),
              contact_phone: this.get("developerAccount.contact_phone"),
              contact_email: this.get("developerAccount.contact_email"),
              active: this.get("developerAccount.active") && this.get("developerAccount.active") !== "" && this.get("developerAccount.active") !== undefined ? this.get("developerAccount.active") : false,
              send_outbound_notification: this.get("developerAccount.send_outbound_notification") && this.get("developerAccount.send_outbound_notification") !== "" && this.get("developerAccount.send_outbound_notification") !== undefined ? this.get("developerAccount.send_outbound_notification") : false,
              payment_rate_type: this.get("developerAccount.enable_payment") && this.get("developerAccount.enable_payment") !== "" && this.get("developerAccount.enable_payment") !== undefined ? 1 : 0,
              carrier_partner: this.get("developerAccount.carrier_partner") && this.get("developerAccount.carrier_partner") !== "" && this.get("developerAccount.carrier_partner") !== undefined ? this.get("developerAccount.carrier_partner") : "",
              contact_address: this.get('developerAccount.contact_address'),
              contact_address2: this.get('developerAccount.contact_address2'),
              contact_suburb: this.get('developerAccount.contact_suburb'),
              contact_state: this.get("developerAccount.contact_state"),
              contact_postcode: this.get('developerAccount.contact_postcode'),
              contact_country: this.get("developerAccount.contact_country"),
              billing_contactname: this.get("developerAccount.billing_contactname"),
              billing_position: this.get("developerAccount.billing_position"),
              billing_phone: this.get("developerAccount.billing_phone"),
              billing_email: this.get("developerAccount.billing_email"),
              billing_address: this.get('developerAccount.billing_address'),
              billing_address2: this.get('developerAccount.billing_address2'),
              billing_suburb: this.get('developerAccount.billing_suburb'),
              billing_state: this.get("developerAccount.billing_state"),
              billing_postcode: this.get('developerAccount.billing_postcode'),
              billing_country: this.get("developerAccount.billing_country"),
              notification_url: this.get("developerAccount.notification_url"),
              isCustomNetwork: this.get("developerAccount.customNetwork") && this.get("developerAccount.customNetwork") === 1 ? true : false,
              send_notification: true,
              duplicate_allowed_days: 0,
              ecommerceProductMapping: products_list,
              WebhookEventMapping: webhook_events,
              ecommerceAccountSettings: ecommerceAccountSettings,
              ecommerceLocationMapping: returnLocationsData,
              consoliAddressID: this.get('consolidatedLocationAddresssId'),

              integration_method: this.get("developerAccount.integration_method"),
              product_payment_method: this.get("developerAccount.product_payment_method"),
              fixedRates: {
                id: this.get("developerAccount.fixedRates.id"),
                hubbed_user_id: this.get("developerAccount.hubbed_user_id"),
                sale_rate: sale_rate_val,
                buy_rate: buy_rate_val,
                additional_charge: additional_charge_val
              },
              ecommerceChannelMapping: channelListArr
            }),
            contentType: 'application/json; charset=utf-8',
            crossDomain: true
          }).then(function (data) {
            _this.set("isSavingBool", false);
            if (data.responseCode === "200" || data.responseCode === 200) {
              _this.get('notify').success('eCommerce customer has been successfully Updated.');

              _this.transitionToRoute('ecommerce.details', _this.get("selectedEcomCourier"));
            } else {
              _this.get('notify').error(data.responseMessage);
            }
          })['catch'](function (error) {
            _this.set("isSavingBool", false);
            if (error && error.errors) {
              var errorMsg = error.errors.map(function (e) {
                return e.detail;
              });

              if (errorMsg[0] && errorMsg[0].message) {
                _this.get('notify').error(errorMsg[0].message);
              } else if (errorMsg[0] && errorMsg[0].result && errorMsg[0].result.length > 0) {
                _this.get('notify').error(errorMsg[0].result[0].description);
              } else {
                _this.get('notify').error('Error updating details.');
              }
            } else {

              //this.transitionToRoute('ecommerce.details', this.get("selectedEcomCourier"));
              _this.transitionToRoute('ecommerce.list');
            }
          });
        } else {
          this.set("isSavingBool", false);
          if (rateValidationBool) {
            this.get('notify').error("Sale rate, buy rate and additional charges can't be negative");
          } else {
            this.get('notify').error('Please add Return Locations');
          }
        }
      },
      populateBillingAddress: function populateBillingAddress(developerAccount) {
        if (this.get('populateBillingBool')) {
          this.set('developerAccount.billing_contactname', developerAccount.contact_name);
          this.set('developerAccount.billing_email', developerAccount.contact_email);
          this.set('developerAccount.billing_phone', developerAccount.contact_phone);
          this.set('developerAccount.billing_position', developerAccount.contact_position);
          this.set('developerAccount.billing_address', developerAccount.contact_address);
          this.set('developerAccount.billing_address2', developerAccount.contact_address2);
          this.set('developerAccount.billing_suburb', developerAccount.contact_suburb);
          this.set('developerAccount.billing_state', developerAccount.contact_state);
          this.set('developerAccount.billing_postcode', developerAccount.contact_postcode);
          this.set('developerAccount.billing_country', developerAccount.contact_country);
        }
      },
      billingOpen: function billingOpen(developerAccount) {
        if (this.get('isClickBillingCheckbox')) {
          if (this.get('isBillingOpen')) {
            this.set('populateBillingBool', false);
            this.set('developerAccount.billing_contactname', null);
            this.set('developerAccount.billing_email', null);
            this.set('developerAccount.billing_phone', null);
            this.set('developerAccount.billing_position', null);
            this.set('developerAccount.billing_address', null);
            this.set('developerAccount.billing_address2', null);
            this.set('developerAccount.billing_suburb', null);
            this.set('developerAccount.billing_state', null);
            this.set('developerAccount.billing_postcode', null);
            this.set('developerAccount.billing_country', null);
            this.set('isBillingOpen', false);
          } else {
            this.set('populateBillingBool', true);
            this.set('developerAccount.billing_contactname', developerAccount.contact_name);
            this.set('developerAccount.billing_email', developerAccount.contact_email);
            this.set('developerAccount.billing_phone', developerAccount.contact_phone);
            this.set('developerAccount.billing_position', developerAccount.contact_position);
            this.set('developerAccount.billing_address', developerAccount.contact_address);
            this.set('developerAccount.billing_address2', developerAccount.contact_address2);
            this.set('developerAccount.billing_suburb', developerAccount.contact_suburb);
            this.set('developerAccount.billing_state', developerAccount.contact_state);
            this.set('developerAccount.billing_postcode', developerAccount.contact_postcode);
            this.set('developerAccount.billing_country', developerAccount.contact_country);
            this.set('isBillingOpen', true);
          }
        }
        this.set("isClickBillingCheckbox", true);
      },
      colorChanged: function colorChanged(e) {
        this.set("developerAccount." + e, this.get(e));
      },
      removeLocation: function removeLocation(returnLocation) {
        var _this2 = this;

        if (returnLocation.id === 0) {
          this.get('returnLocations').removeObject(returnLocation);
          this.set("eRemoveLocationDetails", null);
          _ember['default'].$('.ui.modal.ecommerce-remove-location').modal('hide');
        } else {
          this.get('ajax').post(this.get("config.carrierApiURL") + ('returnlocations/delete/' + returnLocation.id), {
            headers: {
              'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
            }
          }).then(function (responseData) {
            if (responseData.responseCode === "200" || responseData.responseCode === 200) {
              _this2.get('returnLocations').removeObject(returnLocation);
              _this2.set("eRemoveLocationDetails", null);
              _ember['default'].$('.ui.modal.ecommerce-remove-location').modal('hide');
            } else {
              _this2.set("eRemoveLocationDetails", null);
              _ember['default'].$('.ui.modal.ecommerce-remove-location').modal('hide');
              _this2.get('notify').error(responseData.responseMessage);
            }
          })['catch'](function (error) {
            _this2.get('notify').error("Error, in removing location");
          });
        }
      },
      cancelLocation: function cancelLocation() {
        this.get('returnLocations').addObject(this.get('hiddenReturnLocationData'));
        this.set("updateLocationBool", false);
        this.set('notValidReturnLocation', false);
        this.set("hiddenReturnLocationData", null);
        this.set('developerAccount.location_name', null);
        this.set('developerAccount.location_id', null);
        this.set('developerAccount.location_phone', null);
        this.set('developerAccount.location_reference', null);
        this.set('developerAccount.location_address', null);
        this.set('developerAccount.location_suburb', null);
        this.set('developerAccount.location_state', null);
        this.set('developerAccount.location_postcode', null);
        this.set('developerAccount.location_country', null);
      },
      updateLocation: function updateLocation(returnLocation) {
        this.set("updateLocationBool", true);
        this.set('notValidReturnLocation', false);
        this.set("hiddenReturnLocationData", returnLocation);
        this.set('developerAccount.location_name', returnLocation.name);
        this.set('developerAccount.location_id', returnLocation.id);
        this.set('developerAccount.location_phone', returnLocation.phone);
        this.set('developerAccount.location_reference', returnLocation.reference);
        this.set('developerAccount.location_address', returnLocation.address);
        this.set('developerAccount.location_suburb', returnLocation.suburb);
        this.set('developerAccount.location_state', returnLocation.state);
        this.set('developerAccount.location_postcode', returnLocation.postcode);
        this.set('developerAccount.location_country', returnLocation.country);
        this.get('returnLocations').removeObject(returnLocation);
      },
      addLocation: function addLocation(developerAccount) {
        this.set('returnLocationErrorStr', "Please add all the required information.");
        this.set('notValidReturnLocation', false);
        var location_reference = developerAccount.location_reference;

        if (developerAccount.location_name !== null && developerAccount.location_phone !== null && developerAccount.location_address !== null && developerAccount.location_suburb !== null && developerAccount.location_state !== null && developerAccount.location_postcode !== null && developerAccount.location_country !== null && developerAccount.location_name !== undefined && developerAccount.location_phone !== undefined && developerAccount.location_address !== undefined && developerAccount.location_suburb !== undefined && developerAccount.location_state !== undefined && developerAccount.location_postcode !== undefined && developerAccount.location_country !== undefined && developerAccount.location_name.trim() !== undefined && developerAccount.location_phone.trim() !== undefined && developerAccount.location_address.trim() !== undefined && developerAccount.location_suburb.trim() !== undefined && developerAccount.location_state.trim() !== undefined && developerAccount.location_postcode.trim() !== undefined && developerAccount.location_country.trim() !== undefined && developerAccount.location_name.trim() !== "" && developerAccount.location_phone.trim() !== "" && developerAccount.location_address.trim() !== "" && developerAccount.location_suburb.trim() !== "" && developerAccount.location_state.trim() !== "" && developerAccount.location_postcode.trim() !== "" && developerAccount.location_country.trim() !== "") {
          //var regName = /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/;
          var regName = /^[a-zA-Z0-9]+ [a-zA-Z0-9]+$/;
          var onlyAlphanumberic = /^[0-9a-zA-Z ]+$/;
          var multipleSpaceRegex = /\s{2,}/g;

          if (multipleSpaceRegex.test(developerAccount.location_name)) {
            this.set('returnLocationErrorStr', "Multiple spaces in name.");
            this.set('notValidReturnLocation', true);
          } else if (!onlyAlphanumberic.test(developerAccount.location_name)) {
            this.set('returnLocationErrorStr', "Only Alphanumeric is allowed for name.");
            this.set('notValidReturnLocation', true);
          } else if (!regName.test(developerAccount.location_name)) {
            this.set('returnLocationErrorStr', "Please enter name as 2 words.");
            this.set('notValidReturnLocation', true);
          } else if (location_reference !== null && location_reference !== undefined && location_reference !== "" && location_reference.length > 31) {
            this.set('returnLocationErrorStr', "Maximum 30 characters are allowed for reference.");
            this.set('notValidReturnLocation', true);
          } else {
            this.set('notValidReturnLocation', false);
            var dataObj = {};
            dataObj['id'] = 0;
            if (developerAccount.location_id && developerAccount.location_id !== null) {
              dataObj['id'] = developerAccount.location_id;
            }
            dataObj['name'] = developerAccount.location_name;
            var fullnameArr = developerAccount.location_name.split(' ');
            var lastNameStr = '';
            for (var i = 1; i < fullnameArr.length; i++) {
              lastNameStr += fullnameArr[i] + " ";
            }
            dataObj['firstname'] = fullnameArr[0];
            dataObj['lastname'] = lastNameStr.trim();
            dataObj['phone'] = developerAccount.location_phone.trim();

            if (location_reference !== null && location_reference !== undefined && location_reference !== "") {
              location_reference = developerAccount.location_reference.trim();
            }
            dataObj['reference'] = location_reference;
            dataObj['address'] = developerAccount.location_address.trim();
            dataObj['suburb'] = developerAccount.location_suburb.trim();
            dataObj['state'] = developerAccount.location_state.trim();
            dataObj['postcode'] = developerAccount.location_postcode.trim();
            dataObj['country'] = developerAccount.location_country.trim();
            this.set('developerAccount.location_id', null);
            this.set('developerAccount.location_name', null);
            this.set('developerAccount.location_phone', null);
            this.set('developerAccount.location_reference', null);
            this.set('developerAccount.location_address', null);
            this.set('developerAccount.location_suburb', null);
            this.set('developerAccount.location_state', null);
            this.set('developerAccount.location_postcode', null);
            this.set('developerAccount.location_country', null);
            this.set('notValidReturnLocation', false);
            this.set("hiddenReturnLocationData", null);
            this.get('returnLocations').addObject(dataObj);
            this.set("updateLocationBool", false);
            //console.log(this.get('returnLocations'))
          }
        } else {
            this.set('notValidReturnLocation', true);
          }
      },
      removeLocationModalOpen: function removeLocationModalOpen(returnLocation) {
        this.set("eRemoveLocationDetails", returnLocation);
        _ember['default'].$('.ui.modal.ecommerce-remove-location').modal('show');
      },
      checkForProducts: function checkForProducts(e) {
        var self = this;
        self.get("productMappingList").map(function (a) {
          if (parseInt(a.product_id) === parseInt(e)) {
            _ember['default'].set(a, 'enabled', !a.enabled);
          }
          if (a.enabled === true && a.product_name === "Click & Collect") {
            self.set('clickCollectPageSetupUI', true);
          } else if (a.enabled === false && a.product_name === "Click & Collect") {
            self.set('clickCollectPageSetupUI', false);
          }
          if (a.enabled === true && a.product_name === "Return") {
            self.set('returnPageSetupUI', true);
          } else if (a.enabled === false && a.product_name === "Return") {
            self.set('returnPageSetupUI', false);
          }
          if (self.get('returnPageSetupUI') === true || self.get('clickCollectPageSetupUI') === true) {
            self.set('showPageSetupUI', true);
          } else {
            self.set('showPageSetupUI', false);
          }
        });
      },
      reset_password: function reset_password() {
        this.set("reseetPasswordBool", !this.get('reseetPasswordBool'));
        if (this.get('reseetPasswordBool') === true) {
          this.set('developerAccount.notification_password', '');
        } else {
          this.set('developerAccount.notification_password', this.get('tempPassword'));
        }
      }
    }
  });
});