define('admin-portal/pods/consignments/alternate-names/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),

    results: null,
    q: "",
    queryParams: ['q'],

    actions: {
      search: function search() {
        var self = this;
        this.get('ajax').request('v2/admin/consignments/search?q=' + this.get("q")).then(function (results) {
          self.set('results', results);
        }, function (error) {
          console.log(error);
        });
      },

      showAlternateName: function showAlternateName(consignment) {
        this.set("currentConsignment", consignment);
        _ember['default'].$(".ui.modal.consignment-alternate-names-modal").modal('show');
      },

      saveName: function saveName(consignment, name) {
        var self = this;
        this.get('ajax').post('v2/admin/consignments/' + consignment.id + '/alternatePickupName', {
          data: JSON.stringify({
            name: name
          }),
          dataType: "text"
        }).then(function () {
          _ember['default'].$(".ui.modal.consignment-alternate-names-modal").modal('hide');
          self.send("search");
        }, function (error) {
          console.log(error);
        });
      }

    }
  });
});