define("admin-portal/services/loading", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Service.extend({
    show: function show() {
      _ember["default"].$("#full-page-loader").addClass("show");
    },

    hide: function hide(wait) {
      var timeout = wait || 300;
      _ember["default"].run.later(function () {
        _ember["default"].$("#full-page-loader").removeClass("show");
      }, timeout);
    }

  });
});