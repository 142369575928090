define('admin-portal/pods/components/courier-view-modal/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    session: _ember['default'].inject.service('session'),

    name: 'courier-detail-view',
    classNames: ['courier-detail-view'],

    onHide: function onHide() {
      this.set("apiKeyValue", null);
      this.set("viewContent", null);
      this.set("isGeneratingBool", false);
      _ember['default'].$('body').removeClass('editCourierClass');
      _ember['default'].$('.ui.modal.courier-detail-view').modal('hide');
    },

    actions: {
      regenerate: function regenerate(code) {
        this.get("onClick")(code);
      },
      cancel: function cancel() {
        this.set("viewContent", null);
        this.set("apiKeyValue", null);
        this.set("isGeneratingBool", false);
        _ember['default'].$('body').removeClass('editCourierClass');
        _ember['default'].$('.ui.modal.courier-detail-view').modal('hide');
      }
    }
  });
});