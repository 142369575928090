define('admin-portal/pods/ecommerce/cedit/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'admin-portal/shapes/developer-account'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _adminPortalShapesDeveloperAccount) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller, model) {
      controller.get("loadingSpinner").show();
      controller.set("showThemeOptionBool", false);
      controller.set("isSavingBool", false);
      controller.set("developerAccount", null);
      controller.set("isClickBillingCheckbox", false);
      controller.set('showPageSetupUI', false);
      controller.set("isBillingOpen", false);
      controller.set('populateBillingBool', false);
      controller.set('clickCollectPageSetupUI', false);
      controller.set('returnPageSetupUI', false);
      controller.set('notValidReturnLocation', false);
      controller.set("updateLocationBool", false);
      controller.set("reseetPasswordBool", false);
      controller.set("products_list_bool", false);
      controller.set("defaultTemplateBool", true);

      controller.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/edit/' + model.id, {
        headers: {
          'Accept': 'application/json',
          'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
        }
      }).then(function (response) {
        if (response && response.result) {
          var results = response.result;
          controller.set('consolidatedLocationAddresss', results.consolidatedLocationAddresssName);
          controller.set('selectedEcomCourier', results.partnerID);
          controller.set("tempPassword", 'Hubbed!1@990');
          controller.set("apiKeyValue", results.apiKey);
          controller.set("productMappingList", results.ecommerceProductMapping);
          controller.set("webhookEventList", results.webhookEventMapping);
          controller.set("channelMappingList", results.ecommerceChannelMapping);
          controller.set('consolidatedAddress', results.consolidatedDetails);

          if (results.contact_name === results.billing_contactname && results.contact_position === results.billing_position && results.contact_email === results.billing_email && results.contact_phone === results.billing_phone && results.contact_address === results.billing_address && results.contact_address2 === results.billing_address2 && results.contact_suburb === results.billing_suburb && results.contact_state === results.billing_state && results.contact_postcode === results.billing_postcode && results.contact_country === results.billing_country) {
            controller.set("isBillingOpen", true);
            controller.set('populateBillingBool', true);
          } else {
            controller.set("isBillingOpen", false);
            controller.set('populateBillingBool', false);
            controller.set("isClickBillingCheckbox", true);
          }

          controller.set('returnLocations', results.ecommerceLocationMapping);
          var selectedSkinBgMethod = 'Image';
          controller.set("skinBgImageBool", true);
          controller.set("skinBgColorBool", false);

          results['sale_rate'] = 0;
          results['buy_rate'] = 0;
          results['additional_charge'] = 0;

          if (results && results.fixedRates && results.fixedRates !== null) {
            results['sale_rate'] = results.fixedRates['sale_rate'];
            results['buy_rate'] = results.fixedRates['buy_rate'];
            results['additional_charge'] = results.fixedRates['additional_charge'];
          }
          if (results && results.ecommerceAccountSettings) {
            results['website'] = results.ecommerceAccountSettings['website'];
            results['logo'] = results.ecommerceAccountSettings['logo'];
            results['terms_of_service'] = results.ecommerceAccountSettings['termsofserviceurl'];
            results['returnpolicy'] = results.ecommerceAccountSettings['returnpolicy'];
            results['parcel_info'] = results.ecommerceAccountSettings['parcelinstructionsurl'];
            results['about_us'] = results.ecommerceAccountSettings['aboutus'];
          }
          if (results && results.ecommerceAccountSettings && results.ecommerceAccountSettings['skinbackground'] && results.ecommerceAccountSettings['skinbackground'] !== null) {
            var bgData = results.ecommerceAccountSettings['skinbackground'];
            if (bgData.indexOf('#') >= 0) {
              selectedSkinBgMethod = 'Color';
              controller.set("skinBgImageBool", false);
              controller.set("skinBgColorBool", true);
            }
          }
          var channelsArr = [];
          if (response.result && response.result.ecommerceChannelMapping && response.result.ecommerceChannelMapping.length > 0) {
            for (var i = 0; i < response.result.ecommerceChannelMapping.length; i++) {
              channelsArr.push(response.result.ecommerceChannelMapping[i].channel_id);
            }
          }
          results['skinBgMethod'] = selectedSkinBgMethod;
          results['timezoneId'] = results.timeZoneId;
          if (results.notification_username && results.notification_url && results.notification_username !== "" && results.notification_url !== "") {
            results['notification_password'] = controller.get("tempPassword");
          } else {
            results['notification_password'] = '';
          }

          results['enable_payment'] = false;

          if (results.parentTemplate && (results.parentTemplate !== "" || results.parentTemplate !== null) && results.parentTemplate !== "default") {
            // controller.set("showThemeOptionBool", true);
          }
          if (results.template && (results.template !== "" || results.template !== null) && results.template !== "default") {
            controller.set('defaultTemplateBool', false);
          } else {
            results['template'] = "default";
          }

          if (results.payment_rate_type && results.payment_rate_type === 1) {
            results['enable_payment'] = true;
          }
          results['channel_ids'] = channelsArr;
          controller.set("developerAccount", _adminPortalShapesDeveloperAccount['default'].create(_ember['default'].getOwner(controller).ownerInjection(), results));
          controller.set("developerAccount.customNetwork", 0);

          if (results.isCustomNetwork && results.isCustomNetwork === true) {
            controller.set("developerAccount.customNetwork", 1);
          }

          if (response.result.ecommerceProductMapping.length > 0) {
            controller.set("products_list_bool", true);
            response.result.ecommerceProductMapping.map(function (a) {
              if (a.enabled === true && a.product_name === "Click & Collect") {
                controller.set('clickCollectPageSetupUI', true);
              } else if (a.enabled === false && a.product_name === "Click & Collect") {
                controller.set('clickCollectPageSetupUI', false);
              }
              if (a.enabled === true && a.product_name === "Return") {
                controller.set('returnPageSetupUI', true);
              } else if (a.enabled === false && a.product_name === "Return") {
                controller.set('returnPageSetupUI', false);
              }
              if (controller.get('returnPageSetupUI') === true || controller.get('clickCollectPageSetupUI') === true) {
                controller.set('showPageSetupUI', true);
              } else {
                controller.set('showPageSetupUI', false);
              }
            });
          }
          controller.set("productMappingList", response.result.ecommerceProductMapping);
        } else {
          controller.get('notify').success('Error encountered while fetching the details.');
          //controller.transitionToRoute('ecommerce.list');
        }
        controller.get("loadingSpinner").hide();
      }, console.log);

      this.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/getmastertableslist', {
        headers: {
          'Accept': 'application/json',
          'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
        }
      }).then(function (response) {
        if (response && response.result && response.result.channels) {
          controller.set("channel_list", response.result.channels);
        }
        if (response && response.result && response.result.roles) {
          for (var i = 0; i <= response.result.roles.length; i++) {
            if (response.result.roles[i].rolename === "EcommerceCustomer") {
              controller.set('role_id', response.result.roles[i].id);
              break;
            }
          }
        }
      }, console.log);

      // fetch countries
      this.get('ajax').request('v2/admin/countries').then(function (response) {
        controller.set("countries", response);
      }, console.log);
    }
  });
});