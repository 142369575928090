define('admin-portal/pods/locations/new/route', ['exports', 'ember', 'rsvp', 'ember-simple-auth/mixins/authenticated-route-mixin', 'admin-portal/shapes/new-location'], function (exports, _ember, _rsvp, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _adminPortalShapesNewLocation) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        config: _ember['default'].inject.service('config'),
        ajax: _ember['default'].inject.service("ajax"),

        model: function model() {
            return _rsvp['default'].hash({
                abilities: this.get('ajax').request('v2/admin/dropLocations/abilities'),
                storeTypes: this.get('ajax').request('v2/admin/storeTypes'),
                countries: this.get('ajax').request('v2/admin/countries')
            });
        },

        // listingData:  this.get('ajax').request(this.get("config.carrierApiURL")+'ecommerce/GetMapConfig'),
        setupController: function setupController(controller, model) {
            controller.set("selectedCountryName", '');
            controller.set('new.address', '');
            controller.set("abilities", model.abilities);
            controller.set("storeTypes", model.storeTypes);
            controller.set("countries", model.countries);
            controller.set("openTimePicker", config.openTimePicker);
            controller.set("closeTimePicker", config.closeTimePicker);

            var businessHours = [{ "id": null, "day": 1, "openTime": null, "closeTime": null }, { "id": null, "day": 2, "openTime": null, "closeTime": null }, { "id": null, "day": 3, "openTime": null, "closeTime": null }, { "id": null, "day": 4, "openTime": null, "closeTime": null }, { "id": null, "day": 5, "openTime": null, "closeTime": null }, { "id": null, "day": 6, "openTime": null, "closeTime": null }, { "id": null, "day": 7, "openTime": null, "closeTime": null }];
            controller.set("new.businessHours", businessHours);

            this.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/getmastertableslist', {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
                }
            }).then(function (listingData) {
                if (listingData.responseCode === "200" || listingData.responseCode === 200) {
                    var listingDataObj = listingData.result;
                    controller.set("productList", listingDataObj.products);
                    controller.set("opening_days", listingDataObj.operatingHoursList);
                    controller.set("serviceTypes", listingDataObj.serviceList);
                    controller.set("channel_list", listingDataObj.channels);
                    controller.set("location_type_list", listingDataObj.locationTypeList);
                }
            });
        },
        actions: {
            willTransition: function willTransition() {
                this.controller.set('new', _adminPortalShapesNewLocation['default'].create(_ember['default'].getOwner(this).ownerInjection()));
                return true;
            }
        }
    });
});