define('admin-portal/pods/lockers/locker-unit/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'admin-portal/shapes/locker-unit'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _adminPortalShapesLockerUnit) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service(),

    model: function model(params) {
      return params;
    },

    setupController: function setupController(controller, model) {
      var _this = this;

      this.get('ajax').request('v2/admin/lockerUnits/' + model.locker_id).then(function (results) {
        controller.set("unit", results);
        controller.set("lockerUnitID", model.locker_id);
        controller.set("dropboxEnabledBool", true);
        controller.set("dropbox_drop_in", '');
        controller.set("dropbox_drop_out", '');
        controller.set("dropbox_allocations", null);
        controller.set("lockerValues", []);
        _this.get('ajax').request('v2/admin/lockerProviders/' + results.provider.id + '/sizeClasses').then(function (results) {
          var sizeClassObj = [];
          if (results && results.length > 0) {
            for (var i = 0; i < results.length; i++) {
              if (results[i].ext_ref === "drop-in") {
                controller.set("dropInSizeClassID", results[i].id);
              } else if (results[i].ext_ref === "drop-out") {
                controller.set("dropOutSizeClassID", results[i].id);
              } else {
                sizeClassObj.push(results[i]);
              }
            }
          }
          controller.set("sizeClasses", sizeClassObj.sort(function (a, b) {
            return a.id - b.id;
          }));
        }, console.log);

        _this.get('ajax').request('v2/admin/' + model.locker_id + '/getDropinandout').then(function (results) {
          if (results && results.length > 0 && results[0].shape.disabled_at !== null && results[0].shape.disabled_at !== "") {
            controller.set("dropboxEnabledBool", false);
          } else if (results && results.length > 0 && results[0].shape.disabled_at === null && results[0].shape.disabled_at === "") {
            controller.set("dropboxEnabledBool", true);
          }
          controller.set("dropboxDetails", results);
        }, console.log);
      }, console.log);
      this.get('ajax').request('v2/admin/lockerUnits/' + model.locker_id + '/lockers').then(function (results) {
        controller.set("lockers", results);
      }, console.log);
      controller.set('locker-unit', _adminPortalShapesLockerUnit['default'].create(_ember['default'].getOwner(controller).ownerInjection()));
    }
  });
});

// import formValidation from '../../shapes/locker-unit';