define('admin-portal/pods/transactions/exports/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    config: _ember['default'].inject.service('config'),

    queryParams: ["page"],

    page: 1,
    totalPages: 1,
    transactions: _ember['default'].A([]),

    csvURL: _ember['default'].computed('report_id', 'session.data.authenticated', 'config.apiURL', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      return this.get("config.apiURL") + '/v2/admin/transactions/exports/' + this.get("report_id") + '/csv?token=' + jwt;
    }),

    actions: {
      getPage: function getPage(page) {
        var self = this;
        self.set("loading", true);
        this.get('ajax').request('v2/admin/transactions/exports/' + this.get("report_id") + '?page=' + page + '&perPage=20').then(function (result) {
          self.set('page', result.page);
          self.set('totalPages', result.totalPages);
          self.set('transactions', _ember['default'].A(result.items));
          self.set("loading", false);
          self.get("loadingSpinner").hide();
        }, function (error) {
          console.log(error);
          self.get("loadingSpinner").hide();
        });
      },

      selectExport: function selectExport(value) {
        this.get("loadingSpinner").show();
        this.set("selectedExport", value);
        this.set("report_id", value.id);
        this.send("getPage", 1);
      }

    }

  });
});