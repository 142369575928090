define('admin-portal/pods/courier/update/controller', ['exports', 'ember', 'admin-portal/shapes/newcourier'], function (exports, _ember, _adminPortalShapesNewcourier) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    notify: _ember['default'].inject.service('notify'),
    loading: _ember['default'].inject.service('loading'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    courierUnit: null,
    apiKeyValue: null,
    isBillingOpen: true,
    savedWebhookEvents: null,
    init: function init() {
      this.set('courierUnit', _adminPortalShapesNewcourier['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },
    actions: {
      billingOpen: function billingOpen(courierUnit) {
        if (this.get('isBillingOpen')) {
          this.set('isBillingOpen', false);

          this.set('courierUnit.apiuser.billingAddress.address', courierUnit.apiuser.address.address);
          this.set('courierUnit.apiuser.billingAddress.suburb', courierUnit.apiuser.address.suburb);
          this.set('courierUnit.apiuser.billingAddress.state', courierUnit.apiuser.address.state);
          this.set('courierUnit.apiuser.billingAddress.postcode', courierUnit.apiuser.address.postcode);
          this.set('courierUnit.apiuser.billingAddress.country', courierUnit.apiuser.address.country);
        } else {
          this.set('isBillingOpen', true);

          this.set('courierUnit.apiuser.billingAddress.address', null);
          this.set('courierUnit.apiuser.billingAddress.suburb', null);
          this.set('courierUnit.apiuser.billingAddress.state', null);
          this.set('courierUnit.apiuser.billingAddress.postcode', null);
          this.set('courierUnit.apiuser.billingAddress.country', null);
        }
      },
      update: function update() {
        var _this = this;

        //let self = this;
        // let webhook_events =
        // self.get("webhook_events").map(function(a) {
        //   if(a.enabled){
        //     return {
        //       "active" : true,
        //       "eventId" : Number(a.WebhookEvents_id)
        //     };
        //   }else{
        //     return {
        //       "active" : false,
        //       "eventId" : Number(a.WebhookEvents_id)
        //     };
        //   }
        //   });
        var courierDetails = {
          "name": this.get('courierUnit.courier.name'),
          "code": this.get('courierUnit.courier.code'),
          "contactName": this.get('courierUnit.courier.contactName'),
          "courierType": 1,
          "logo": this.get('courierUnit.courier.logo'),
          "contactPhone": this.get('courierUnit.courier.contactPhone'),
          "active": this.get('courierUnit.courier.active')
        };
        var apiuserDetails = {
          "email": this.get('courierUnit.apiuser.email'),
          "notificationUsername": this.get('courierUnit.apiuser.notificationUsername'),
          "billingAddress": {
            state: this.get('courierUnit.apiuser.billingAddress.state'),
            postcode: this.get('courierUnit.apiuser.billingAddress.postcode'),
            country: this.get('courierUnit.apiuser.billingAddress.country'),
            suburb: this.get('courierUnit.apiuser.billingAddress.suburb'),
            address: this.get('courierUnit.apiuser.billingAddress.address')
          },
          "apikey": this.get('apiKeyValue'),
          "languageCode": this.get('courierUnit.apiuser.languageCode'),
          "notificationPassword": this.get('courierUnit.apiuser.notificationPassword'),
          "notificationURL": this.get('courierUnit.apiuser.notificationURL'),
          "id": this.get('courierUnit.apiuser.id'),
          "isDeleted": this.get('courierUnit.apiuser..isDeleted'),
          "token": this.get('courierUnit.apiuser.token'),
          "address": {
            state: this.get('courierUnit.apiuser.address.state'),
            postcode: this.get('courierUnit.apiuser.address.postcode'),
            country: this.get('courierUnit.apiuser.address.country'),
            suburb: this.get('courierUnit.apiuser.address.suburb'),
            address: this.get('courierUnit.apiuser.address.address')
          },
          "mobile": this.get('courierUnit.apiuser.mobile')
        };
        this.get('ajax').put('v2/admin/couriers', {
          data: JSON.stringify({
            courier: courierDetails,
            apiuser: apiuserDetails,
            webhookEvents: this.get('savedWebhookEvents')
          }),
          contentType: 'application/json; charset=utf-8',
          crossDomain: true
        }).then(function () {
          _this.get('notify').success('Courier has been successfully Created.');
          _this.transitionToRoute('courier.couriers');
        })['catch'](function (error) {
          if (error && error.errors) {
            var errorMsg = error.errors.map(function (e) {
              return e.detail;
            });

            if (errorMsg[0] && errorMsg[0].message) {
              _this.get('notify').error(errorMsg[0].message);
            } else {
              _this.get('notify').error('Error updating details.');
            }
          } else {
            _this.get('notify').success('Courier has been successfully Created.');
            _this.transitionToRoute('courier.couriers');
          }
        });
      },
      regenerateAPIKey: function regenerateAPIKey(code) {
        var _this2 = this;

        this.get('ajax').post('v2/admin/couriers/' + code + '/refreshToken', {
          contentType: 'application/json; charset=utf-8',
          crossDomain: true
        }).then(function (result) {
          _this2.set("apiKeyValue", result.apikey);
          _this2.get('notify').success('API Key has been successfully Regenerated.');
        }, function () /*error*/{
          _this2.get('notify').error('Error regenerating API Key.');
        });
      }
    }
  });
});