define('admin-portal/shapes/manifest', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  var Validations = (0, _emberCpValidations.buildValidations)({
    courier_code: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true })],
    location: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true, message: 'Please select store' })],
    manifest_day: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true })],
    manifest_time: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true })],
    manifest_number: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gt: 0,
      lte: 9999
    })]
  });
  exports['default'] = _ember['default'].Object.extend(Validations, {
    courier_code: null,
    manifest_day: null,
    manifest_time: null,
    manifest_number: null
  });
});