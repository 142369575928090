define('admin-portal/pods/invoicing/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'admin-portal/shapes/invoicing'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _adminPortalShapesInvoicing) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        config: _ember['default'].inject.service('config'),
        ajax: _ember['default'].inject.service("ajax"),

        setupController: function setupController(controller) {
            controller.set('newTransactionReport', null);
            controller.set('transactionMonthTextSelected', null);
            controller.set('transactionMonthValSelected', null);
            controller.set('summaryReportMonthValSelected', null);
            controller.set('summaryReportMonthTextSelected', null);
            controller.set('showTransactionMonthBool', false);
            controller.set('showSummaryReportMonthBool', false);

            var monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var currentDate = new Date();

            var firstMonth = currentDate.getMonth();
            var secondMonth = currentDate.getMonth();
            var thirdMonth = currentDate.getMonth();

            var firstYear = currentDate.getFullYear();
            var secondYear = currentDate.getFullYear();
            var thirdYear = currentDate.getFullYear();

            if (currentDate.getMonth() - 1 < 0) {
                firstMonth = 12 + (currentDate.getMonth() - 1);
                firstYear = currentDate.getFullYear() - 1;
            } else {
                firstMonth = currentDate.getMonth() - 1;
            }
            if (currentDate.getMonth() - 2 < 0) {
                secondMonth = 12 + (currentDate.getMonth() - 2);
                secondYear = currentDate.getFullYear() - 1;
            } else {
                secondMonth = currentDate.getMonth() - 2;
            }
            if (currentDate.getMonth() - 3 < 0) {
                thirdMonth = 12 + (currentDate.getMonth() - 3);
                thirdYear = currentDate.getFullYear() - 1;
            } else {
                thirdMonth = currentDate.getMonth() - 3;
            }
            var monthlyArr = [];
            monthlyArr.push({ "month": firstMonth, "year": firstYear, "display_name": monthArr[firstMonth] + " " + firstYear });
            monthlyArr.push({ "month": secondMonth, "year": secondYear, "display_name": monthArr[secondMonth] + " " + secondYear });
            monthlyArr.push({ "month": thirdMonth, "year": thirdYear, "display_name": monthArr[thirdMonth] + " " + thirdYear });

            controller.set('newTransactionReport', { "month": firstMonth, "year": firstYear, "display_name": monthArr[firstMonth] + " " + firstYear });
            controller.set('monthlyDropDown', monthlyArr);

            controller.set('invoicing', _adminPortalShapesInvoicing['default'].create(_ember['default'].getOwner(controller).ownerInjection()));
            controller.set('invoicing.fileData', null);

            this.get('ajax').request(this.get("config.carrierApiURL") + 'admin/InvoicingReport/GetArchiveTableDropDownItems', {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
                }
            }).then(function (response) {
                var previousMonthTransactionArr = [];
                var previousMonthSummaryArr = [];

                if (response && response.result && response.result.invoicedTransactionReportDropDownItems && response.result.invoicedTransactionReportDropDownItems.length > 0) {
                    previousMonthTransactionArr = response.result.invoicedTransactionReportDropDownItems;
                }
                controller.set("previousMonthTransactionArr", previousMonthTransactionArr);

                if (response && response.result && response.result.invoicedSummaryReportDropDownItems && response.result.invoicedSummaryReportDropDownItems.length > 0) {
                    previousMonthSummaryArr = response.result.invoicedSummaryReportDropDownItems;
                }
                controller.set("previousMonthSummaryArr", previousMonthSummaryArr);
            }, console.log);
        }
    });
});