define('admin-portal/pods/consumer/consignment/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),

    consignment: null,
    assigningJobNumber: false,
    jobNumber: "",

    assigningConsignmentNumber: false,
    consignmentNumber: "",

    actions: {
      reloadConsignment: function reloadConsignment() {
        var self = this;
        this.get('ajax').request('v2/consumerPortal/admin/consignments/' + this.get("consignment.id")).then(function (result) {
          self.set('consignment', result);
        }, function (error) {
          console.log(error);
        });
      },

      assignJobNumber: function assignJobNumber() {
        this.set("assigningJobNumber", true);
      },

      assignConsignmentNumber: function assignConsignmentNumber() {
        this.set("assigningConsignmentNumber", true);
      },

      saveConsignmentNumber: function saveConsignmentNumber() {
        var self = this;
        this.get('ajax').post('v2/consumerPortal/admin/consignments/' + this.get("consignment.id"), {
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify({ consignmentNumber: this.get("consignmentNumber") })
        }).then(function () {
          self.send("reloadConsignment");
          self.set("assigningConsignmentNumber", false);
          self.set("consignmentNumber", "");
        }, function (error) {
          console.log(error);
        });
      },

      saveJobNumber: function saveJobNumber() {
        var self = this;
        this.get('ajax').post('v2/consumerPortal/admin/consignments/' + this.get("consignment.id"), {
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify({ pickupJobNumber: this.get("jobNumber") })
        }).then(function () {
          self.send("reloadConsignment");
          self.set("assigningJobNumber", false);
          self.set("jobNumber", "");
        }, function (error) {
          console.log(error);
        });
      }

    }

  });
});