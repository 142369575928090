define('admin-portal/pods/unlinked/consignments/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    notify: _ember['default'].inject.service('notify'),
    loading: _ember['default'].inject.service('loading'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    startDate: new Date(),
    endDate: new Date(),
    item_barcode: null,
    couriersAll: 'all',
    missedConsignments: _ember['default'].A([]),
    courierTypes: [{ name: "All", code: "all" }, { name: "Couriers Please", code: "cpl" }, { name: "Toll", code: "toll" }, { name: "DHL", code: "dhl" }, { name: "UPS", code: "UPS_1562219440" }],

    buildUrl: function buildUrl(page) {
      var url = 'v2/admin/unlinkConsignment?page=' + page + '&perPage=20';
      if (this.get('couriersAll') !== 'all') {
        url += '&type=' + this.get("couriersAll");
      }
      //if(this.get('startDate')) { url += '&start=' + moment(this.get("startDate")).format("YYYY-MM-DD");}
      //if(this.get('endDate')) { url += '&end=' + moment(this.get("endDate")).format("YYYY-MM-DD");}
      if (this.get('item_barcode')) {
        url += '&item_barcode=' + this.get('item_barcode');
      }
      return url;
    },
    actions: {
      getPage: function getPage(page) {
        var self = this;
        self.set("loading", true);
        this.get('ajax').request(this.buildUrl(page)).then(function (result) {
          //self.set('page', 1);
          //self.set('totalPages', 15);
          self.set('page', result.Page);
          self.set('totalPages', result.TotalPages);
          self.set('missedConsignments', _ember['default'].A(result.results));
          //let dataa = result.results;
          //debugger
          self.set("loading", false);
          self.get("loadingSpinner").hide();
        }, function (error) {
          console.log(error);
        });
      },
      applyFilter: function applyFilter() {
        this.get("loadingSpinner").show();
        this.send("getPage", 1);
      }
    }
  });
});