define('admin-portal/pods/upload-image/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        config: _ember['default'].inject.service('config'),
        ajax: _ember['default'].inject.service(),
        session: _ember['default'].inject.service('session'),
        notify: _ember['default'].inject.service('notify'),
        fileData: null,
        regenerateFileData: null,

        actions: {
            reUploadTransactionReport: function reUploadTransactionReport() {
                var fileContent = document.getElementById('regenertae-file-upload').files[0];
                this.set('regenerateFileData', fileContent);
            },

            delete_uploaded_image: function delete_uploaded_image() {
                var self = this;
                var jwt = this.get('session.data.authenticated.jwt');

                $.ajax({
                    url: this.get("config.apiURL") + '/v2/admin/agent/notification/' + this.get('getNotificationID') + '?token=' + jwt,
                    processData: false,
                    contentType: false,
                    type: 'DELETE',
                    success: function success() /*responseData*/{
                        self.send('loadFreshData');
                        self.get('notify').success('Successfully deleted the notification image.');
                        self.set("existingImageBlob", '');
                        self.set("notification_name", '');
                    },
                    error: function error(jqXHR, exception) {
                        var msg = '';
                        if (jqXHR.status === 0) {
                            msg = 'Not connect.\n Verify Network.';
                        } else if (jqXHR.status === 404) {
                            msg = 'Requested page not found. [404]';
                        } else if (jqXHR.status === 500) {
                            msg = 'Internal Server Error [500].';
                        } else if (exception === 'parsererror') {
                            msg = 'Requested JSON parse failed.';
                        } else if (exception === 'timeout') {
                            msg = 'Time out error.';
                        } else if (exception === 'abort') {
                            msg = 'Ajax request aborted.';
                        } else {
                            msg = 'Uncaught Error.\n' + jqXHR.responseText;
                        }
                        self.get('notify').error(msg);
                    }
                });
            },
            loadFreshData: function loadFreshData() {
                var _this = this;

                this.set('getNotificationID', '');
                this.set('existingImageBlob', '');
                this.set('enabled', false);

                this.get('ajax').request('v2/admin/agent/notifications?perPage=1').then(function (response) {
                    if (response && response.results && response.results.length > 0) {
                        _this.set("getNotificationID", response.results[0].id);
                        _this.set('notification_name', response.results[0].notificationName);
                        _this.set('description', response.results[0].description);
                        _this.set('enabled', response.results[0].enabled);

                        _this.get('ajax').request('v2/agent/notification/' + response.results[0].id).then(function (imageBlob) {
                            _this.set("existingImageBlob", imageBlob);
                        }, console.log);
                    }
                }, console.log);
            },
            createImage: function createImage(formDataUpload) {
                var self = this;
                var jwt = this.get('session.data.authenticated.jwt');
                $.ajax({
                    url: this.get("config.apiURL") + '/v2/admin/agent/notifications?token=' + jwt,
                    data: formDataUpload,
                    processData: false,
                    contentType: false,
                    type: 'POST',
                    success: function success() /*data*/{
                        self.set('regenerateFileData', null);
                        document.getElementById('regenertae-file-upload').value = '';
                        self.set("uploadingTransactionBool", false);
                        self.send('loadFreshData');
                        self.get('notify').success('Successfully Uploaded');
                    },
                    error: function error(jqXHR, exception) {
                        self.set("uploadingTransactionBool", false);
                        var msg = '';
                        if (jqXHR.status === 0) {
                            msg = 'Not connect.\n Verify Network.';
                        } else if (jqXHR.status === 404) {
                            msg = 'Requested page not found. [404]';
                        } else if (jqXHR.status === 500) {
                            msg = 'Internal Server Error [500].';
                        } else if (exception === 'parsererror') {
                            msg = 'Requested JSON parse failed.';
                        } else if (exception === 'timeout') {
                            msg = 'Time out error.';
                        } else if (exception === 'abort') {
                            msg = 'Ajax request aborted.';
                        } else {
                            msg = 'Uncaught Error.\n' + jqXHR.responseText;
                        }
                        self.get('notify').error(msg);
                    }
                });
            },
            saveImageUploaded: function saveImageUploaded() {
                var self = this;
                var jwt = this.get('session.data.authenticated.jwt');
                var formDataUpload = new FormData();
                formDataUpload.append('enabled', this.get('enabled'));
                formDataUpload.append('expiry', this.get('expiry'));
                formDataUpload.append('notification_name', this.get('notification_name'));
                formDataUpload.append('description', this.get('description'));

                if (this.get('notification_name') !== "" && this.get('notification_name') !== undefined) {
                    if (this.get('regenerateFileData')) {
                        formDataUpload.append('file', this.get('regenerateFileData'));
                        this.set("uploadingTransactionBool", true);

                        if (this.get('getNotificationID') !== '') {
                            $.ajax({
                                url: this.get("config.apiURL") + '/v2/admin/agent/notification/' + this.get('getNotificationID') + '?token=' + jwt,
                                processData: false,
                                contentType: false,
                                type: 'DELETE',
                                success: function success() /*data1*/{
                                    self.send('createImage', formDataUpload);
                                },
                                error: function error(jqXHR, exception) {
                                    var msg = '';
                                    if (jqXHR.status === 0) {
                                        msg = 'Not connect.\n Verify Network.';
                                    } else if (jqXHR.status === 404) {
                                        msg = 'Requested page not found. [404]';
                                    } else if (jqXHR.status === 500) {
                                        msg = 'Internal Server Error [500].';
                                    } else if (exception === 'parsererror') {
                                        msg = 'Requested JSON parse failed.';
                                    } else if (exception === 'timeout') {
                                        msg = 'Time out error.';
                                    } else if (exception === 'abort') {
                                        msg = 'Ajax request aborted.';
                                    } else {
                                        msg = 'Uncaught Error.\n' + jqXHR.responseText;
                                    }
                                    self.get('notify').error(msg);
                                }
                            });
                        } else {
                            self.send('createImage', formDataUpload);
                        }
                    } else if (this.get('regenerateFileData') === null && this.get('getNotificationID') !== '') {

                        this.set("uploadingTransactionBool", true);

                        $.ajax({
                            url: this.get("config.apiURL") + '/v2/admin/agent/notification/' + this.get('getNotificationID') + '?token=' + jwt,
                            data: formDataUpload,
                            processData: false,
                            contentType: false,
                            type: 'PUT',
                            success: function success() /*data*/{
                                self.set('regenerateFileData', null);
                                document.getElementById('regenertae-file-upload').value = '';
                                self.set("uploadingTransactionBool", false);
                                self.send('loadFreshData');
                                self.get('notify').success('Successfully Uploaded');
                            },
                            error: function error(jqXHR, exception) {
                                self.set("uploadingTransactionBool", false);
                                var msg = '';
                                if (jqXHR.status === 0) {
                                    msg = 'Not connect.\n Verify Network.';
                                } else if (jqXHR.status === 404) {
                                    msg = 'Requested page not found. [404]';
                                } else if (jqXHR.status === 500) {
                                    msg = 'Internal Server Error [500].';
                                } else if (exception === 'parsererror') {
                                    msg = 'Requested JSON parse failed.';
                                } else if (exception === 'timeout') {
                                    msg = 'Time out error.';
                                } else if (exception === 'abort') {
                                    msg = 'Ajax request aborted.';
                                } else {
                                    msg = 'Uncaught Error.\n' + jqXHR.responseText;
                                }
                                self.get('notify').error(msg);
                            }
                        });
                    } else {
                        this.get('notify').error('Please upload the file');
                    }
                } else {
                    this.get('notify').error('Please enter the notification name');
                }
            }

        }
    });
});