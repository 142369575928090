define('admin-portal/pods/consignments/pods/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    loadingSpinner: _ember['default'].inject.service('loading'),
    results: null,
    q: "",
    queryParams: ['q'],

    actions: {
      search: function search() {
        var self = this;
        this.get('ajax').request('v2/admin/consignments/search?q=' + this.get("q")).then(function (results) {
          self.set('results', results);
        }, function (error) {
          console.log(error);
        });
      },

      // showPOD(consignment) {
      //   this.set("currentConsignment", consignment);
      //   Ember.$(".ui.modal.consignment-pod").modal('show');
      // }

      showPOD: function showPOD(consignment) {
        var _this = this;

        //console.log('consignment === ', consignment);

        var hasAdminMarkoff = false;
        var i;

        if (consignment.articles.length > 0) {
          for (i = 0; i < consignment.articles.length; i++) {
            if (consignment.articles[i].adminLockerMarkOff.length > 0) {
              hasAdminMarkoff = true;
            }
          }
        }

        this.set("currentConsignment", null);
        this.get("loadingSpinner").show();
        if (consignment.latestArticleState === 2) {
          this.get('ajax').request('v2/admin/consignments/' + consignment.id + '/pod').then(function (results) {
            if (results.resentSMS && results.resentSMS[0]) {
              results.latestResendNumber = results.resentSMS[0].to;
            }

            results.pickupHasSignature = consignment.pickupHasSignature;
            results.pickupReason = consignment.pickupReason;
            results.pickupReceiver = consignment.pickupReceiver;
            results.articles = consignment.articles;
            results.hasAdminMarkoff = hasAdminMarkoff;

            _this.get("loadingSpinner").hide();
            _this.set("currentConsignment", results);
          }, function () {
            _this.get("loadingSpinner").hide();
          });
        } else {

          var isd = '';
          var pickupReason = '';
          if (consignment.articles[0].adminLockerMarkOff && consignment.articles[0].adminLockerMarkOff[0]) {
            isd = consignment.articles[0].adminLockerMarkOff[0].isd;
            pickupReason = consignment.articles[0].adminLockerMarkOff[0].reason;
          }

          var setResponse = {
            pickupReceipt: {
              "receiver": {
                "name": consignment.receiver.name,
                "phone": consignment.receiver.phone
              },
              "reason": consignment.pickupReason,
              "consignmentNumber": consignment.consignmentNumber,
              "consignmentId": consignment.id

            },
            "pickupHasSignature": consignment.pickupHasSignature,
            "pickupReason": pickupReason,
            "pickupReceiver": consignment.pickupReceiver,
            "isd": isd,
            "articles": consignment.articles,
            "hasAdminMarkoff": hasAdminMarkoff
          };
          this.get("loadingSpinner").hide();
          this.set("currentConsignment", setResponse);
        }

        _ember['default'].$(".ui.modal.consignment-pod").modal('show');
      }

    }
  });
});