define('admin-portal/pods/courier/new/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    notify: _ember['default'].inject.service('notify'),
    loading: _ember['default'].inject.service('loading'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    isBillingOpen: true,
    carrier_partners: [{ name: "Couriers Please", code: "cpl" }, { name: "Toll", code: "toll" }, { name: "None", code: null }],
    timezone_list: config.timezone_list,

    actions: {
      billingOpen: function billingOpen(newcourier) {
        if (this.get('isBillingOpen')) {
          this.set('isBillingOpen', false);
          this.set('newcourier.billing_address', newcourier.address1);
          this.set('newcourier.billing_address2', newcourier.address2);
          this.set('newcourier.billing_suburb', newcourier.suburb);
          this.set('newcourier.billing_state', newcourier.state);
          this.set('newcourier.billing_postcode', newcourier.postcode);
          this.set('newcourier.billing_country', newcourier.country);
        } else {
          this.set('isBillingOpen', true);
          this.set('newcourier.billing_address', null);
          this.set('newcourier.billing_address2', null);
          this.set('newcourier.billing_suburb', null);
          this.set('newcourier.billing_state', null);
          this.set('newcourier.billing_postcode', null);
          this.set('newcourier.billing_country', null);
        }
      },
      setCountry: function setCountry(country_name) {
        this.set('newcourier.country', country_name);
      },
      populateBillingAddress: function populateBillingAddress(newcourier) {
        if (this.get('isBillingOpen')) {
          this.set('newcourier.billing_address', null);
          this.set('newcourier.billing_address2', null);
          this.set('newcourier.billing_suburb', null);
          this.set('newcourier.billing_state', null);
          this.set('newcourier.billing_postcode', null);
          this.set('newcourier.billing_country', null);
        } else {
          this.set('newcourier.billing_address', newcourier.address1);
          this.set('newcourier.billing_address2', newcourier.address2);
          this.set('newcourier.billing_suburb', newcourier.suburb);
          this.set('newcourier.billing_state', newcourier.state);
          this.set('newcourier.billing_postcode', newcourier.postcode);
          this.set('newcourier.billing_country', newcourier.country);
        }
      },
      create: function create() {
        var _this = this;

        var self = this;
        var webhook_events = '';
        if (self.get("webhook_events")) {
          webhook_events = self.get("webhook_events").map(function (a) {
            if (a.enabled) {
              return {
                "active": true,
                "eventId": Number(a.WebhookEvents_id)
              };
            } else {
              return {
                "active": false,
                "eventId": Number(a.WebhookEvents_id)
              };
            }
          });
        }
        var duplicateAllowedDays = this.get("newcourier.duplicate_allowed_days");
        if (this.get("newcourier.duplicate_allowed_days") === null || this.get("newcourier.duplicate_allowed_days") === undefined || this.get("newcourier.duplicate_allowed_days") === "") {
          duplicateAllowedDays = 0;
        }
        this.get('ajax').post('v2/admin/couriers', {
          data: JSON.stringify({
            carrier_partner: this.get('newcourier.carrier_partner'),
            timezone: this.get('newcourier.timezone'),
            name: this.get('newcourier.courierCompanyName'),
            logo: this.get('newcourier.logo') && this.get('newcourier.logo') !== "" ? this.get('newcourier.logo') : null,
            contactName: this.get("newcourier.name"),
            contactPhone: this.get("newcourier.phone"),
            email: this.get("newcourier.email"),
            duplicateAllowedDays: duplicateAllowedDays,
            notificationURL: this.get("newcourier.notificationURL"),
            notificationUsername: this.get("newcourier.notificationUserName"),
            notificationPassword: this.get("newcourier.notificationPassword"),
            token: null,
            active: this.get("newcourier.active") && this.get("newcourier.active") !== "" && this.get("newcourier.active") !== undefined ? this.get("newcourier.active") : false,
            active_notification: this.get("newcourier.active_notification") && this.get("newcourier.active_notification") !== "" && this.get("newcourier.active_notification") !== undefined ? this.get("newcourier.active_notification") : false,
            address: {
              address: this.get('newcourier.address1'),
              address2: this.get('newcourier.address2'),
              suburb: this.get('newcourier.suburb'),
              state: this.get("newcourier.state"),
              postcode: this.get('newcourier.postcode'),
              country: this.get("newcourier.country")
            },
            languageCode: this.get("newcourier.language_code"),
            billingAddress: {
              address: this.get('newcourier.billing_address'),
              address2: this.get('newcourier.billing_address2'),
              suburb: this.get('newcourier.billing_suburb'),
              state: this.get("newcourier.billing_state"),
              postcode: this.get('newcourier.billing_postcode'),
              country: this.get("newcourier.billing_country")
            },
            webhookEvents: webhook_events
          }),
          contentType: 'application/json; charset=utf-8',
          crossDomain: true
        }).then(function () {
          _this.set('newcourier', '');
          _this.get('notify').success('Courier has been successfully Created.');
          _this.transitionToRoute('courier.couriers');
        })['catch'](function (error) {
          var errorMsg = error.errors.map(function (e) {
            return e.detail;
          });
          if (errorMsg[0] && errorMsg[0].message) {
            _this.get('notify').error(errorMsg[0].message);
          } else if (error.errors && error.errors.length > 0 && error.errors[0].title) {
            _this.get('notify').error(error.errors[0].title);
          } else {
            _this.get('notify').error('Error while saving, please try again.');
          }
        });
      }
    }
  });
});