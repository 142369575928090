define('admin-portal/pods/consignments/outbound/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),
    loadingSpinner: _ember['default'].inject.service('loading'),

    results: null,
    q: "",
    queryParams: ["q", "page"],

    loading: false,
    noRecordsBool: false,
    page: 1,
    totalPages: 1,

    actions: {
      changePage: function changePage(page) {
        this.set('page', page);
        this.send('refresh');
      },

      search: function search() {
        this.set('page', 1);
        this.send('refresh');
      },

      refresh: function refresh() {
        var _this = this;

        var query = '';
        if (this.get('q') !== "") {
          query = this.get('q');

          this.set('loading', true);

          this.get('ajax').request('v2/admin/SevenEleven/getConsignmentNumber?page=' + this.get('page') + '&perPage=10&consignment_number=' + query).then(function (results) {
            if (results && results.length > 0) {
              _this.set('noRecordsBool', false);
            } else {
              _this.set('noRecordsBool', true);
            }
            _this.set('page', results.page);
            _this.set('totalPages', results.totalPages);
            _this.set('results', results);
            _this.set('loading', false);
            _this.get("loadingSpinner").hide();
          }, console.log);
        } else {
          this.set('results', []);
        }
      },
      showWarrantyModalOpen: function showWarrantyModalOpen(data) {
        this.set("warrantyData", data);
        _ember['default'].$('.ui.modal.consignment-warranty').modal('show');
      },
      cancelShipmentModalOpen: function cancelShipmentModalOpen(consignment) {
        this.set("cancellationConsignment", consignment);
        _ember['default'].$('.ui.modal.cancel-shipment').modal('show');
      },
      cancelOutboundConsignment: function cancelOutboundConsignment(consignment) {
        var _this2 = this;

        this.get("loadingSpinner").show();
        this.get('ajax').put('v2/admin/SevenEleven/cancelStatus?number=' + consignment).then(function () /*result*/{
          _this2.get('notify').success(consignment + ' is successfully cancelled.');
          _this2.set("cancellationConsignment", null);
          _ember['default'].$('.ui.modal.cancel-shipment').modal('hide');
          _this2.get("loadingSpinner").show();
          _this2.send("search");
        }, function (error) {
          if (error) {
            _this2.get('notify').success(consignment + ' is successfully cancelled.');
            _this2.set("cancellationConsignment", null);
            _ember['default'].$('.ui.modal.cancel-shipment').modal('hide');
            _this2.get("loadingSpinner").show();
            _this2.send("search");
          } else {
            _this2.get('notify').error('Could not find ' + consignment);
            _this2.get("loadingSpinner").hide();
          }
        });
      }
    }
  });
});