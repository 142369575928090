define('admin-portal/shapes/newcourier', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false }), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z][a-zA-Z' \-]*$/,
        message: 'Invalid characters'
      })]
    },
    // courierCode: {
    //   description: "Courier Code",
    //   validators: [
    //     validator('presence', { presence: true, ignoreBlank: false }),
    //     validator('format', {
    //       regex: /^[a-zA-Z0-9][a-zA-Z0-9' \-]*$/,
    //       message: 'Invalid characters'
    //     })
    //   ]
    // }, 
    phone: {
      description: "Phone",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 8,
        max: 12
      })]
    },
    timezone: [(0, _emberCpValidations.validator)('presence', true)],
    logo: {
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        regex: /\b((http|https|HTTP|HTTPS):\/\/?)[^\s()<>]+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
        message: 'Invalid URL'
      })]
    },
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      message: 'Invalid email eg. abc@domain.com'
    })],

    notificationURL: {
      description: "Notification URL",
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        regex: /\b((http|https|HTTP|HTTPS):\/\/?)[^\s()<>]+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
        message: 'Invalid URL'
      }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
        var username = model.get('notificationUserName');

        if (value !== undefined && value !== "" && value !== null) {
          if (username === undefined || username === "" || username === null) {
            return 'Please enter user name to enable webhook notifications';
          }
        }

        return true;
      }, {
        dependentKeys: ['model.notificationURL', 'model.notificationUserName', 'model.notificationPassword']
      })]
    },
    notificationUserName: {
      description: "User Name",
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true
      }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
        var notificationPassword = model.get('notificationPassword');
        var notificationURL = model.get('notificationURL');

        if (value !== undefined && value !== "" && value !== null) {
          if (notificationPassword === undefined || notificationPassword === "" || notificationPassword === null) {
            return 'Please enter password to enable webhook notifications';
          }

          if (notificationURL === undefined || notificationURL === "" || notificationURL === null) {
            return 'Please enter notification url to enable webhook notifications';
          }
        }
        return true;
      }, {
        dependentKeys: ['model.notificationUserName', 'model.notificationURL', 'model.notificationPassword']
      })]
    },
    notificationPassword: {
      description: "Notification Password",
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true
      }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
        var notificationURL = model.get('notificationURL');

        if (value !== undefined && value !== "" && value !== null) {
          if (notificationURL === undefined || notificationURL === "" || notificationURL === null) {
            return 'Please enter notification url to enable webhook notifications';
          }
        }
        return true;
      }, {
        dependentKeys: ['model.notificationPassword', 'model.notificationURL', 'model.notificationUserName']
      })]
    },
    // notificationUserName: {
    //   description: "Notification User Name",
    //   validators: [
    //     validator('presence', true),
    //     validator('format', {
    //       regex: /^[^\s].*/,
    //       message: 'Invalid characters'
    //     })
    //   ]
    // },
    // notificationPassword: [
    //   validator('presence', true),
    //   validator('length', {
    //     min: 8,
    //     max: 50
    //   }),
    //   validator('format', {
    //     regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{4,}$/,
    //     message: 'Password must contain atleast one Capital letter, small letter, number and special character.'
    //   })
    // ],
    courierCompanyName: {
      description: "Courier Name",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z0-9][a-zA-Z0-9' \-]*$/,
        message: 'Invalid characters'
      })]
    },
    address1: {
      description: "Address Line",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    suburb: {
      description: "Suburb",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    postcode: {
      description: "Postcode",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    state: {
      description: "State",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    country: {
      description: "Country",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_address: {
      description: "Address Line",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_suburb: {
      description: "Suburb",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_postcode: {
      description: "Postcode",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_state: {
      description: "State",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_country: {
      description: "Country",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    }
  });
  exports['default'] = _ember['default'].Object.extend(Validations, {
    name: null,
    // courierCode: null,
    phone: null,
    email: null,
    timezone: null,
    // notificationURL: null,
    // notificationUserName :null,
    // notificationPassword :null,
    courierCompanyName: null,
    address1: null,
    suburb: null,
    postcode: null,
    state: null,
    country: null,
    billing_address: null,
    billing_suburb: null,
    billing_postcode: null,
    billing_state: null,
    billing_country: null
  });
});