define('admin-portal/services/ajax', ['exports', 'ember', 'ember-ajax/services/ajax'], function (exports, _ember, _emberAjaxServicesAjax) {
  exports['default'] = _emberAjaxServicesAjax['default'].extend({
    session: _ember['default'].inject.service('session'),
    config: _ember['default'].inject.service('config'),

    host: _ember['default'].computed('config.apiURL', {
      get: function get() {
        return this.get("config.apiURL");
      }
    }),

    headers: _ember['default'].computed('session.data.authenticated', {
      get: function get() {
        var headers = {};
        var jwt = this.get('session.data.authenticated.jwt');
        if (jwt) {
          headers['Authorization'] = "Bearer " + jwt;
        }
        return headers;
      }
    })

  });
});