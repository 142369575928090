define('admin-portal/pods/ecommerce/details/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    config: _ember['default'].inject.service('config'),
    notify: _ember['default'].inject.service('notify'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),

    model: function model(params) {
      this.set('selectedEcomCourier', params.id);
      return params;
    },
    setupController: function setupController(controller, model) {
      controller.get("loadingSpinner").show();
      controller.set('selectedEcomCourier', model.id);
      controller.set('ecommerceData', null);
      this.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/edit/' + model.id, {
        headers: {
          'Accept': 'application/json',
          'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
        }
      }).then(function (response) {
        var responseData = response.result;
        var carrier_partner = "None";
        if (responseData.carrier_partner && responseData.carrier_partner !== null && responseData.carrier_partner !== undefined && responseData.carrier_partner !== "") {
          if (responseData.carrier_partner === "cpl") {
            //carrier_partner = "Couriers Please";
            carrier_partner = "CP";
          } else if (responseData.carrier_partner === "toll") {
            carrier_partner = "Toll";
          }
        }
        responseData['carrier_partner'] = carrier_partner;
        controller.set("ecommerceCourierDetails", responseData);
        if (responseData.customers && responseData.customers.length > 0) {
          controller.set('ecommerceData', _ember['default'].A(responseData.customers));
        }
        controller.get("loadingSpinner").hide();
      }, console.log);
    },
    actions: {
      createDeveloperAcc: function createDeveloperAcc() {
        this.transitionTo('ecommerce.customer', this.get("selectedEcomCourier"));
      }
    }
  });
});