define('admin-portal/pods/consumer/invalid-consignments/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),
    loadingSpinner: _ember['default'].inject.service('loading'),

    queryParams: ["page"],

    loading: false,
    page: 1,
    totalPages: 1,
    consignments: _ember['default'].A([]),

    actions: {
      fix: function fix(cons) {
        var _this = this;

        this.get("loadingSpinner").show();
        this.get('ajax').post('v2/consumerPortal/admin/consignments/' + cons.id + '/fix').then(function () /* result */{
          _this.get("loadingSpinner").hide();
          _this.send("getPage", 1);
          _this.get("notify").success('Successfully fixed.');
        }, function (error) {
          console.log(error);
          _this.get("loadingSpinner").hide();
          _this.get("notify").error('Error fixing please contact technical support');
        });
      },

      getPage: function getPage(page) {
        var self = this;
        this.set("loading", true);
        this.get('ajax').request('v2/consumerPortal/admin/invalidConsignments?page=' + page + '&perPage=10').then(function (result) {
          self.set('page', result.page);
          self.set('totalPages', result.totalPages);
          self.set('consignments', _ember['default'].A(result.results));
          self.set("loading", false);
        }, function (error) {
          console.log(error);
          self.set("loading", false);
        });
      }

    }
  });
});