define('admin-portal/pods/ecommerce/view/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    config: _ember['default'].inject.service('config'),
    session: _ember['default'].inject.service('session'),
    ajax: _ember['default'].inject.service(),
    loadingSpinner: _ember['default'].inject.service('loading'),

    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller, model) {
      controller.set('productMapping', false);
      controller.set("viewContent", null);
      controller.set('selectedEcomCourier', model.id);
      controller.get("loadingSpinner").show();
      controller.set('clickCollectPageSetupUI', false);
      controller.set('returnPageSetupUI', false);
      controller.set('showPageSetupUI', false);

      controller.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/edit/' + model.id, {
        headers: {
          'Accept': 'application/json',
          'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
        }
      }).then(function (response) {
        if (response.result.consolidatedLocationAddresssName && (response.result.consolidatedLocationAddresssName !== "" || response.result.consolidatedLocationAddresssName !== null)) {
          controller.set('SelectedConsolidatedAddress', response.result.consolidatedLocationAddresssName);
        }
        if (response && response.result && response.result !== null) {
          controller.set('selectedEcomCourier', response.result.partnerID);
          if (response.result && response.result.ecommerceAccountSettings && response.result.ecommerceAccountSettings !== null && response.result.ecommerceAccountSettings['skinbackground'] && response.result.ecommerceAccountSettings['skinbackground'] !== null) {
            var bgData = response.result.ecommerceAccountSettings['skinbackground'];
            var bgColorBool = false;
            if (bgData.indexOf('#') >= 0) {
              bgColorBool = true;
            }

            response.result['skinBgColorBool'] = bgColorBool;
          }
          var defaultTemp = "default";
          if (response.result.template && (response.result.template !== "" || response.result.template !== null) && response.result.template !== "default") {
            defaultTemp = response.result.template;
          }
          var themeNameStr = defaultTemp;
          if (config.returnPortalTemplate && config.returnPortalTemplate.length > 0) {
            for (var c = 0; c < config.returnPortalTemplate.length; c++) {
              if (config.returnPortalTemplate[c].code === defaultTemp) {
                themeNameStr = config.returnPortalTemplate[c].name;
                break;
              }
            }
          }
          response.result['template'] = defaultTemp;
          response.result['templateName'] = themeNameStr;

          if (response.result.ecommerceProductMapping && response.result.ecommerceProductMapping.length > 0) {
            controller.set('productMapping', true);
            response.result.ecommerceProductMapping.map(function (a) {
              if (a.enabled === true && a.product_name === "Click & Collect") {
                controller.set('clickCollectPageSetupUI', true);
              } else if (a.enabled === false && a.product_name === "Click & Collect") {
                controller.set('clickCollectPageSetupUI', false);
              }
              if (a.enabled === true && a.product_name === "Return") {
                controller.set('returnPageSetupUI', true);
              } else if (a.enabled === false && a.product_name === "Return") {
                controller.set('returnPageSetupUI', false);
              }
              if (controller.get('clickCollectPageSetupUI') === true || controller.get('returnPageSetupUI') === true) {
                controller.set('showPageSetupUI', true);
              } else {
                controller.set('showPageSetupUI', false);
              }
            });
          }

          response.result['ecommerceReturnURL'] = config.ecommerceReturnURL;
          controller.set("viewContent", response.result);
        } else {
          controller.get('notify').success('Error encountered.');
        }
        controller.get("loadingSpinner").hide();
      }, console.log);
    }
  });
});