define('admin-portal/pods/locations/search/controller', ['exports', 'ember', 'moment', 'ember-ajax/errors', 'admin-portal/shapes/new-location', 'admin-portal/helpers/weekday-helper'], function (exports, _ember, _moment, _emberAjaxErrors, _adminPortalShapesNewLocation, _adminPortalHelpersWeekdayHelper) {

  // Utility function to trim strings in an object
  function trimStringsInObject(obj) {
    for (var key in obj) {
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        trimStringsInObject(obj[key]);
      }
    }
  }

  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),
    config: _ember['default'].inject.service('config'),
    loadingSpinner: _ember['default'].inject.service('loading'),

    queryParams: ["page", "location_id"],
    editing: false,
    location: null,
    location_id: null,
    channel_list: ["7-Eleven", "BP", "Pack & Send", "Repco", "National Storage", "Vodafone", "None"],
    //  opening_days: ["Business Hrs", "After Hrs", "Weekend"],
    // countries: ["Australia", "New Zealand", "United Kingdom", "United States"],
    page: 1,
    totalPages: 1,
    consignments: _ember['default'].A([]),
    newLockerUnitProvider: null,
    lockerProviders: null,
    disableButton: true,
    newUser: {
      name: null,
      email: null,
      password: null
    },
    newUserErrors: [],

    newSeven: {},

    locationsURL: _ember['default'].computed('session.data.authenticated', 'config.apiURL', {
      get: function get() {
        var jwt = this.get('session.data.authenticated.jwt');
        return this.get('config.apiURL') + '/v2/admin/dropLocations/search?name={query}&token=' + jwt;
      }
    }),

    locationsResponseWrap: function locationsResponseWrap(response) {
      return {
        results: JSON.parse(response)
      };
    },

    init: function init() {
      _ember['default'].$.fn.search.settings.templates["locationResult"] = function (response, fields) {
        var html = '';
        if (response[fields.results] !== undefined) {
          _ember['default'].$.each(response[fields.results], function (index, result) {
            html += '<a class="result">';
            html += '<div class="content">';
            html += '<div class="title">' + result["name"] + '</div>';
            if (result["cpData"]) {
              html += '<div class="description">' + result["cpData"]["dlb"] + '</div>';
            }
            if (result["dhlData"]) {
              html += '<div class="description">' + result["dhlData"]["locationCode"] + '</div>';
            }
            if (result["tollData"]) {
              html += '<div class="description">' + result["tollData"]["locationCode"] + '</div>';
            }
            if (result["dscData"]) {
              html += '<div class="description">' + result["dscData"]["locationCode"] + '</div>';
            }
            html += '</div>';
            html += '</a>';
          });
          return html;
        }
        return false;
      };
    },

    actions: {

      notActive: function notActive() {
        if (this.get("location.tntLocationId") !== "" && this.get("location.tntSiteId") !== "") {
          this.set('disableButton', false);
        } else {
          this.set('disableButton', true);
        }
      },

      selectedCountry: function selectedCountry(component, id, value) {
        this.set("location.country", value);
        this.set("location.countryCode", id);
      },
      getLatLong: function getLatLong(address, country) {
        var _this = this;

        if (address.state !== null && address.state !== "" && address.state !== undefined && country !== null && country !== undefined && country !== "") {
          var requestData = {
            address: {
              street1: address.street1,
              street2: address.street2,
              city: address.suburb,
              state: address.state,
              postcode: address.postcode,
              country: country
            }
          };
          this.get('ajax').post('v2/admin/geoLocations', {
            data: JSON.stringify(requestData),
            contentType: "application/json",
            crossDomain: true,
            dataType: 'text'
          }).then(function (response) {
            if (response && response !== null) {
              var responseObj = JSON.parse(response);
              if (responseObj.lat && responseObj.lon && responseObj.lat !== "" && responseObj.lon !== "") {
                _this.set("location.latitude", responseObj.lat);
                _this.set("location.longitude", responseObj.lon);
              }
            }
          }, function () /*error*/{
            _this.set("location.latitude", "");
            _this.set("location.longitude", "");
          });
        } else {
          this.set("location.latitude", "");
          this.set("location.longitude", "");
        }
      },
      startEditing: function startEditing() {
        this.set("editing", true);
      },

      cancelEdits: function cancelEdits() {
        this.send("loadLocation");
        this.set("editing", false);
      },

      toggleUser: function toggleUser(user, checked) {
        var action = checked ? "enable" : "disable";
        var self = this;
        this.get('ajax').post('v2/admin/users/' + user.id + '/' + action, {
          dataType: "text"
        }).then(function () /*dl*/{
          self.send("loadLocation");
          self.get("notify").success('Successfully changed user permissions');
        }, function () /*error*/{
          self.get("notify").error('Error changing user permissions');
        });
      },

      createUser: function createUser() {
        var self = this;
        this.get("loadingSpinner").show();
        this.get('ajax').post('v2/admin/users', {
          data: JSON.stringify({
            name: self.get("newUser.name"),
            email: self.get("newUser.email"),
            password: self.get("newUser.password"),
            location: parseInt(self.get("location_id"))
          }),
          contentType: "application/json",
          crossDomain: true
        }).then(function () /*dl*/{
          self.send("loadLocation");
          self.set("newUser", {});
          self.get("loadingSpinner").hide();
          self.set("newUserErrors", []);
          self.get("notify").success('Successfully added user to location.');
        }, function (error) {
          if ((0, _emberAjaxErrors.isBadRequestError)(error)) {
            self.set("newUserErrors", error.errors.map(function (e) {
              return e.detail;
            }));
          }
          self.get("loadingSpinner").hide();
          self.get("notify").error('Error adding user to location');
        });
      },

      addSevenData: function addSevenData() {
        var self = this;
        this.get("loadingSpinner").show();
        this.get('ajax').post('v2/admin/dropLocations/' + this.get("location_id") + '/sevenElevenData', {
          data: JSON.stringify({
            agentCode: self.get("newSeven.agentCode"),
            storeId: self.get("newSeven.storeId"),
            storeSuffix: self.get("newSeven.storeSuffix"),
            validTo: self.get("newSeven.validTo") ? (0, _moment['default'])(self.get("newSeven.validTo")).endOf('day').unix() : null,
            validFrom: self.get("newSeven.validFrom") ? (0, _moment['default'])(self.get("newSeven.validFrom")).startOf('day').unix() : null
          }),
          contentType: "application/json",
          crossDomain: true
        }).then(function () /*dl*/{
          self.send("loadLocation");
          self.set("newSeven", {});
          self.get("loadingSpinner").hide();
          self.get("notify").success('Successfully added seven eleven data to location.');
        }, function () /*error*/{
          self.get("loadingSpinner").hide();
          self.get("notify").error('Error adding seven eleven data to location');
        });
      },

      saveEdits: function saveEdits() {
        var self = this;
        var abilities = self.get("abilities").filter(function (a) {
          return a.enabled;
        }).map(function (a) {
          return Number(a.id);
        });

        var productIdStr = self.get("location.productIds");
        var productIdArr = [];
        if (productIdStr !== null && productIdStr.length > 0) {
          productIdArr = productIdStr.map(function (item) {
            return parseInt(item);
          });
        }

        var serviceTypeIdArr = [];
        var serviceTypeIdStr = self.get("location.serviceTypeIds");
        if (serviceTypeIdStr !== null && serviceTypeIdStr.length > 0) {
          serviceTypeIdArr = serviceTypeIdStr.map(function (item) {
            if (item === null) {
              return '';
            } else {
              return item;
            }
          });
        }
        var locationBusinessHourArr = [],
            saveSucessfullyBool = true,
            errorDayNum = 0;
        var businessHoursData = self.get("location.dropLocationsBusinessHours");
        if (businessHoursData !== null && businessHoursData.length > 0) {
          locationBusinessHourArr = businessHoursData.map(function (item) {
            var returnItem = {
              "id": item.id,
              "day": item.day
            };
            if (item.openTime === "Closed") {
              returnItem['openTime'] = null;
            } else {
              returnItem['openTime'] = item.openTime;
            }
            if (item.closeTime === "Closed") {
              returnItem['closeTime'] = null;
            } else {
              returnItem['closeTime'] = item.closeTime;
            }
            var openTimeFloat = item.openTime;
            var closeTimeFloat = item.closeTime;
            if (openTimeFloat !== null) {
              openTimeFloat = openTimeFloat.replace(":", ".");
            }
            if (closeTimeFloat !== null) {
              closeTimeFloat = closeTimeFloat.replace(":", ".");
            }
            if (item.openTime !== "Closed" && item.closeTime === "Closed" || item.openTime === "Closed" && item.closeTime !== "Closed" || parseFloat(openTimeFloat) >= parseFloat(closeTimeFloat)) {
              saveSucessfullyBool = false;
              errorDayNum = item.day;
            }
            return returnItem;
          });
        }
        if (saveSucessfullyBool) {
          var request = {
            name: self.get("location.name"),
            address: {
              street1: self.get("location.street1"),
              street2: self.get("location.street2"),
              city: self.get("location.suburb"),
              state: self.get("location.state"),
              postcode: self.get("location.postcode"),
              latitude: self.get("location.latitude"),
              longitude: self.get("location.longitude"),
              country: self.get("location.country")
            },
            latitude: self.get("location.latitude"),
            longitude: self.get("location.longitude"),
            enabled: this.get("location.enabled") && this.get("location.enabled") !== "" && this.get("location.enabled") !== undefined ? this.get("location.enabled") : false,
            enabledEbay: this.get("location.enabledEbay") && this.get("location.enabledEbay") !== "" && this.get("location.enabledEbay") !== undefined ? this.get("location.enabledEbay") : false,

            availableDelivery: self.get("location.availableDelivery"),
            availableDropoff: self.get("location.availableDropoff"),
            operatingHours: self.get("location.operatingHours"),
            openingDays: self.get("location.openingDays"),
            phone: self.get("new.phone") || "1300482233",
            abilities: abilities,
            couriersPleaseAccount: self.get("location.email"),
            storeType: self.get("location.storeType"),
            additional: {
              showOnMap: this.get("location.showOnMap") && this.get("location.showOnMap") !== "" && this.get("location.showOnMap") !== undefined ? this.get("location.showOnMap") : false,
              printAtStore: this.get("location.printAtStore") && this.get("location.printAtStore") !== "" && this.get("location.printAtStore") !== undefined ? this.get("location.printAtStore") : false,
              packingEnabled: this.get("location.packingEnabled") && this.get("location.packingEnabled") !== "" && this.get("location.packingEnabled") !== undefined ? this.get("location.packingEnabled") : false,

              channel: self.get("location.channelID"),
              product: productIdArr,
              service: serviceTypeIdArr,
              specialInstructions: self.get('location.special_instructions'),
              locationType: self.get("location.locationTypeID"),
              countryCode: self.get("location.countryCode")
            },
            cpData: {
              dlb: self.get("location.cpData.dlb"),
              suburb: self.get("location.cpData.cpSuburb"),
              state: self.get("location.cpData.cpState"),
              postcode: self.get("location.cpData.cpPostcode")
            },
            dhlData: {
              locationCode: self.get("location.dhlData.locationCode"),
              routeCode: self.get("location.dhlData.routeCode"),
              cycleCode: self.get("location.dhlData.cycleCode"),
              serviceAreaCode: self.get("location.dhlData.serviceAreaCode"),
              facilityCode: self.get("location.dhlData.facilityCode")
            },
            tollData: {
              locationCode: self.get("location.tollData.locationCode")
            },
            dscData: {
              locationCode: self.get("location.dscData.locationCode")
            },
            dropLocationsBusinessHours: locationBusinessHourArr
          };
          if (self.get("location.tntLocationId") && this.get("location.tntLocationId") !== "" && self.get("location.tntSiteId") && this.get("location.tntSiteId") !== "") {
            request['tntData'] = {
              locationId: this.get("location.tntLocationId"),
              siteId: this.get("location.tntSiteId"),
              enabled: this.get("location.tntenabled") && this.get("location.tntenabled") !== "" && this.get("location.tntenabled") !== undefined ? this.get("location.tntenabled") : false
            };
          } else {
            request['tntData'] = null;
          };
          this.get("loadingSpinner").show();

          // Trim all string values in the request object
          trimStringsInObject(request);

          this.get('ajax').put('v2/admin/dropLocations/' + this.get("location_id"), {
            data: JSON.stringify(request),
            contentType: "application/json",
            crossDomain: true,
            dataType: 'text'
          }).then(function () /*dl*/{
            self.send("loadLocation");
            self.set("editing", false);
            self.get("loadingSpinner").hide();
            self.get("notify").success('Successfully edited location.');
          }, function (error) {
            self.get("loadingSpinner").hide();
            if (error.errors && error.errors.length > 0) {
              self.get("notify").error(error.errors[0].detail.message);
            } else {
              self.get("notify").error('Error editing location');
            }
          });
        } else {
          self.get("loadingSpinner").hide();
          self.get("notify").error('Invalid Open and Close operating hours for ' + (0, _adminPortalHelpersWeekdayHelper.weekDayHelper)([errorDayNum]) + ', please check.');
        }
      },

      selectLocation: function selectLocation(value) {
        this.set("location_id", value.id);
        this.set("location", null);
        this.set("consignments", null);
        this.set("disableTNTLocation", true);
        this.set("editing", false);
        this.send("loadLocation");
        this.send("getPage", 1);
      },

      loadLocation: function loadLocation() {
        var _this2 = this;

        var self = this;
        this.set("locationDetailsLoading", true);

        this.set("drop_location_id", '' + this.get("location_id"));
        this.get('ajax').request('v2/admin/dropLocations/' + this.get("location_id") + '/lockerUnits').then(function (lockerUnits) {
          self.set('lockerUnits', lockerUnits);
        }, console.log);
        this.get('ajax').request('v2/admin/dropLocations/' + this.get("location_id") + '/users').then(function (users) {
          self.set('users', users);
        }, console.log);
        this.get('ajax').request('v2/admin/dropLocations/' + this.get("location_id") + '/sevenElevenData').then(function (sevenElevenRows) {
          self.set('sevenElevenRows', sevenElevenRows);
        }, console.log);
        this.get('ajax').request('v2/admin/droplocations/' + this.get("location_id")).then(function (result) {

          if (result.dropLocationsBusinessHours !== null) {
            var daysNumArr = [1, 2, 3, 4, 5, 6, 7],
                tempFoundArr = [],
                tempNotFoundArr = [];

            if (result.dropLocationsBusinessHours.length > 0) {
              for (var d = 0; d < result.dropLocationsBusinessHours.length; d++) {
                tempFoundArr.push(result.dropLocationsBusinessHours[d].day);
              }
              tempNotFoundArr = daysNumArr.filter(function (e) {
                return !tempFoundArr.includes(e);
              });
            }

            if (tempNotFoundArr.length > 0) {
              for (var i = 0; i < tempNotFoundArr.length; i++) {
                result.dropLocationsBusinessHours.push({ "id": null, "day": tempNotFoundArr[i], "openTime": "Closed", "closeTime": "Closed" });
              }
            }
            result.dropLocationsBusinessHours.sort(function (a, b) {
              if (a.openTime === null) {
                a.openTime = "Closed";
              }
              if (a.closeTime === null) {
                a.closeTime = "Closed";
              }
              if (b.openTime === null) {
                b.openTime = "Closed";
              }
              if (b.closeTime === null) {
                b.closeTime = "Closed";
              }
              if (a.closeTime === "00:00") {
                a.closeTime = "24:00";
              }
              if (b.closeTime === "00:00") {
                b.closeTime = "24:00";
              }
              return a.day - b.day;
            });
          } else {
            var businessHours = [{ "id": null, "day": 1, "openTime": "Closed", "closeTime": "Closed" }, { "id": null, "day": 2, "openTime": "Closed", "closeTime": "Closed" }, { "id": null, "day": 3, "openTime": "Closed", "closeTime": "Closed" }, { "id": null, "day": 4, "openTime": "Closed", "closeTime": "Closed" }, { "id": null, "day": 5, "openTime": "Closed", "closeTime": "Closed" }, { "id": null, "day": 6, "openTime": "Closed", "closeTime": "Closed" }, { "id": null, "day": 7, "openTime": "Closed", "closeTime": "Closed" }];
            result['dropLocationsBusinessHours'] = businessHours;
          }
          self.set('location', result);
          self.set('disableTNTLocation', true);
          self.set('location.name', result.name);
          if (self.get('location.address') !== null) {
            self.set('location.street1', self.get('location.address.street1'));
            self.set('location.street2', self.get('location.address.street2'));
            self.set('location.suburb', self.get('location.address.city'));
            self.set('location.state', self.get('location.address.state'));
            self.set('location.postcode', self.get('location.address.postcode'));
            self.set('location.country', self.get('location.address.country'));
            if (self.get('countries') && self.get('countries').length > 0) {
              var countries_list = self.get('countries');
              for (var m = 0; m < countries_list.length; m++) {
                if (self.get('location.address.country') === countries_list[m]['name']) {
                  self.set('location.countryCode', countries_list[m]['iso']);
                  break;
                }
              }
            }
          }
          if (self.get('location.droplocationAdditionalAttributes') !== null && self.get('location.droplocationAdditionalAttributes') !== undefined) {
            var droplocationAdditionalAttributesObj = self.get('location.droplocationAdditionalAttributes');
            var servicesList = '';var servicesArr = [];

            if (droplocationAdditionalAttributesObj.length > 0) {
              for (var i = 0; i < droplocationAdditionalAttributesObj.length; i++) {
                if (droplocationAdditionalAttributesObj[i].serviceType !== null) {
                  servicesArr.push(droplocationAdditionalAttributesObj[i].serviceType);
                  if (i === 0) {
                    servicesList += droplocationAdditionalAttributesObj[i].serviceType;
                  } else {
                    servicesList += ", " + droplocationAdditionalAttributesObj[i].serviceType;
                  }
                }
              }
            }
            self.set('location.servicesListStr', servicesList);
            self.set('location.serviceTypeIds', servicesArr);
            self.set('location.special_instructions', droplocationAdditionalAttributesObj[0].specialInstructions);
            self.set('location.openingDays', droplocationAdditionalAttributesObj[0].openingDays);
            self.set('location.showOnMap', droplocationAdditionalAttributesObj[0].showOnMap);
            self.set('location.printAtStore', droplocationAdditionalAttributesObj[0].printAtStore);
            self.set('location.packingEnabled', droplocationAdditionalAttributesObj[0].packingEnabled);
          }
          if (result.channelLocationTypeProduct !== null && result.channelLocationTypeProduct !== undefined && result.channelLocationTypeProduct.products !== null) {
            if (result.channelLocationTypeProduct.locationType !== null) {
              self.set('location.locationTypeID', result.channelLocationTypeProduct.locationType.id);
              self.set('location.locationTypeName', result.channelLocationTypeProduct.locationType.name);
            }
            if (result.channelLocationTypeProduct.channel !== null) {
              self.set('location.channelID', result.channelLocationTypeProduct.channel.channelId);
              self.set('location.channelName', result.channelLocationTypeProduct.channel.ChannelName);
            }
            var dropLocationProductObj = result.channelLocationTypeProduct.products;
            var productsList = '';
            var productsArr = [];
            for (var j = 0; j < dropLocationProductObj.length; j++) {
              productsArr.push(dropLocationProductObj[j].id);
              if (j === 0) {
                productsList += dropLocationProductObj[j].productname;
              } else {
                productsList += ", " + dropLocationProductObj[j].productname;
              }
            }
            self.set('location.productListStr', productsList);
            self.set('location.productIds', productsArr);
          }
          if (self.get('location.cpData') === null) {
            self.set('location.cpData', {});
          }
          if (self.get('location.dhlData') === null) {
            self.set('location.dhlData', {});
          }
          if (self.get('location.tntData') === null) {
            self.set('disableTNTLocation', false);
            self.set('location.tntLocationId', '');
            self.set('location.tntSiteId', '');
            self.set('location.tntenabled', '');
          } else if (self.get('location.tntData') !== null) {
            self.set('disableTNTLocation', true);
            self.set('location.tntLocationId', self.get('location.tntData.locationId'));
            self.set('location.tntSiteId', self.get('location.tntData.siteId'));
            self.set('location.tntenabled', self.get('location.tntData.enabled'));
          }
          if (self.get('location.tollData') === null || self.get('location.tollData') === undefined) {
            self.set('location.tollData', {});
          }
          if (self.get('location.dscData') === null || self.get('location.dscData') === undefined) {
            self.set('location.dscData', {});
          }
          self.set("location.email", self.get("location.couriersPleaseAccount"));

          self.set("displayLocation", self.get("location"));

          self.set("location", _adminPortalShapesNewLocation['default'].create(_ember['default'].getOwner(_this2).ownerInjection(), self.get('location')));

          self.get('ajax').request('v2/admin/dropLocations/abilities').then(function (results) {
            var abilities = results;
            var newAbilities = [];
            abilities.forEach(function (a) {
              if (result.abilities.filter(function (e) {
                return e.name === a.name;
              }).length > 0) {
                newAbilities.pushObject({
                  id: a.id,
                  name: a.name,
                  enabled: true
                });
              } else {
                newAbilities.pushObject({
                  id: a.id,
                  name: a.name,
                  enabled: false
                });
              }
            });
            self.set('abilities', newAbilities);
          });
          self.set("locationDetailsLoading", false);
        }, console.log);
      },

      getPage: function getPage(page) {
        var self = this;
        this.set("consignmentsLoading", true);
        this.get('ajax').request('v2/admin/locations/' + this.get("location_id") + '/consignments?page=' + page + '&perPage=10').then(function (result) {
          self.set('page', result.page);
          self.set('totalPages', result.totalPages);
          self.set('consignments', _ember['default'].A(result.results));
          self.set("consignmentsLoading", false);
        }, function (error) {
          console.log(error);
        });
      },

      startEditing7Data: function startEditing7Data(row) {
        _ember['default'].set(row, "editing", true);
        _ember['default'].set(row, "validFromNew", new Date(row.validFrom * 1000));
        _ember['default'].set(row, "validToNew", new Date(row.validTo * 1000));
      },

      cancelEditing7Data: function cancelEditing7Data(row) {
        _ember['default'].set(row, "editing", false);
      },

      saveEditing7Data: function saveEditing7Data(row) {
        var self = this;
        this.get("loadingSpinner").show();
        this.get('ajax').post('v2/admin/dropLocations/' + this.get("location_id") + '/sevenElevenData/' + row.id, {
          data: JSON.stringify({
            agentCode: row.agentCode,
            storeId: row.storeId,
            storeSuffix: row.storeSuffix,
            validTo: row.validToNew ? (0, _moment['default'])(row.validToNew).endOf('day').unix() : row.validTo,
            validFrom: row.validFromNew ? (0, _moment['default'])(row.validFromNew).startOf('day').unix() : row.validFrom
          }),
          contentType: "application/json",
          crossDomain: true
        }).then(function () /*dl*/{
          self.send("loadLocation");
          self.set("newSeven", {});
          self.get("loadingSpinner").hide();
          self.get("notify").success('Successfully edited seven eleven data to location.');
        }, function () /*error*/{
          self.get("loadingSpinner").hide();
          self.get("notify").error('Error editing seven eleven data to location');
        });
      },

      createLocker: function createLocker() {
        var _this3 = this;

        this.get("loadingSpinner").show();
        this.get('ajax').post('v2/admin/dropLocations/' + this.get('location_id') + '/lockerUnits', {
          data: JSON.stringify({
            provider: this.get('newLockerUnitProvider').id
          }),
          contentType: "application/json",
          crossDomain: true
        }).then(function (result) {
          _this3.get("loadingSpinner").hide();
          _this3.transitionToRoute('lockers.locker-unit', result.id);
        }, function () /*error*/{
          _this3.get("loadingSpinner").hide();
          _this3.get("notify").error('Error creating locker for this location');
        });
      }
    }

  });
});