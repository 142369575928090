define('admin-portal/pods/parcel-status/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'admin-portal/shapes/parcel-status'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _adminPortalShapesParcelStatus) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    config: _ember['default'].inject.service('config'),
    session: _ember['default'].inject.service('session'),
    ajax: _ember['default'].inject.service(),

    setupController: function setupController(controller) {
      controller.set('listingData', null);
      var admin_user_id = this.get('session.data.authenticated.user_id');
      admin_user_id = admin_user_id.replace('auth0|', '');
      controller.set("admin_user_id", admin_user_id);

      controller.set("parcelStatusForm", _adminPortalShapesParcelStatus['default'].create(_ember['default'].getOwner(controller).ownerInjection()));

      this.get('ajax').request(this.get("config.carrierApiURL") + 'courier/GetCourierList', {
        headers: {
          'Accept': 'application/json',
          'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
        }
      }).then(function (response) {
        controller.set("couriers", response.data);
      }, console.log);
    }
  });
});