define('admin-portal/shapes/invoicing', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  var Validations = (0, _emberCpValidations.buildValidations)({
    invoice_number: {
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true,
        gt: 0
      })]
    },
    fileData: [(0, _emberCpValidations.validator)('presence', { presence: true })]
  });
  exports['default'] = _ember['default'].Object.extend(Validations, {
    invoice_number: null,
    fileData: null
  });
});