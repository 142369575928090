define("admin-portal/pods/dashboard/controller", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    ajax: _ember["default"].inject.service(),

    lat: -27.822,
    lng: 133.7751,
    zoom: 4,

    dashboard: {
      all: {
        today: {
          "in": "Loading..."
        }
      },
      nsw: {
        today: {
          "in": "Loading..."
        }
      },
      act: {
        today: {
          "in": "Loading..."
        }
      },
      tas: {
        today: {
          "in": "Loading..."
        }
      },
      vic: {
        today: {
          "in": "Loading..."
        }
      },
      nt: {
        today: {
          "in": "Loading..."
        }
      },
      qld: {
        today: {
          "in": "Loading..."
        }
      },
      wa: {
        today: {
          "in": "Loading..."
        }
      },
      sa: {
        today: {
          "in": "Loading..."
        }
      }
    },

    stateSelected: "Global",

    actions: {
      selectState: function selectState(state) {
        var _this = this;

        if (state !== "Global" && this.get('dashboard.' + state.toLowerCase() + '.today.in') === "Loading...") {
          this.get('ajax').request('v2/admin/dashboard?state=' + state).then(function (data) {
            _this.set('dashboard.' + state.toLowerCase(), data);
          });
        }
        this.set("stateSelected", state);
      }
    }

  });
});