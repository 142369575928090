define('admin-portal/pods/courier/couriers/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service(),

    setupController: function setupController(controller) {
      controller.send("getPage", 1);

      this.get('ajax').request('v2/admin/countries').then(function (response) {
        controller.set("countries", response);
      }, console.log);
    }
  });
});