define('admin-portal/pods/reports/pods/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    loadingSpinner: _ember['default'].inject.service('loading'),

    queryParams: ["page", "courier", "start", "end", "location"],

    start: null,
    end: null,
    courier: null,
    location: null,
    page: 1,

    pods: [],

    actions: {
      getPage: function getPage(page) {
        var self = this;
        self.set("loading", true);
        this.get('ajax').request('v2/admin/consignments/podSearch?page=' + page + '&perPage=10&start=' + this.get("start") + '&end=' + this.get("end") + '&courier=' + this.get("courier") + '&dropLocation=' + this.get("location")).then(function (result) {
          self.set('page', result.currentPage);
          self.set('totalPages', result.totalPages);
          self.set('pods', _ember['default'].A(result.receipts));
          self.set("loading", false);
        }, function (error) {
          console.log(error);
        });
      }
    }

  });
});