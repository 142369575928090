define('admin-portal/pods/courier/update/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service(),
    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller, model) {
      this.get('ajax').request('v2/admin/getWebhookEvents').then(function (response) {
        controller.set("webhook_events", response.results);
      }, console.log);
      this.get('ajax').request('v2/admin/couriers/' + model.code).then(function (results) {
        controller.set("apiKeyValue", results.apiuser.apikey);
        controller.set("courierUnit", results);
        controller.set("savedWebhookEvents", results.webhookEvents);
        // controller.set("webhook_events", response.webhookEvents);
      }, console.log);
    }
  });
});