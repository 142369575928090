define('admin-portal/pods/sim/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    ajax: _ember['default'].inject.service('ajax'),
    notify: _ember['default'].inject.service('notify'),
    loadingSpinner: _ember['default'].inject.service('loading'),

    scenarios: [],
    selected: null,

    files: {},

    sampleData: [{
      cardNumber: "NHCLON223",
      barcode: "3256",
      consignmentNumber: "CON3453716",
      name: "Chris Jackson",
      mobile: "0424345672",
      email: "chris@premonition.io"
    }, {
      cardNumber: "NHCLON456",
      barcode: "4873",
      consignmentNumber: "CON5352900",
      name: "Curtis	Mullins",
      mobile: "0424345672",
      email: "chris@premonition.io"
    }, {
      cardNumber: "NHCLON141",
      barcode: "2325",
      consignmentNumber: "CON9657671",
      name: "Michelle	Harvey",
      mobile: "0424345672",
      email: "chris@premonition.io"
    }, {
      cardNumber: "NHCLON125",
      barcode: "9974",
      consignmentNumber: "CON8973506",
      name: "Mike Hubbson",
      mobile: "0424345672",
      email: "chris@premonition.io"
    }, {
      cardNumber: "NHCLON098",
      barcode: "3462",
      consignmentNumber: "CON8989266",
      name: "Jessica Brown",
      mobile: "0424345672",
      email: "chris@premonition.io"
    }, {
      cardNumber: "NHCLON083",
      barcode: "4468",
      consignmentNumber: "CON4970827",
      name: "Stacey Robbins",
      mobile: "0424345672",
      email: "chris@premonition.io"
    }, {
      cardNumber: "NHCLON882",
      barcode: "2129",
      consignmentNumber: "CON2693812",
      name: "Martin Evans",
      mobile: "0424345672",
      email: "chris@premonition.io"
    }, {
      cardNumber: "NHCLON047",
      barcode: "4089",
      consignmentNumber: "CON4368277",
      name: "Angelica Moore",
      mobile: "0424345672",
      email: "chris@premonition.io"
    }, {
      cardNumber: "NHCLON291",
      barcode: "9090",
      consignmentNumber: "CON4087598",
      name: "Jan	Crawford",
      mobile: "0424345672",
      email: "chris@premonition.io"
    }, {
      cardNumber: "NHCLON060",
      barcode: "4920",
      consignmentNumber: "CON4933302",
      name: "Zachary	Powell",
      mobile: "0424345672",
      email: "chris@premonition.io"
    }, {
      cardNumber: "NHCLON088",
      barcode: "8908001",
      consignmentNumber: "DUB4933302",
      name: "John	Powell",
      mobile: "0424345672",
      email: "chris@premonition.io"
    }, {
      cardNumber: "NHCLON088",
      barcode: "8908002",
      consignmentNumber: "DUB4933302",
      name: "John	Powell",
      mobile: "0424345672",
      email: "chris@premonition.io"
    }],

    actions: {
      resetDB: function resetDB() {
        var _this = this;

        if (this.get("selected")) {
          (function () {
            var formData = new FormData();
            _this.get("selected.expectedParts").forEach(function (e) {
              if (_this.get("files")[e]) {
                formData.append(e, _this.get("files")[e]);
              }
            });
            _this.get("loadingSpinner").show();
            _this.get('ajax').post('sim/load/' + _this.get("selected.id"), {
              data: formData,
              contentType: false,
              processData: false,
              dataType: 'text'
            }).then(function () /*result*/{
              _this.get("notify").success('Success');
              _this.get("loadingSpinner").hide();
            }, function () /*error*/{
              _this.get("notify").error('Error');
              _this.get("loadingSpinner").hide();
            });
          })();
        }
      },

      checkinLaunch: function checkinLaunch(courier) {
        this.set("currentCourier", courier);
        _ember['default'].$(".ui.modal.courier-checkin").modal('show');
      },

      checkinComplete: function checkinComplete(checkinData) {
        var _this2 = this;

        var courier = this.get("currentCourier");
        var email = this.get("email");
        var phone = this.get("phone");
        var items = [];
        var url = null;
        var username = null;
        var password = null;

        if (courier === "CouriersPlease") {
          url = "couriers/couriersPlease/inbound";
          username = "CouriersPlease";
          password = "2vGbWXbyRqDGkYbs";
          checkinData.items.forEach(function (i) {
            items.pushObject({
              Courier: "CouriersPlease",
              storeDlb: checkinData.dlb,
              missedDeliveryCardNumber: i.cardNumber,
              labelNumber: i.barcode,
              consignmentNumber: i.consignmentNumber,
              totalNumberOfLogisticUnits: checkinData.items.filter(function (a) {
                return a.consignmentNumber === i.consignmentNumber;
              }).length,
              contactName: i.name,
              mobileNumber: phone && phone.length > 0 ? phone : i.mobile,
              emailAddress: email && email.length > 0 ? email : i.email
            });
          });
        } else if (courier === "DHL") {
          url = "couriers/dhl/inbound";
          username = "DHL";
          password = "$HU663D@93";
          var grouped = checkinData.items.group(function (i) {
            return i.consignmentNumber;
          });
          grouped.forEach(function (g) {
            items.pushObject({
              storeDlb: checkinData.dlb,
              missedDeliveryCardNumber: g.data[0].cardNumber,
              labelNumbers: g.data.map(function (n) {
                return n.barcode;
              }),
              consignmentNumber: g.data[0].consignmentNumber,
              contactName: g.data[0].name,
              mobileNumber: phone && phone.length > 0 ? phone : g.data[0].mobile,
              emailAddress: email && email.length > 0 ? email : g.data[0].email
            });
          });
        }

        if (items) {
          (function () {
            var self = _this2;
            items.forEach(function (i) {
              _this2.get('ajax').post(url, {
                data: JSON.stringify(i),
                contentType: "application/json",
                crossDomain: true,
                headers: {
                  "Authorization": "Basic " + btoa(username + ":" + password)
                }
              }).then(function () /*result*/{
                self.get("notify").success('Success ' + i.consignmentNumber);
              }, function () /*error*/{
                self.get("notify").error('Error ' + i.consignmentNumber);
              });
            });
          })();
        }
      }
    }

  });

  Object.defineProperty(Array.prototype, 'group', {
    enumerable: false,
    value: function value(key) {
      var map = {};
      this.map(function (e) {
        return { k: key(e), d: e };
      }).forEach(function (e) {
        map[e.k] = map[e.k] || [];
        map[e.k].push(e.d);
      });
      return Object.keys(map).map(function (k) {
        return { key: k, data: map[k] };
      });
    }
  });
});