define('admin-portal/pods/lockers/locker-units/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),
    loadingSpinner: _ember['default'].inject.service('loading'),

    results: null,
    q: "",
    queryParams: ["q", "page"],

    loading: false,
    page: 1,
    totalPages: 1,

    actions: {
      changePage: function changePage(page) {
        this.set('page', page);
        this.send('refresh');
      },

      search: function search() {
        this.set('page', 1);
        this.send('refresh');
      },

      refresh: function refresh() {
        var _this = this;

        var query = '';
        if (this.get('q') !== "") {
          query = '&q=' + this.get('q');
        }
        this.set('loading', true);
        this.get('ajax').request('v2/admin/lockerUnits?page=' + this.get('page') + '&perPage=10' + query).then(function (results) {
          _this.set('page', results.page);
          _this.set('totalPages', results.totalPages);
          _this.set('results', results.results);
          _this.set('loading', false);
        }, console.log);
      }
    }
  });
});