define('admin-portal/pods/locations/download/controller', ['exports', 'ember', 'admin-portal/shapes/import-locations'], function (exports, _ember, _adminPortalShapesImportLocations) {
  exports['default'] = _ember['default'].Controller.extend({
    config: _ember['default'].inject.service('config'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),
    uploadingBool: false,
    uploadingUpdateBool: false,

    downloadAllLocations: _ember['default'].computed('session.data.authenticated.jwt', 'config.apiURL', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      return this.get("config.apiURL") + '/v2/admin/exports/locations?token=' + jwt;
    }),
    actions: {
      onUploadNew: function onUploadNew() {
        var fileContent = document.getElementById('file-upload-input').files[0];
        this.set('importNewData.fileData', fileContent);
      },
      onUploadUpdate: function onUploadUpdate() {
        var fileContent = document.getElementById('update-input').files[0];
        this.set('importUpdateData.fileData', fileContent);
      },
      uploadNewLocations: function uploadNewLocations() {
        if (this.get('importNewData.fileData')) {
          this.set("uploadingBool", true);

          var formDataUpload = new FormData();
          formDataUpload.append('file', this.get('importNewData.fileData'));
          var self = this;

          $.ajax({
            url: this.get("config.apiURL") + '/v2/admin/imports/locations',
            headers: {
              'Authorization': "Bearer " + this.get('session.data.authenticated.jwt')
            },
            data: formDataUpload,
            processData: false,
            contentType: false,
            type: 'POST',
            success: function success(data) {
              self.set("uploadingBool", false);
              document.getElementById('file-upload-input').value = '';
              self.get('notify').success('Imported Location(s) Successfully!');
              self.set('importNewData.fileData', null);
              self.set('importNewData', _adminPortalShapesImportLocations['default'].create(_ember['default'].getOwner(controller).ownerInjection()));
            },
            error: function error(jqXHR, exception) {
              self.set("uploadingBool", false);
              var msg = '';
              var successBool = false;
              if (jqXHR.status === 0) {
                msg = 'Not connect.\n Verify Network.';
              } else if (jqXHR.status === 404) {
                msg = 'Requested page not found. [404]';
              } else if (jqXHR.status === 500) {
                msg = 'Internal Server Error [500].';
              } else if (exception === 'parsererror') {
                msg = 'Requested JSON parse failed.';
              } else if (exception === 'timeout') {
                msg = 'Time out error.';
              } else if (exception === 'abort') {
                msg = 'Ajax request aborted.';
              } else {
                if (jqXHR.responseText === "Success") {
                  successBool = true;
                  document.getElementById('file-upload-input').value = '';
                  self.set('importNewData.fileData', null);
                  self.set('importNewData', _adminPortalShapesImportLocations['default'].create(_ember['default'].getOwner(controller).ownerInjection()));
                  msg = 'Imported Location(s) Successfully!';
                } else {
                  msg = 'Uncaught Error.\n' + jqXHR.responseText;
                }
              }
              if (successBool) {
                self.get('notify').success(msg);
              } else {
                self.get('notify').error(msg);
              }
            }
          });
        } else {
          this.get('notify').error('Please upload the file');
        }
      },
      uploadUpdateLocations: function uploadUpdateLocations() {
        if (this.get('importUpdateData.fileData')) {
          this.set("uploadingUpdateBool", true);

          var formDataUpload = new FormData();
          formDataUpload.append('file', this.get('importUpdateData.fileData'));
          var self = this;

          $.ajax({
            url: this.get("config.apiURL") + '/v2/admin/imports/locations/update',
            headers: {
              'Authorization': "Bearer " + this.get('session.data.authenticated.jwt')
            },
            data: formDataUpload,
            processData: false,
            contentType: false,
            type: 'POST',
            success: function success(data) {
              self.set("uploadingUpdateBool", false);
              self.get('notify').success('Updated Location(s) Successfully!');
              document.getElementById('update-input').value = '';
              self.set('importUpdateData.fileData', null);
              self.set('importUpdateData', _adminPortalShapesImportLocations['default'].create(_ember['default'].getOwner(controller).ownerInjection()));
            },
            error: function error(jqXHR, exception) {
              self.set("uploadingUpdateBool", false);
              var msg = '';
              var successBool = false;
              if (jqXHR.status === 0) {
                msg = 'Not connect.\n Verify Network.';
              } else if (jqXHR.status === 404) {
                msg = 'Requested page not found. [404]';
              } else if (jqXHR.status === 500) {
                msg = 'Internal Server Error [500].';
              } else if (exception === 'parsererror') {
                msg = 'Requested JSON parse failed.';
              } else if (exception === 'timeout') {
                msg = 'Time out error.';
              } else if (exception === 'abort') {
                msg = 'Ajax request aborted.';
              } else {
                if (jqXHR.responseText === "Success") {
                  successBool = true;
                  document.getElementById('update-input').value = '';
                  self.set('importUpdateData.fileData', null);
                  self.set('importUpdateData', _adminPortalShapesImportLocations['default'].create(_ember['default'].getOwner(controller).ownerInjection()));

                  msg = 'Updated Location(s) Successfully!';
                } else {
                  msg = 'Uncaught Error.\n' + jqXHR.responseText;
                }
              }
              if (successBool) {
                self.get('notify').success(msg);
              } else {
                self.get('notify').error(msg);
              }
            }
          });
        } else {
          this.get('notify').error('Please upload the file');
        }
      }
    }
  });
});