define('admin-portal/pods/locations/search/route', ['exports', 'ember', 'rsvp', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _rsvp, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    config: _ember['default'].inject.service('config'),
    ajax: _ember['default'].inject.service("ajax"),
    productList: null,
    serviceTypes: null,
    channel_list: null,

    model: function model() {
      return _rsvp['default'].hash({
        storeTypes: this.get('ajax').request('v2/admin/storeTypes'),
        // listingData:  this.get('ajax').request(this.get("config.carrierApiURL")+'ecommerce/GetMapConfig'),
        countries: this.get('ajax').request('v2/admin/countries')
      });
      // return this.get('ajax').request('v2/admin/storeTypes');
    },

    setupController: function setupController(controller, model) {
      controller.set("storeTypes", model.storeTypes);
      controller.set("countries", model.countries);
      controller.set("drop_location_id", null);
      controller.set("openTimePicker", config.openTimePicker);
      controller.set("closeTimePicker", config.closeTimePicker);

      this.get('ajax').request(this.get("config.carrierApiURL") + 'ecommerceuser/getmastertableslist', {
        headers: {
          'Accept': 'application/json',
          'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
        }
      }).then(function (listingData) {
        if (listingData.responseCode === "200" || listingData.responseCode === 200) {
          var listingDataObj = listingData.result;

          controller.set("productList", listingDataObj.products);
          controller.set("opening_days", listingDataObj.operatingHoursList);
          controller.set("serviceTypes", listingDataObj.serviceList);
          controller.set("channel_list", listingDataObj.channels);
          controller.set("location_type_list", listingDataObj.locationTypeList);
        }
      });
      this.get('ajax').request('v2/admin/lockerProviders').then(function (results) {
        controller.set("lockerProviders", results.sort(function (a, b) {
          return a.id - b.id;
        }));
      }, console.log);

      if (controller.get("location_id")) {
        controller.send("loadLocation");
        controller.send("getPage", 1);
      }
    }
  });
});