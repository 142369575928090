define('admin-portal/pods/components/consignment-pod-modal/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    session: _ember['default'].inject.service('session'),
    config: _ember['default'].inject.service('config'),

    name: 'consignment-pod',
    classNames: ['consignment-pod'],

    consignment: null,

    currentSignatureURL: _ember['default'].computed('consignment.pickupHasSignature', 'consignment.pickupReceiptId', 'session.data.authenticated', 'config.apiURL', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      if (this.get("consignment.pickupHasSignature")) {
        return this.get("config.apiURL") + '/v2/admin/consignments/' + this.get("consignment.pickupReceiptId") + '/signature?token=' + jwt;
      } else {
        return null;
      }
    }),

    onHide: function onHide() {
      this.set("consignment", null);
    }
  });
});