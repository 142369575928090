define('admin-portal/shapes/new-location', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    // email: [
    //   validator('format', { type: 'email' })
    // ],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      message: 'Invalid email eg. abc@domain.com'
    })],
    street1: {
      description: "Street1",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    suburb: {
      description: "Suburb",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    postcode: {
      description: "Postcode",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    state: {
      description: "State",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    country: {
      description: "Country",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    // latitude: {
    //   description: "Latitude",
    //   validators: [
    //     validator('presence', true)
    //   ]
    // },
    // longitude: {
    //   description: "Longitude",
    //   validators: [
    //     validator('presence', true)
    //   ]
    // },
    storeType: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    productIds: { validators: [(0, _emberCpValidations.validator)('presence', true)] },
    serviceTypeIds: { validators: [(0, _emberCpValidations.validator)('presence', true)] },
    special_instructions: {
      description: "Special Instructions",
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 40
      })]
    },
    tntSiteId: {
      description: "Site Code",
      validators: [(0, _emberCpValidations.validator)('length', {
        is: 9,
        allowBlank: true
      }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
        var tntLocationId = model.get('tntLocationId');
        if (value !== "" && tntLocationId === "" || value !== null && tntLocationId === null) {
          return 'Location Code is also required';
        }
        return true;
      }, {
        dependentKeys: ['model.tntSiteId', 'model.tntLocationId']
      })]
    },
    tntLocationId: {
      description: "Location Code",
      validators: [(0, _emberCpValidations.validator)('length', {
        is: 8,
        allowBlank: true
      }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
        // In case of tntLocationId, the tntSiteId should not be empty
        var tntSiteId = model.get('tntSiteId');
        if (value !== "" && tntSiteId === "" || value !== null && tntSiteId === null) {
          return 'Site Code is also required';
        }
        return true;
      }, {
        dependentKeys: ['model.tntSiteId', 'model.tntLocationId']
      })]
    }
  });

  //TODO: finish validation rules
  exports['default'] = _ember['default'].Object.extend(Validations, {
    name: null,
    address: {
      street1: null,
      street2: null,
      suburb: null,
      state: null,
      postcode: null,
      country: null
    },
    // latitude : null,
    // longitude : null
    email: null,
    storeType: null,
    productIds: null,
    serviceTypeIds: null,
    dhlData: {
      locationCode: null,
      routeCode: null,
      cycleCode: null,
      serviceAreaCode: null,
      facilityCode: null
    },
    cpData: {
      dlb: null,
      suburb: null,
      state: null,
      postcode: null
    },
    tollData: {
      locationCode: null
    },
    dscData: {
      locationCode: null
    },
    special_instructions: null,
    tntSiteId: null,
    tntLocationId: null
  });
});