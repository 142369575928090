define('admin-portal/pods/locations/new/controller', ['exports', 'ember', 'admin-portal/shapes/new-location', 'admin-portal/helpers/weekday-helper'], function (exports, _ember, _adminPortalShapesNewLocation, _adminPortalHelpersWeekdayHelper) {

  // Utility function to trim strings in an object
  function trimStringsInObject(obj) {
    for (var key in obj) {
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        trimStringsInObject(obj[key]);
      }
    }
  }

  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    ajax: _ember['default'].inject.service('ajax'),
    notify: _ember['default'].inject.service('notify'),
    loadingSpinner: _ember['default'].inject.service('loading'),

    'new': null,
    disableButton: true,

    //countries: ["Australia", "New Zealand", "United Kingdom", "United States"],
    init: function init() {
      this.set('new', _adminPortalShapesNewLocation['default'].create(_ember['default'].getOwner(this).ownerInjection()));
      this.set('new.tntLocationId', '');
      this.set('new.tntSiteId', '');
      this.set('new.tntenabled', '');
    },

    actions: {
      notActive: function notActive() {
        if (this.get("new.tntLocationId") !== "" && this.get("new.tntSiteId") !== "") {
          this.set('disableButton', false);
        } else {
          this.set('disableButton', true);
        }
      },

      getLatLong: function getLatLong(address, country) {
        var _this = this;

        this.set("selectedCountryName", country);
        if (address.state !== null && address.state !== "" && address.state !== undefined && country !== null && country !== undefined && country !== "") {
          var requestData = {
            address: {
              street1: address.street1,
              street2: address.street2,
              city: address.suburb,
              state: address.state,
              postcode: address.postcode,
              country: country
            }
          };
          this.get('ajax').post('v2/admin/geoLocations', {
            data: JSON.stringify(requestData),
            contentType: "application/json",
            crossDomain: true,
            dataType: 'text'
          }).then(function (response) {
            if (response && response !== null) {
              var responseObj = JSON.parse(response);
              if (responseObj.lat && responseObj.lon && responseObj.lat !== "" && responseObj.lon !== "") {
                _this.set("new.latitude", responseObj.lat);
                _this.set("new.longitude", responseObj.lon);
              }
            }
          }, function () /*error*/{
            _this.set("new.latitude", "");
            _this.set("new.longitude", "");
          });
        } else {
          this.set("new.latitude", "");
          this.set("new.longitude", "");
        }
      },

      create: function create() {
        var self = this;
        var productIdStr = self.get("new.productIds");

        var productIdArr = [];
        var serviceTypeIdArr = [];
        if (productIdStr !== null && productIdStr.length > 0) {
          productIdArr = productIdStr.map(function (item) {
            return parseInt(item);
          });
        }
        var serviceTypeIdStr = self.get("new.serviceTypeIds");
        if (serviceTypeIdStr !== null && serviceTypeIdStr.length > 0) {
          serviceTypeIdArr = serviceTypeIdStr.map(function (item) {
            return item;
          });
        }
        var abilities = self.get("abilities").filter(function (a) {
          return a.enabled;
        }).map(function (a) {
          return Number(a.id);
        });

        var locationBusinessHourArr = [],
            saveSucessfullyBool = true,
            errorDayNum = 0;
        var businessHoursData = self.get("new.businessHours");
        if (businessHoursData !== null && businessHoursData.length > 0) {
          locationBusinessHourArr = businessHoursData.map(function (item) {
            var returnItem = {
              "id": item.id,
              "day": item.day
            };
            if (item.openTime === "Closed") {
              returnItem['openTime'] = null;
            } else {
              returnItem['openTime'] = item.openTime;
            }
            if (item.closeTime === "Closed") {
              returnItem['closeTime'] = null;
            } else {
              returnItem['closeTime'] = item.closeTime;
            }
            var openTimeFloat = item.openTime;
            var closeTimeFloat = item.closeTime;
            if (openTimeFloat !== null) {
              openTimeFloat = openTimeFloat.replace(":", ".");
            }
            if (closeTimeFloat !== null) {
              closeTimeFloat = closeTimeFloat.replace(":", ".");
            }
            if (item.openTime !== "Closed" && item.closeTime === "Closed" || item.openTime === "Closed" && item.closeTime !== "Closed" || parseFloat(openTimeFloat) >= parseFloat(closeTimeFloat)) {
              saveSucessfullyBool = false;
              errorDayNum = item.day;
            }
            if (item.openTime !== null && item.closeTime === null) {
              saveSucessfullyBool = false;
              errorDayNum = item.day;
            }
            if (item.openTime === null && item.closeTime !== null) {
              saveSucessfullyBool = false;
              errorDayNum = item.day;
            }
            return returnItem;
          });
        }
        if (saveSucessfullyBool) {
          var request = {
            name: self.get("new.name"),
            enabled: this.get("new.enabled") && this.get("new.enabled") !== "" && this.get("new.enabled") !== undefined ? this.get("new.enabled") : false,
            storeType: self.get("new.storeType"),
            channel: self.get("new.channelIds"),
            locationType: self.get("new.location_type_id"),
            product: productIdArr,
            additionalParams: {
              service: serviceTypeIdArr,
              showOnMap: this.get("new.showonmap") && this.get("new.showonmap") !== "" && this.get("new.showonmap") !== undefined ? this.get("new.showonmap") : false,
              printAtStore: this.get("new.printatstore") && this.get("new.new.printatstore") !== "" && this.get("new.printatstore") !== undefined ? this.get("new.printatstore") : false,
              packingEnabled: this.get("new.packingenabled") && this.get("new.packingenabled") !== "" && this.get("new.packingenabled") !== undefined ? this.get("new.packingenabled") : false,
              countryCode: self.get("new.country")
            },
            specialInstructions: self.get('new.special_instructions'),
            address: {
              street1: self.get("new.street1"),
              street2: self.get("new.street2"),
              city: self.get("new.suburb"),
              state: self.get("new.state"),
              postcode: self.get("new.postcode"),
              latitude: self.get("new.latitude"),
              longitude: self.get("new.longitude"),
              country: self.get("selectedCountryName")
            },
            abilities: abilities,
            couriersPleaseAccount: self.get("new.email"),
            phone: self.get("new.phone") || "1300482233",
            operating_hours: self.get("new.operating_hours"),
            openingDays: self.get("new.opening_days"),
            cpData: {
              dlb: self.get("new.cpData.dlb"),
              suburb: self.get("new.cpData.suburb"),
              state: self.get("new.cpData.state"),
              postcode: self.get("new.cpData.postcode")
            },
            dhlData: {
              locationCode: self.get("new.dhlData.locationCode"),
              routeCode: self.get("new.dhlData.routeCode"),
              cycleCode: self.get("new.dhlData.cycleCode"),
              serviceAreaCode: self.get("new.dhlData.serviceAreaCode"),
              facilityCode: self.get("new.dhlData.facilityCode")
            },
            tollData: {
              locationCode: self.get("new.tollData.locationCode")
            },
            dscData: {
              locationCode: self.get("new.dscData.locationCode")
            },

            tntData: {
              siteId: self.get("new.tntSiteId"),
              locationId: self.get("new.tntLocationId"),
              enabled: self.get("new.tntenabled")
              // enabled : (this.get("new.tntenabled") && this.get("new.tntenabled") !== "" && this.get("new.tntenabled") !== undefined) ? this.get("new.tntenabled") : false,
            },
            dropLocationsBusinessHours: locationBusinessHourArr
          };

          if (self.get("new.tntLocationId") && this.get("new.tntLocationId") !== "" && self.get("new.tntSiteId") && this.get("new.tntSiteId") !== "") {
            request['tntData'] = {
              locationId: this.get("new.tntLocationId"),
              siteId: this.get("new.tntSiteId"),
              enabled: self.get("new.tntenabled") && this.get("new.tntenabled") !== "" && this.get("new.tntenabled") !== undefined ? this.get("new.tntenabled") : false
            };
          } else {
            request['tntData'] = null;
          }
          this.get("loadingSpinner").show();

          // Trim all string values in the request object
          trimStringsInObject(request);

          this.get('ajax').post('v2/admin/dropLocations', {
            data: JSON.stringify(request),
            contentType: "application/json",
            crossDomain: true,
            dataType: 'text'
          }).then(function () /*dl*/{
            self.set("selectedCountryName", '');
            self.set('new.address', '');
            self.set("new.cpData.dlb", "");
            self.set("new.cpData.suburb", "");
            self.set("new.cpData.state", "");
            self.set("new.cpData.postcode", "");
            self.set("new.dhlData.locationCode", "");
            self.set("new.dhlData.routeCode", "");
            self.set("new.dhlData.cycleCode", "");
            self.set("new.dhlData.serviceAreaCode", "");
            self.set("new.dhlData.facilityCode", "");
            self.set("new.tollData.locationCode", "");
            self.set("new.dscData.locationCode", "");
            self.set('disableButton', true);
            self.set('new', '');
            self.get("abilities").map(function (a) {
              if (a.enabled) {
                _ember['default'].set(a, 'enabled', !a.enabled);
              }
            });
            self.set('new', _adminPortalShapesNewLocation['default'].create(_ember['default'].getOwner(self).ownerInjection()));
            var businessHours = [{ "id": null, "day": 1, "openTime": null, "closeTime": null }, { "id": null, "day": 2, "openTime": null, "closeTime": null }, { "id": null, "day": 3, "openTime": null, "closeTime": null }, { "id": null, "day": 4, "openTime": null, "closeTime": null }, { "id": null, "day": 5, "openTime": null, "closeTime": null }, { "id": null, "day": 6, "openTime": null, "closeTime": null }, { "id": null, "day": 7, "openTime": null, "closeTime": null }];
            self.set("new.businessHours", businessHours);
            self.get("loadingSpinner").hide();
            self.get("notify").success('Successfully added new location.');
          }, function () /*error*/{
            self.get("loadingSpinner").hide();
            self.get("notify").error('Error adding new location.');
          });
        } else {
          self.get("loadingSpinner").hide();
          self.get("notify").error('Invalid Open and Close operating hours for ' + (0, _adminPortalHelpersWeekdayHelper.weekDayHelper)([errorDayNum]) + ', please check.');
        }
      }

    }

  });
});