define('admin-portal/pods/unlinked/consignment/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'admin-portal/shapes/unlinkedconsignments'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _adminPortalShapesUnlinkedconsignments) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service(),
    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller, model) {
      controller.set('tags', []);
      controller.set('unlinked_Consignments', null);
      controller.set('unlinked_Consignments', _adminPortalShapesUnlinkedconsignments['default'].create(_ember['default'].getOwner(this).ownerInjection()));
      this.get('ajax').request('v2/admin/getWebhookArticles?page=1&perPage=20&id=' + model.barcode_id).then(function (response) {
        controller.set("barcode", response.results);
        var tempConsignmentNumber = controller.get('barcode.consignment_number');
        if (tempConsignmentNumber && tempConsignmentNumber.length > 100) {
          tempConsignmentNumber = tempConsignmentNumber.substring(0, 100);
        }
        controller.set("unlinked_Consignments.consignment_number", controller.get('barcode.consignment_number'));
      }, console.log);
    }
  });
});