define('admin-portal/pods/components/developer-account-view/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    session: _ember['default'].inject.service('session'),
    loadingSpinner: _ember['default'].inject.service('loading'),

    name: 'courier-detail-view',
    classNames: ['courier-detail-view'],

    onHide: function onHide() {
      this.set("viewContent", null);
      this.set("isGeneratingBool", false);
      this.get("loadingSpinner").hide();
      _ember['default'].$('body').removeClass('editCourierClass');
      _ember['default'].$('.ui.modal.courier-detail-view').modal('hide');
    },

    actions: {
      cancel: function cancel() {
        this.get("loadingSpinner").hide();
        this.set("viewContent", null);
        this.set("isGeneratingBool", false);
        _ember['default'].$('body').removeClass('editCourierClass');
        _ember['default'].$('.ui.modal.courier-detail-view').modal('hide');
      }
    }
  });
});