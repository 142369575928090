define('admin-portal/pods/manifest/controller', ['exports', 'ember', 'admin-portal/shapes/manifest'], function (exports, _ember, _adminPortalShapesManifest) {
  exports['default'] = _ember['default'].Controller.extend({
    config: _ember['default'].inject.service('config'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    couriers_list: [{ name: "Vodafone", code: "Vodafone" }, { name: "Toll", code: "toll" }],
    weekdays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    loading: false,
    page: 1,
    totalPages: 1,

    tags: [],
    searchedLocationsArr: [],

    locationsURL: _ember['default'].computed('config.carrierApiURL', 'locationQuery', 'update_manifest.location', 'openUpdateFormBool', {
      get: function get() {
        if (this.get('locationQuery') !== '' && this.get("openUpdateFormBool") === false) {
          this.set('update_manifest.location', '');
        }
        if (this.get('openUpdateFormBool')) {
          this.set("openUpdateFormBool", false);
        }
        return this.get("config.carrierApiURL") + 'hubbed/droplocations/{query}';
      }
    }),
    locationsResponseWrap: function locationsResponseWrap(response) {
      var responseData = JSON.parse(response);
      return {
        results: responseData.result
      };
    },
    init: function init() {
      _ember['default'].$.fn.search.settings.templates["locationResult"] = function (response, fields) {
        var html = '';
        if (response[fields.results] !== undefined) {
          _ember['default'].$.each(response[fields.results], function (index, result) {
            html += '<a class="result">';
            html += '<div class="content">' + result["name"] + '</div>';
            html += '</a>';
          });
          return html;
        }
        return false;
      };
      _ember['default'].$.fn.search.settings.templates["message"] = function (message, type) {
        var html = '<a class="result">No record found</a>';
        return html;
      };
    },

    actions: {
      searchLocationByName: function searchLocationByName(inputVal) {
        var _this = this;

        if (inputVal) {
          this.get('ajax').request(this.get("config.carrierApiURL") + 'hubbed/droplocations/' + inputVal).then(function (response) {
            if (response && response.result && response.result.length > 0) {
              _this.set("searchedLocationsArr", response.result);
            }
          });
        }
      },
      addLocationAsTag: function addLocationAsTag(locationData) {
        if (!this.get('selectedLocationsArr').includes(locationData.id)) {
          this.get('tags').pushObject(locationData.name);
          this.get('selectedLocationsArr').pushObject(locationData.id);

          var selectedLocationStr = this.get('selectedLocationsArr').toString();
          this.set('manifest.location', selectedLocationStr);
        }
        this.set("searchedLocationsArr", []);
      },
      addTag: function addTag(tag) {
        //this.get('tags').pushObject(tag);
      },
      removeTagAtIndex: function removeTagAtIndex(index) {
        this.get('tags').removeAt(index);
        this.get('selectedLocationsArr').removeAt(index);
        this.set('manifest.location', this.get('selectedLocationsArr').toString());
      },
      search_by_courier: function search_by_courier(courier) {
        this.set("searchCourier", courier);
        this.send("getPageData", 1);
      },
      change_search_location: function change_search_location() {
        if (this.get("locationSearch") === "") {
          this.set("searchLocationId", undefined);
          this.send("getPageData", 1);
        }
      },
      change_location: function change_location() {
        if (this.get("locationQuery") === "") {
          if (this.get("updateBool") === true) {
            this.set("update_manifest.location", undefined);
            _ember['default'].$('#update_manifest_location').focus();
          } else {
            this.set("manifest.location", undefined);
          }
        }
      },
      search_by_location: function search_by_location(value) {
        this.set("locationSearch", value.name);
        this.set("searchLocationId", value.id);
        this.send("getPageData", 1);
      },
      addSelectLocation: function addSelectLocation(value) {
        this.set("addLocationQuery", value.name);
        this.set("manifest.location", value.id);
      },
      selectLocation: function selectLocation(value) {
        this.set("locationQuery", value.name);
        if (this.get("updateBool") === true) {
          this.set("openUpdateFormBool", true);
          this.set("update_manifest.location", value.id);
        } else {
          this.set("manifest.location", value.id);
        }
      },
      getPageData: function getPageData(page) {
        var self = this;
        self.set("loading", true);
        var queryStr = '?pageNo=' + page + '&perPageRecords=10';
        if (this.get('searchCourier') !== '' && this.get('searchCourier') !== undefined) {
          queryStr += '&courierCode=' + this.get("searchCourier").trim();
        }
        if (this.get('searchLocationId') !== '' && this.get('searchLocationId') !== undefined) {
          queryStr += '&dropLocationId=' + this.get("searchLocationId");
        }

        this.get('ajax').request(this.get("config.carrierApiURL") + 'Manifest/getconfiguration' + queryStr, {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          }
        }).then(function (response) {
          if (response && response.data) {
            self.set('page', response.pageNo);
            if (response.pageNo === 1 && response.totalPages === 0) {
              self.set('totalPages', 1);
            } else {
              self.set('totalPages', response.totalPages);
            }
            self.set("manifestData", response.data);
          }

          self.set("loading", false);
        }, function () /*error*/{
          self.set("loading", false);
        });
      },
      cancelSubmission: function cancelSubmission() {
        this.set("tags", []);
        this.set("addLocationQuery", '');
        this.set('selectedLocationsArr', []);
        this.set("manifest", null);
        this.set('manifest', _adminPortalShapesManifest['default'].create(_ember['default'].getOwner(this).ownerInjection()));
        this.set("updateBool", false);
      },
      closeEditModal: function closeEditModal() {
        this.set("update_manifest", null);
        this.set("locationQuery", '');
        this.set('update_manifest', _adminPortalShapesManifest['default'].create(_ember['default'].getOwner(this).ownerInjection()));
        this.set("updateBool", false);
        _ember['default'].$('.ui.edit-manifest-details.modal').modal('hide');
      },
      editManifestRule: function editManifestRule(dataObj) {
        this.set("locationQuery", '');

        var manifestDayArr = [];
        if (Array.isArray(dataObj.manifest_day)) {
          manifestDayArr = dataObj.manifest_day;
        } else {
          manifestDayArr = dataObj.manifest_day.split(",");
        }
        this.set("update_manifest", null);
        this.set("updateBool", true);
        this.set("openUpdateFormBool", true);

        this.set("locationQuery", dataObj.droplocation_name);

        this.set('update_manifest', _adminPortalShapesManifest['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
          "courier_code": dataObj.courier_code,
          "id": dataObj.id,
          "manifest_time": dataObj.manifest_time,
          "manifest_number": dataObj.manifest_number,
          "manifest_day": manifestDayArr,
          "location": dataObj.droplocation_id
        }));
        _ember['default'].$('.ui.edit-manifest-details.modal').modal('show');
      },
      submitdetails: function submitdetails() {
        var _this2 = this;

        this.set("isSavingBool", true);

        var urlStr = this.get("config.carrierApiURL");
        if (this.get("updateBool") === true && this.get("update_manifest").id && this.get("update_manifest").id && this.get("update_manifest").id !== undefined) {
          urlStr += 'manifest/updateconfiguration';
          var requestData = {
            "id": this.get("update_manifest").id,
            "courier_code": this.get("update_manifest").courier_code,
            "droplocation_id": this.get("update_manifest").location,
            "manifest_day": this.get("update_manifest").manifest_day.toString(),
            "manifest_number": this.get("update_manifest").manifest_number,
            "manifest_time": this.get("update_manifest").manifest_time.trim()
          };
        } else {
          urlStr += 'manifest/addconfiguration';
          var requestData = {
            "courier_code": this.get("manifest").courier_code,
            "droplocation_ids": this.get("manifest").location.split(","),
            "manifest_day": this.get("manifest").manifest_day.toString(),
            "manifest_number": this.get("manifest").manifest_number,
            "manifest_time": this.get("manifest").manifest_time.trim()
          };
        }
        this.get('ajax').post(urlStr, {
          headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          },
          data: JSON.stringify(requestData),
          contentType: 'application/json; charset=utf-8',
          crossDomain: true
        }).then(function (data) {
          _this2.set("isSavingBool", false);

          if (data.responseCode === "200" || data.responseCode === 200) {
            if (_this2.get("updateBool") === true) {
              _this2.set('update_manifest', '');
              _this2.set('update_manifest', _adminPortalShapesManifest['default'].create(_ember['default'].getOwner(_this2).ownerInjection()));
              _ember['default'].$('.ui.edit-manifest-details.modal').modal('hide');
            } else {
              _this2.set("tags", []);
              _this2.set('selectedLocationsArr', []);
              _this2.set('manifest', '');
              _this2.set('manifest', _adminPortalShapesManifest['default'].create(_ember['default'].getOwner(_this2).ownerInjection()));
            }
            _this2.set("updateBool", false);
            _this2.set("locationQuery", '');
            _this2.set("addLocationQuery", '');
            _this2.send("getPageData", 1);
            _this2.get('notify').success('Manifest data has been successfully saved.');
          } else {
            _this2.get('notify').error(data.responseMessage);
          }
        })['catch'](function (error) {
          _this2.set("isSavingBool", false);
          if (error && error.errors) {
            var errorMsg = error.errors.map(function (e) {
              return e.detail;
            });
            if (errorMsg[0] && errorMsg[0].result && errorMsg[0].result && errorMsg[0].result[0] && errorMsg[0].result[0].description) {
              _this2.get('notify').error(errorMsg[0].result[0].description);
            } else {
              _this2.get('notify').error('Error while saving, please try again.');
            }
          }
        });
      }
    }
  });
});