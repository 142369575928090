define('admin-portal/pods/transactions/unexported/controller', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    notify: _ember['default'].inject.service('notify'),
    loadingSpinner: _ember['default'].inject.service('loading'),

    queryParams: ["page"],

    startDate: null,
    endDate: null,

    transactionTypes: ['All', 'Debit', 'Credit'],
    debitOrCredit: 'All',

    containTypes: ['All', 'Checkout', 'Outbound', 'Return', 'Consumer portal referral', 'Consumer portal dropoff'],
    contains: 'All',

    accountNames: ['All', 'HUBBEDINBOUND', 'HUBBEDOUTBOUND', 'Couriers Please Inbound', 'Couriers Please Outbound', 'DHL Inbound', 'DHL Outbound', 'Toll Inbound', 'Toll Outbound', 'Road Runners Inbound', 'Road Runners Outbound'],
    account: 'All',

    exportName: '',

    page: 1,
    totalPages: 1,
    transactions: _ember['default'].A([]),

    buildUrl: function buildUrl(page) {
      var url = 'v2/admin/transactions/unexported?page=' + page + '&perPage=20';

      if (this.get('debitOrCredit') !== 'All') {
        url += '&type=' + this.get("debitOrCredit");
      }
      if (this.get('contains') !== 'All') {
        url += '&contains=' + this.get("contains");
      }
      if (this.get('account') !== 'All') {
        url += '&account=' + this.get("account");
      }
      if (this.get('startDate')) {
        url += '&start=' + (0, _moment['default'])(this.get("startDate")).format("YYYY-MM-DD");
      }
      if (this.get('endDate')) {
        url += '&end=' + (0, _moment['default'])(this.get("endDate")).format("YYYY-MM-DD");
      }

      return url;
    },

    actions: {
      getPage: function getPage(page) {
        var self = this;
        self.set("loading", true);
        this.get('ajax').request(this.buildUrl(page)).then(function (result) {
          self.set('page', result.page);
          self.set('totalPages', result.totalPages);
          self.set('transactions', _ember['default'].A(result.data));
          self.set("loading", false);
          self.get("loadingSpinner").hide();
        }, function (error) {
          console.log(error);
        });
      },

      applyFilter: function applyFilter() {
        this.get("loadingSpinner").show();
        this.send("getPage", 1);
      },

      exportFilter: function exportFilter() {
        var self = this;
        var filter = {
          type: this.get("debitOrCredit") === "All" ? null : this.get("debitOrCredit"),
          contains: this.get("contains") === "All" ? null : this.get("contains"),
          account: this.get("account") === "All" ? null : this.get("account"),
          name: this.get("exportName"),
          start: (0, _moment['default'])(this.get("startDate")).format("YYYY-MM-DD"),
          end: (0, _moment['default'])(this.get("endDate")).format("YYYY-MM-DD")
        };
        this.get("loadingSpinner").show();
        this.get('ajax').post('v2/admin/transactions/exports', {
          data: JSON.stringify(filter),
          contentType: "application/json",
          crossDomain: true
        }).then(function () {
          self.send("getPage", 1);
          self.set("exportName", '');
          self.get("loadingSpinner").hide();
          self.get("notify").success('Successfully created export.');
        }, function () /*error*/{
          self.get("loadingSpinner").hide();
          self.get("notify").error('Error creating export.');
        });
      }

    }

  });
});