define('admin-portal/pods/lockers/locker-unit/controller', ['exports', 'ember', 'moment', 'admin-portal/shapes/locker-unit'], function (exports, _ember, _moment, _adminPortalShapesLockerUnit) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),
    config: _ember['default'].inject.service('config'),

    unit: null,
    newDropBox: {},
    newLocker: {},
    lockers: null,
    sizeClasses: null,

    editingDescription: false,
    tmpDescription: null,
    editingExtRef: false,
    tmpExtRef: null,
    // reasondropdown:{},
    statusdropdown: {},
    tym: null,
    lockerToDelete: null,
    Markoff: null,
    lockerApiReport: {
      startDate: null,
      endDate: null
    },
    isd: null,
    lockerApiReportURL: _ember['default'].computed('lockerApiReport.startDate', 'lockerApiReport.endDate', 'session.data.authenticated', 'unit.id', 'config.apiURL', 'startDateFormat', 'endDateFormat', function () {
      var jwt = this.get('session.data.authenticated.jwt');

      var startDate = (0, _moment['default'])(this.get("startDateFormat")).format("YYYY-MM-DD");
      var endDate = (0, _moment['default'])(this.get("endDateFormat")).format("YYYY-MM-DD");

      if (this.get('unit') != null) {
        return this.get("config.apiURL") + '/v2/admin/lockerUnits/' + this.get('unit.id') + '/apiLogsExport?token=' + jwt + '&start=' + startDate + '&end=' + endDate;
      } else {
        return null;
      }
    }),

    AuDate: new Date().toLocaleString("en-GB", { timeZone: "Australia/Sydney" }),

    actions: {

      onChangeDateFrom: function onChangeDateFrom(val, selectedValue) {
        //this.set("enableDownloadBool", true);
        var daysDiff = 8;
        if (val === "startDate") {
          this.set('startDateFormat', null);
        }
        if (val === "endDate") {
          this.set('endDateFormat', null);
        }
        if (val === "startDate" && selectedValue && selectedValue !== "" && selectedValue !== null) {
          var startDate = (0, _moment['default'])(selectedValue).format("YYYY-MM-DD");
          this.set('startDateFormat', startDate);
          this.set('startDateTimestamp', (0, _moment['default'])(selectedValue).format("X"));
          this.set('startDateTimestampX', (0, _moment['default'])(selectedValue));
        } else if (val === "startDate" && (selectedValue === "" || selectedValue === null)) {
          this.set('startDateFormat', null);
          this.set('startDateTimestamp', null);
          this.set('startDateTimestampX', null);
        }
        if (val === "endDate" && selectedValue && selectedValue !== "" && selectedValue !== null) {
          var endDate = (0, _moment['default'])(selectedValue).format("YYYY-MM-DD");
          this.set('endDateFormat', endDate);
          this.set('endDateTimestamp', (0, _moment['default'])(selectedValue).format("X"));
          this.set('endDateTimestampX', (0, _moment['default'])(selectedValue));
        } else if (val === "endDate" && (selectedValue === "" || selectedValue === null)) {
          this.set('endDateFormat', null);
          this.set('endDateTimestamp', null);
          this.set('endDateTimestampX', null);
        }

        if (this.get('endDateTimestampX') !== undefined && this.get('startDateTimestampX') !== undefined) {
          daysDiff = this.get('endDateTimestampX').diff(this.get('startDateTimestampX'), 'days');

          this.set("enableDownloadBool", true);
          if (daysDiff >= 7) {
            this.set("enableDownloadBool", false);
            this.get('notify').error('Logs can be downloaded for a maximum of 7 days only.');
          }
        }

        if (this.get('startDateTimestamp') > this.get('endDateTimestamp')) {
          this.set("enableDownloadBool", false);
          this.get('notify').error('Invalid start or end date.');
        }
        if (this.get('startDateFormat') === this.get('endDateFormat') && daysDiff < 7) {
          this.set("enableDownloadBool", true);
        }
        if (selectedValue === "" || selectedValue == null) {
          this.set("enableDownloadBool", false);
          this.get('notify').error('Invalid start or end date.');
        }
      },

      startEditingDescription: function startEditingDescription() {
        this.set("editingDescription", true);
        this.set("tmpDescription", this.get("unit.description"));
      },
      cancelEditingDescription: function cancelEditingDescription() {
        this.set("editingDescription", false);
      },
      saveEditingDescription: function saveEditingDescription() {
        var _this = this;

        var description = this.get("tmpDescription");
        this.get('ajax').patch('v2/admin/lockerUnits/' + this.get('unit').id, {
          data: JSON.stringify({
            description: description
          }),
          contentType: "application/json",
          crossDomain: true
        }).then(function () {
          _this.set("unit.description", description);
          _this.get('notify').success('Successfully updated locker unit description.');
          _this.set("editingDescription", false);
        }, function () /*error*/{
          _this.get('notify').error('Could not update locker unit description. Check your internet connection and try again.');
        });
      },
      startEditingExtRef: function startEditingExtRef() {
        this.set("editingExtRef", true);
        this.set("tmpExtRef", this.get("unit.ext_ref"));
      },
      cancelEditingExtRef: function cancelEditingExtRef() {
        this.set("editingExtRef", false);
      },
      saveEditingExtRef: function saveEditingExtRef() {
        var _this2 = this;

        var extRef = this.get("tmpExtRef");
        this.get('ajax').patch('v2/admin/lockerUnits/' + this.get('unit').id, {
          data: JSON.stringify({
            ext_ref: extRef
          }),
          contentType: "application/json",
          crossDomain: true
        }).then(function () {
          _this2.set("unit.ext_ref", extRef);
          _this2.get('notify').success('Successfully updated locker unit external reference.');
          _this2.set("editingExtRef", false);
        }, function () /*error*/{
          _this2.get('notify').error('Could not update locker unit external reference. Ensure reference is not in use by another locker unit.');
        });
      },
      startEditingDropbox: function startEditingDropbox(dropboxDetail) {
        _ember['default'].set(dropboxDetail, "tmp_locker_shape", Object.assign({}, dropboxDetail.shape));
        _ember['default'].set(dropboxDetail, "editing", true);
      },
      cancelEditingDropbox: function cancelEditingDropbox() {
        this.set('dropbox', null);
      },
      startEditingLocker: function startEditingLocker(locker) {
        _ember['default'].set(locker, "tmp_locker_shape", Object.assign({}, locker.shape));
        _ember['default'].set(locker, "editing", true);
      },
      deleteLockerOpen: function deleteLockerOpen(locker) {
        if (locker.shape.full) {
          this.get('notify').error('Cannot delete in-use locker.');
        } else if (!locker.shape.disabled_at) {
          this.get('notify').error('Cannot delete enabled locker.');
        } else {
          this.set('lockerToDelete', locker);
          _ember['default'].$('.ui.delete-locker.modal').modal('show');
        }
      },
      deleteLockerClose: function deleteLockerClose() {
        this.set('lockerToDelete', null);
        _ember['default'].$('.ui.delete-locker.modal').modal('hide');
      },
      Markoff: function Markoff() {
        this.set('Markoff', true);
        _ember['default'].$('.ui.markoff.modal').modal('show');
      },
      deleteLocker: function deleteLocker(locker) {
        var _this3 = this;

        this.get('ajax')['delete']('v2/admin/lockers/' + locker.shape.id, {
          dataType: "text"
        }).then(function () {
          _this3.get('notify').success('Successfully deleted locker.');
          _this3.get('lockers').removeObject(locker);

          _this3.set('lockerToDelete', null);
          _ember['default'].$('.ui.delete-locker.modal').modal('hide');
        }, function () /*error*/{
          _this3.get('notify').error('Could not delete locker. Locker was disabled too recently.');
        });
      },
      cancelEditingLocker: function cancelEditingLocker(locker) {
        _ember['default'].set(locker, "tmp_locker_shape", null);
        _ember['default'].set(locker, "editing", false);
      },
      saveEditingLocker: function saveEditingLocker(locker) {
        var _this4 = this;

        var lockerShape = _ember['default'].get(locker, "tmp_locker_shape");
        if (lockerShape.ext_ref === null || lockerShape.ext_ref === '' && lockerShape.ext_ref.trim() === "") {
          this.get('notify').error('Please enter the reference value');
        } else {
          this.get('ajax').patch('v2/admin/lockers/' + locker.shape.id, {
            data: JSON.stringify({
              ext_ref: lockerShape.ext_ref,
              size_class: lockerShape.size_class.id,
              allocations: lockerShape.allocations
            }),
            contentType: "application/json",
            crossDomain: true
          }).then(function () {
            _ember['default'].set(locker, "shape", lockerShape);
            _this4.get('notify').success('Successfully updated locker.');
            _ember['default'].set(locker, "editing", false);
          }, function () /*error*/{
            _this4.get('notify').error('Error updating locker. Ensure the locker reference is not a duplicate.');
          });
        }
      },
      changeActiveDropBox: function changeActiveDropBox(dropboxList) {
        var _this5 = this;

        var dropInID = 0,
            dropOutID = 0;

        if (dropboxList && dropboxList.length > 0) {
          for (var i = 0; i < dropboxList.length; i++) {
            if (dropboxList[i].shape.size_class.id === this.get("dropInSizeClassID")) {
              dropInID = dropboxList[i].shape.id;
            } else if (dropboxList[i].shape.size_class.id === this.get("dropOutSizeClassID")) {
              dropOutID = dropboxList[i].shape.id;
            }
          }
        }
        this.set("dropboxEnabledBool", !this.get("dropboxEnabledBool"));
        var action = this.get("dropboxEnabledBool") ? "enableDropbox" : "disableDropbox";
        if (dropInID !== 0 && dropOutID !== 0) {
          this.get('ajax').post('v2/admin/Dropbox/' + action, {
            data: JSON.stringify({
              dropInId: dropInID,
              dropOutId: dropOutID,
              lockerId: this.get("unit").id
            }),
            contentType: "application/json",
            crossDomain: true,
            processData: false
          }).then(function () {
            _this5.get('notify').success('Successfully updated dropbox.');
          }, function (error) {
            //console.log(error.message);
            if (error.message === "Unexpected end of JSON input" || error.message === "JSON.parse: unexpected end of data at line 1 column 1 of the JSON data") {
              _this5.get('notify').success('Successfully updated dropbox.');
            } else {
              _this5.set("dropboxEnabledBool", !_this5.get("dropboxEnabledBool"));
              _this5.get('notify').error('Could not enable dropbox. Try refreshing this page.');
            }
          });
        }
      },

      changeActiveLocker: function changeActiveLocker(locker, checked) {
        var _this6 = this;

        var action = checked ? "enable" : "disable";
        this.get('ajax').post('v2/admin/lockers/' + locker.shape.id + '/' + action, {
          dataType: "text"
        }).then(function () {
          _this6.get('notify').success('Successfully ' + action + 'd locker.');
          if (checked) {
            _ember['default'].set(locker, "shape.disabled_at", null);
          } else {
            _ember['default'].set(locker, "shape.disabled_at", (0, _moment['default'])().unix());
          }
        }, function () /*error*/{
          _this6.get('notify').error('Could not ' + action + ' locker. Try refreshing this page.');
        });
      },

      // v2/admin/lockerUnits/56/lockers

      createLocker: function createLocker() {
        var _this7 = this;

        this.get('ajax').post('v2/admin/lockerUnits/' + this.get("unit").id + '/lockers', {
          data: JSON.stringify({
            ext_ref: this.get("newLocker").ext_ref,
            size_class: this.get("newLocker").size_class.id,
            allocations: this.get("newLocker").allocations || []
          }),
          contentType: "application/json",
          crossDomain: true
        }).then(function (result) {
          _this7.get('notify').success('Successfully added locker.');
          _this7.set("newLocker", {});
          _this7.get("lockers").unshiftObject({ 'shape': result });
        }, function () /*error*/{
          _this7.get('notify').error('Error adding locker. Ensure the locker reference is not a duplicate.');
        });
      },

      createDropBox: function createDropBox() {
        var _this8 = this;

        if ((this.get("dropbox_drop_in") === '' || this.get("dropbox_drop_out") === '') && (this.get("dropbox_drop_in").trim() === "" || this.get("dropbox_drop_out").trim() === "")) {
          this.get('notify').error('Please enter the required fields.');
        } else {
          this.get('ajax').post('v2/admin/' + this.get("unit").id + '/addDropbox', {
            data: JSON.stringify({
              drop_in: {
                ext_ref: this.get("dropbox_drop_in"),
                size_class: this.get("dropInSizeClassID"),
                allocations: this.get("dropbox_allocations") || []
              },
              drop_out: {
                ext_ref: this.get("dropbox_drop_out"),
                size_class: this.get("dropOutSizeClassID"),
                allocations: this.get("dropbox_allocations") || []
              }
            }),
            contentType: "application/json",
            crossDomain: true
          }).then(function () /*result*/{
            _this8.get('notify').success('Successfully added dropbox.');
            _this8.set("dropboxEnabledBool", false);
            _this8.get('ajax').request('v2/admin/' + _this8.get("unit").id + '/getDropinandout').then(function (results) {
              _this8.set("dropboxDetails", results);
            }, console.log);
          }, function () /*error*/{
            _this8.get('notify').error('Error adding dropbox. Ensure that dropbox reference is not a duplicate.');
          });
        }
      },
      changeActiveUnit: function changeActiveUnit(checked) {
        var _this9 = this;

        var action = checked ? "enable" : "disable";

        if (action === "enable") {
          var dropboxList = this.get('dropboxDetails');
          if (dropboxList && dropboxList.length > 0) {
            var dropInID = 0,
                dropOutID = 0;

            for (var i = 0; i < dropboxList.length; i++) {
              if (dropboxList[i].shape.size_class.id === this.get("dropInSizeClassID")) {
                dropInID = dropboxList[i].shape.id;
              } else if (dropboxList[i].shape.size_class.id === this.get("dropOutSizeClassID")) {
                dropOutID = dropboxList[i].shape.id;
              }
            }

            if (dropInID !== 0 && dropOutID !== 0) {
              this.get('ajax').post('v2/admin/lockerUnits/dropbox/enableLockerUnitsDropbox', {
                data: JSON.stringify({
                  lockerId: this.get("unit").id,
                  dropInId: dropInID,
                  dropOutId: dropOutID
                }),
                contentType: "application/json",
                crossDomain: true
              }).then(function () {

                _this9.get('ajax').post('v2/admin/lockerUnits/' + _this9.get("unit").id + '/' + action, {
                  dataType: "text"
                }).then(function () {
                  //refresh dropbox data
                  var dropBoxLatestData = new Array();
                  _this9.get('ajax').request('v2/admin/' + _this9.get("unit").id + '/getDropinandout').then(function (dropResponse) {
                    dropBoxLatestData = dropResponse;

                    _this9.get('notify').success('Successfully ' + action + 'd locker unit.');
                    _this9.get('ajax').request('v2/admin/lockerUnits/' + _this9.get("unit").id + '/lockers').then(function (results) {
                      _this9.set("lockers", results);
                    }, console.log);

                    if (dropBoxLatestData.length > 0 && dropBoxLatestData[0].shape.disabled_at === null) {
                      _this9.set("dropboxEnabledBool", true);
                    } else {
                      _this9.set("dropboxEnabledBool", false);
                    }
                  }, console.log);
                }, function () /*error*/{
                  _this9.get('notify').error('Could not ' + action + ' locker unit. Try refreshing this page.');
                });
              }, function (error) {

                _this9.get('ajax').post('v2/admin/lockerUnits/' + _this9.get("unit").id + '/' + action, {
                  dataType: "text"
                }).then(function () {
                  //refresh dropbox data
                  var dropBoxLatestData = new Array();
                  _this9.get('ajax').request('v2/admin/' + _this9.get("unit").id + '/getDropinandout').then(function (dropResponse) {
                    dropBoxLatestData = dropResponse;

                    _this9.get('notify').success('Successfully ' + action + 'd locker unit.');
                    _this9.get('ajax').request('v2/admin/lockerUnits/' + _this9.get("unit").id + '/lockers').then(function (results) {
                      _this9.set("lockers", results);
                    }, console.log);

                    if (dropBoxLatestData.length > 0 && dropBoxLatestData[0].shape.disabled_at === null) {
                      _this9.set("dropboxEnabledBool", true);
                    } else {
                      _this9.set("dropboxEnabledBool", false);
                    }
                  }, console.log);
                }, function () /*error*/{
                  _this9.get('notify').error('Could not ' + action + ' locker unit. Try refreshing this page.');
                });
              });
            }
          } else {
            this.get('ajax').post('v2/admin/lockerUnits/' + this.get("unit").id + '/' + action, {
              dataType: "text"
            }).then(function () {
              _this9.get('notify').success('Successfully ' + action + 'd locker unit.');
              _this9.get('ajax').request('v2/admin/lockerUnits/' + _this9.get("unit").id + '/lockers').then(function (results) {
                _this9.set("lockers", results);
              }, console.log);
            }, function () /*error*/{
              _this9.get('notify').error('Could not ' + action + ' locker unit. Try refreshing this page.');
            });
          }
        } else {
          this.get('ajax').post('v2/admin/lockerUnits/' + this.get("unit").id + '/' + action, {
            dataType: "text"
          }).then(function () {
            _this9.get('notify').success('Successfully ' + action + 'd locker unit.');
            _this9.get('ajax').request('v2/admin/lockerUnits/' + _this9.get("unit").id + '/lockers').then(function (results) {
              _this9.set("lockers", results);
            }, console.log);

            var dropboxList = _this9.get('dropboxDetails');
            if (dropboxList && dropboxList.length > 0) {
              var dropInID = 0,
                  dropOutID = 0;

              for (var i = 0; i < dropboxList.length; i++) {
                if (dropboxList[i].shape.size_class.id === _this9.get("dropInSizeClassID")) {
                  dropInID = dropboxList[i].shape.id;
                } else if (dropboxList[i].shape.size_class.id === _this9.get("dropOutSizeClassID")) {
                  dropOutID = dropboxList[i].shape.id;
                }
              }

              if (dropInID !== 0 && dropOutID !== 0) {
                _this9.set("dropboxEnabledBool", false);
                _this9.get('ajax').post('v2/admin/lockerUnits/dropbox/disableLockerUnitsDropbox', {
                  data: JSON.stringify({
                    lockerId: _this9.get("unit").id,
                    dropInId: dropInID,
                    dropOutId: dropOutID
                  }),
                  contentType: "application/json",
                  crossDomain: true
                }).then(function () /*result*/{
                  //this.set("dropboxEnabledBool", false);
                }, console.log);
              }
            }
          }, function () /*error*/{
            _this9.get('notify').error('Could not ' + action + ' locker unit. Try refreshing this page.');
          });
        }
      },

      removeLockerUnitOpen: function removeLockerUnitOpen() {
        if (this.get("unit").enabled) {
          this.get('notify').error('Cannot delete enabled locker unit.');
        } else {
          _ember['default'].$('.ui.remove-locker-unit.modal').modal('show');
        }
      },

      removeLockerUnitClose: function removeLockerUnitClose() {
        _ember['default'].$('.ui.remove-locker-unit.modal').modal('hide');
      },

      removeLockerUnit: function removeLockerUnit() {
        var _this10 = this;

        this.get('ajax')['delete']('v2/admin/lockerUnits/' + this.get("unit").id, {
          dataType: "text"
        }).then(function () {
          _this10.get('notify').success('Successfully deleted locker unit.');

          _ember['default'].$('.ui.remove-locker-unit.modal').modal('hide');
          _this10.transitionToRoute('lockers.locker-units');
        }, function () /*error*/{
          _this10.get('notify').error('Could not delete locker unit.');
        });
      },

      closeParcelModal: function closeParcelModal() {
        this.set('reasondropdown', null);
        this.set('parcelStatus', null);
        this.set('isd', null);
        this.set('notify.enabled', null);
        _ember['default'].$('.ui.parcel-data.modal').modal('hide');
      },

      Markoffbutton: function Markoffbutton(locker) {
        _ember['default'].$('.ui.parcel-data.modal').modal('show');
        var lockervalues = this.get('lockers');
        //console.log('lockervalues',lockervalues)
        var singleValue = lockervalues.filter(function (lockerobject) {
          return lockerobject.shape.id == '' + locker.shape.id;
        });
        this.set('popupValue', singleValue);

        this.set('reasondropdown', null);
        this.set('parcelStatus', null);
        this.set('isd', null);
        this.set('notify.enabled', null);
      },

      Updatemarkoffbutton: function Updatemarkoffbutton() {
        var _this11 = this;

        var self = this;
        var df = this.get('popupValue');
        var articles = df.map(function (items) {
          return items.articles[0];
        });
        var shape = df.map(function (item) {
          return item.shape;
        });
        //console.log('articles',articles);
        //console.log('what is in the popupValue',  articles[0].barcode);
        var inbound;
        if (articles[0].publicConsignment) {
          var inbound = {
            consignmentId: articles[0].publicConsignment ? articles[0].publicConsignment.consignmentId : null,
            articleId: articles[0].publicConsignment ? articles[0].publicConsignment.articleId : null
          };
        } else {
          var inbound = null;
        }

        var soso = this.get("reasondropdown");
        this.get('ajax').post('v2/admin/lockers/empty', {
          data: JSON.stringify({
            isd: this.get('isd'),
            eventType: this.get('parcelStatus'),
            lockerUnit: shape[0].locker_unit,
            lockerId: shape[0].id,
            notifyCourier: this.get("notify.enabled") && this.get("notify.enabled") !== "" && this.get("notify.enabled") !== undefined ? this.get("notify.enabled") : false,
            reason: this.get('reasondropdown'),
            article: {
              unlinkArticleBarcode: articles[0].unlinkArticleBarcode,
              barcode: articles[0].barcode,
              publicConsignment: inbound,
              externalConsignment: articles[0].externalConsignment !== undefined ? articles[0].externalConsignment : null,
              stgConsignment: articles[0].stgConsignment !== undefined ? articles[0].stgConsignment : null,
              consignment_number: articles[0].consignment_number
            }
          }),
          contentType: "application/json",
          crossDomain: true
        }).then(function () {
          _this11.get('notify').success('Successfully emptied locker.');
          _this11.get('ajax').request('v2/admin/lockerUnits/' + _this11.get("unit").id + '/lockers').then(function (results) {
            _this11.set("lockers", results);
          }, console.log);
          _this11.set('reasondropdown', null);
          _this11.set('parcelStatus', null);
          _this11.set('isd', null);
          _this11.set('notify.enabled', null);
          _ember['default'].$('.ui.parcel-data.modal').modal('hide');
        }, function (error) {
          //console.log(error.message);
          if (error.message === "Unexpected end of JSON input" || error.message === "JSON.parse: unexpected end of data at line 1 column 1 of the JSON data" || error.message === 'JSON Parse error: Unexpected EOF') {
            _this11.get('notify').success('Successfully updated locker.');
            _this11.get('ajax').request('v2/admin/lockerUnits/' + _this11.get("unit").id + '/lockers').then(function (results) {
              _this11.set("lockers", results);
            }, console.log);
            _ember['default'].$('.ui.parcel-data.modal').modal('hide');
          } else {
            _this11.get('notify').error('Could not empty locker. Try refreshing this page.');
          }
        });
      },
      backTo: function backTo() {
        this.transitionToRoute('lockers.locker-units');
        //return `${this.get("config.apiURL")}/v2/admin/lockerUnits/115`;
      }

    }

  });
});