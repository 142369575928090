define('admin-portal/helpers/sort-object', ['exports', 'ember'], function (exports, _ember) {
    exports.sortObjectHelper = sortObjectHelper;

    function sortObjectHelper(params) {
        var statesArr = params[0];
        var prop = 'product_name';

        statesArr.sort(function (a, b) {
            if (a[prop] < b[prop]) {
                return -1;
            } else if (a[prop] > b[prop]) {
                return 1;
            } else {
                return 0;
            }
        });
        return statesArr;
    }

    exports['default'] = _ember['default'].Helper.helper(sortObjectHelper);
});