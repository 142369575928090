define("admin-portal/pods/locations/download/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 10
            },
            "end": {
              "line": 15,
              "column": 12
            }
          },
          "moduleName": "admin-portal/pods/locations/download/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "validation");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "get", [["subexpr", "get", [["get", "importNewData.validations.attrs", [], 0, 0, 0, 0], "fileData"], [], [], 0, 0], "message"], [], ["loc", [null, [14, 36], [14, 80]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 3
            },
            "end": {
              "line": 22,
              "column": 3
            }
          },
          "moduleName": "admin-portal/pods/locations/download/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("				");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "ui left floated primary button");
          dom.setAttribute(el1, "type", "submit");
          dom.setAttribute(el1, "disabled", "");
          var el2 = dom.createTextNode("Uploading locations...");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 3
            },
            "end": {
              "line": 24,
              "column": 3
            }
          },
          "moduleName": "admin-portal/pods/locations/download/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("				");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "ui left floated primary button");
          dom.setAttribute(el1, "type", "submit");
          var el2 = dom.createTextNode("Upload File");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element1, 'disabled');
          morphs[1] = dom.createElementMorph(element1);
          return morphs;
        },
        statements: [["attribute", "disabled", ["subexpr", "get", [["get", "importNewData.validations", [], 0, 0, 0, 0], "isInvalid"], [], ["loc", [null, [null, null], [23, 56]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["uploadNewLocations"], [], ["loc", [null, [23, 110], [23, 141]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 10
            },
            "end": {
              "line": 36,
              "column": 12
            }
          },
          "moduleName": "admin-portal/pods/locations/download/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "validation");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "get", [["subexpr", "get", [["get", "importUpdateData.validations.attrs", [], 0, 0, 0, 0], "fileData"], [], [], 0, 0], "message"], [], ["loc", [null, [35, 36], [35, 83]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 3
            },
            "end": {
              "line": 43,
              "column": 3
            }
          },
          "moduleName": "admin-portal/pods/locations/download/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("				");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "ui left floated primary button");
          dom.setAttribute(el1, "type", "submit");
          dom.setAttribute(el1, "disabled", "");
          var el2 = dom.createTextNode("Uploading locations...");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 3
            },
            "end": {
              "line": 45,
              "column": 3
            }
          },
          "moduleName": "admin-portal/pods/locations/download/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("				");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "ui left floated primary button");
          dom.setAttribute(el1, "type", "submit");
          var el2 = dom.createTextNode("Upload File");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'disabled');
          morphs[1] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["attribute", "disabled", ["subexpr", "get", [["get", "importUpdateData.validations", [], 0, 0, 0, 0], "isInvalid"], [], ["loc", [null, [null, null], [44, 59]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["uploadUpdateLocations"], [], ["loc", [null, [44, 113], [44, 147]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 70,
            "column": 0
          }
        },
        "moduleName": "admin-portal/pods/locations/download/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "reports-page standard-page");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        dom.setAttribute(el2, "class", "hubbed-page-heading");
        var el3 = dom.createTextNode("Import / Download Locations");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "ui clearing segment form");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        dom.setAttribute(el3, "class", "ui header");
        var el4 = dom.createTextNode("Download all HUBBED locations");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "download", "");
        dom.setAttribute(el3, "class", "ui button blue");
        var el4 = dom.createTextNode("Download");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "ui clearing segment form");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        dom.setAttribute(el3, "class", "ui header");
        var el4 = dom.createTextNode("Import New Locations");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "two fields");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "field");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Select File*");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "id", "file-upload-input");
        dom.setAttribute(el5, "placeholder", "Choose a file");
        dom.setAttribute(el5, "accept", ".csv");
        dom.setAttribute(el5, "type", "file");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" \n            \n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "field");
        var el5 = dom.createTextNode("\n        	");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "ui clearing segment form");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        dom.setAttribute(el3, "class", "ui header");
        var el4 = dom.createTextNode("Update existing HUBBED Locations");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "two fields");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "field");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Select File*");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "id", "update-input");
        dom.setAttribute(el5, "placeholder", "Choose a file");
        dom.setAttribute(el5, "accept", ".csv");
        dom.setAttribute(el5, "type", "file");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" \n            \n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "field");
        var el5 = dom.createTextNode("\n        	");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" \n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "ui clearing segment form");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "field instructionFont");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("Instructions*");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "mrg-20");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Upload .csv format file");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Importing new locations:\n          	");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        dom.setAttribute(el6, "class", "mrg-20");
        var el7 = dom.createTextNode("\n          		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("Provide unique values to some of the important fields, Eg : Toll Location Code, TNT Site Code, TNT Location Code, CP Location Code...");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("If you try to send same csv after successfully importing it. It will through errors on fields that are meant to be unique.");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          	");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Update existing HUBBED locations:\n          	");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        dom.setAttribute(el6, "class", "mrg-20");
        var el7 = dom.createTextNode("\n          		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("Try to put only that locations which have change.");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("Some fields are not mean't to be edited. Eg. TNT site id (if there is already an existing id).");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          	");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" \n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [3, 3]);
        var element4 = dom.childAt(element2, [5, 3]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element5, [3]);
        var element7 = dom.childAt(element2, [7, 3]);
        var element8 = dom.childAt(element7, [1]);
        var element9 = dom.childAt(element8, [3]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element3, 'href');
        morphs[1] = dom.createAttrMorph(element6, 'onchange');
        morphs[2] = dom.createMorphAt(element5, 5, 5);
        morphs[3] = dom.createMorphAt(dom.childAt(element4, [3]), 3, 3);
        morphs[4] = dom.createAttrMorph(element9, 'onchange');
        morphs[5] = dom.createMorphAt(element8, 5, 5);
        morphs[6] = dom.createMorphAt(dom.childAt(element7, [3]), 3, 3);
        return morphs;
      },
      statements: [["attribute", "href", ["concat", [["get", "downloadAllLocations", ["loc", [null, [5, 17], [5, 37]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onchange", ["subexpr", "action", ["onUploadNew"], [], ["loc", [null, [null, null], [12, 50]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "and", [["subexpr", "get", [["subexpr", "get", [["get", "importNewData.validations.attrs", [], 0, 0, 0, 0], "fileData"], [], [], 0, 0], "isInvalid"], [], ["loc", [null, [13, 21], [13, 65]]], 0, 0], ["subexpr", "get", [["subexpr", "get", [["get", "importNewData.validations.attrs", [], 0, 0, 0, 0], "fileData"], [], [], 0, 0], "isDirty"], [], ["loc", [null, [13, 66], [13, 108]]], 0, 0]], [], ["loc", [null, [13, 16], [13, 109]]], 0, 0]], [], 0, null, ["loc", [null, [13, 10], [15, 19]]]], ["block", "if", [["get", "uploadingNewBool", ["loc", [null, [20, 9], [20, 25]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [20, 3], [24, 10]]]], ["attribute", "onchange", ["subexpr", "action", ["onUploadUpdate"], [], ["loc", [null, [null, null], [33, 53]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "and", [["subexpr", "get", [["subexpr", "get", [["get", "importUpdateData.validations.attrs", [], 0, 0, 0, 0], "fileData"], [], [], 0, 0], "isInvalid"], [], ["loc", [null, [34, 21], [34, 68]]], 0, 0], ["subexpr", "get", [["subexpr", "get", [["get", "importUpdateData.validations.attrs", [], 0, 0, 0, 0], "fileData"], [], [], 0, 0], "isDirty"], [], ["loc", [null, [34, 69], [34, 114]]], 0, 0]], [], ["loc", [null, [34, 16], [34, 115]]], 0, 0]], [], 3, null, ["loc", [null, [34, 10], [36, 19]]]], ["block", "if", [["get", "uploadingUpdateBool", ["loc", [null, [41, 9], [41, 28]]], 0, 0, 0, 0]], [], 4, 5, ["loc", [null, [41, 3], [45, 10]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  })());
});