define('admin-portal/pods/ecommerce/edit/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    config: _ember['default'].inject.service('config'),
    notify: _ember['default'].inject.service('notify'),
    loading: _ember['default'].inject.service('loading'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    isSavingBool: false,
    carrier_partners: [{ name: "CP", code: "cpl" }, { name: "Toll", code: "toll" }, { name: "None", code: 'none' }],
    timezone_list: config.timezone_list,

    integration_methods: ["Standard API", "Custom API", "Shopify", "WooCommerce", "Magento", "Other"],
    product_payment_methods: ["Customer Pays", "Consumer Pays", "Combination"],
    isClickBillingCheckbox: false,

    actions: {
      setCountry: function setCountry(country_name) {
        this.set('newcourier.country', country_name);
      },
      populateBillingAddress: function populateBillingAddress(newcourier) {
        if (this.get('populateBillingBool')) {
          this.set('newcourier.billing_address', newcourier.address1);
          this.set('newcourier.billing_address2', newcourier.address2);
          this.set('newcourier.billing_suburb', newcourier.suburb);
          this.set('newcourier.billing_state', newcourier.state);
          this.set('newcourier.billing_postcode', newcourier.postcode);
          this.set('newcourier.billing_country', newcourier.country);
          this.set('newcourier.billing_contactname', newcourier.contactname);
          this.set('newcourier.billing_email', newcourier.email);
          this.set('newcourier.billing_phone', newcourier.phone);
          this.set('newcourier.billing_position', newcourier.position);
        }
      },
      billingOpen: function billingOpen(newcourier) {
        if (this.get('isClickBillingCheckbox')) {
          if (this.get('isBillingOpen')) {
            this.set('populateBillingBool', false);
            this.set('newcourier.billing_address', null);
            this.set('newcourier.billing_address2', null);
            this.set('newcourier.billing_suburb', null);
            this.set('newcourier.billing_state', null);
            this.set('newcourier.billing_postcode', null);
            this.set('newcourier.billing_country', null);
            this.set('newcourier.billing_contactname', null);
            this.set('newcourier.billing_email', null);
            this.set('newcourier.billing_phone', null);
            this.set('newcourier.billing_position', null);
            this.set('isBillingOpen', false);
          } else {
            this.set('populateBillingBool', true);
            this.set('newcourier.billing_address', newcourier.address1);
            this.set('newcourier.billing_address2', newcourier.address2);
            this.set('newcourier.billing_suburb', newcourier.suburb);
            this.set('newcourier.billing_state', newcourier.state);
            this.set('newcourier.billing_postcode', newcourier.postcode);
            this.set('newcourier.billing_country', newcourier.country);
            this.set('newcourier.billing_contactname', newcourier.contactname);
            this.set('newcourier.billing_email', newcourier.email);
            this.set('newcourier.billing_phone', newcourier.phone);
            this.set('newcourier.billing_position', newcourier.position);
            this.set('isBillingOpen', true);
          }
        }
        this.set("isClickBillingCheckbox", true);
      },
      update: function update() {
        var _this = this;

        this.set("isSavingBool", true);

        var passwordStr = '';
        if (this.get('reseetPasswordBool') === true) {
          passwordStr = this.get('newcourier.notificationPassword');
        }
        var selected_carrier_partner = null;
        if (this.get('newcourier.carrier_partner') && this.get('newcourier.carrier_partner') !== "none") {
          selected_carrier_partner = this.get('newcourier.carrier_partner');
        }
        var activeBool = this.get("newcourier.active") && this.get("newcourier.active") !== "" && this.get("newcourier.active") !== undefined ? this.get("newcourier.active") : false;
        this.get('ajax').post(this.get("config.carrierApiURL") + 'ecommerceuser/update', {
          headers: {
            'Authorization': "Bearer " + this.get('session.data.authenticated.dotnet_jwt')
          },
          data: JSON.stringify({
            role_id: this.get('newcourier.role_id'),
            hubbed_user_id: this.get('newcourier.hubbed_user_id'),
            isLinkedCourier: this.get('newcourier.isLinkedCourier'),
            couriertype_id: this.get('newcourier.couriertype_id'),
            active: this.get("newcourier.active") && this.get("newcourier.active") !== "" && this.get("newcourier.active") !== undefined ? this.get("newcourier.active") : false,
            company_name: this.get('newcourier.customername'),
            notification_url: this.get('newcourier.notificationURL'),
            notification_username: this.get('newcourier.notificationUserName'),
            notification_password: passwordStr,
            token: "",
            contact_name: this.get("newcourier.contactname"),
            contact_phone: this.get("newcourier.phone"),
            contact_position: this.get("newcourier.position"),
            contact_email: this.get("newcourier.email"),
            contact_address: this.get('newcourier.address1'),
            contact_address2: this.get('newcourier.address2'),
            contact_suburb: this.get('newcourier.suburb'),
            contact_state: this.get("newcourier.state"),
            contact_postcode: this.get('newcourier.postcode'),
            contact_country: this.get("newcourier.country"),
            billing_contactname: this.get("newcourier.billing_contactname"),
            billing_phone: this.get("newcourier.billing_phone"),
            billing_position: this.get("newcourier.billing_position"),
            billing_email: this.get("newcourier.billing_email"),
            billing_address: this.get('newcourier.billing_address'),
            billing_address2: this.get('newcourier.billing_address2'),
            billing_suburb: this.get('newcourier.billing_suburb'),
            billing_state: this.get("newcourier.billing_state"),
            billing_postcode: this.get('newcourier.billing_postcode'),
            billing_country: this.get("newcourier.billing_country"),
            language_code: "",
            carrier_partner: selected_carrier_partner,
            send_notification: this.get("newcourier.send_notification") && this.get("newcourier.send_notification") !== "" && this.get("newcourier.send_notification") !== undefined ? this.get("newcourier.send_notification") : false,
            timeZoneId: this.get('newcourier.timezone'),
            logo: this.get('newcourier.logo'),
            duplicate_allowed_days: this.get('newcourier.duplicate_allowed_days'),

            integration_method: this.get("newcourier.integration_method"),
            billableServiceMapping: this.get("billableServiceMapping"),
            product_payment_method: this.get("newcourier.product_payment_method"),
            ecommerceProductMapping: this.get("productMappingList"),
            WebhookEventMapping: this.get("webhooksMappingList"),
            ecommerceChannelMapping: this.get("ecommerceChannelMapping"),
            isCustomNetwork: this.get("customizedChannel") && this.get("customizedChannel") === 1 ? true : false
          }),
          contentType: 'application/json; charset=utf-8',
          crossDomain: true
        }).then(function (data) {
          _this.set("isSavingBool", false);
          if (data.responseCode === "200" || data.responseCode === 200) {
            _this.get('ajax').request(_this.get("config.carrierApiURL") + 'ecommerceuser/isactive?hubbed_user_id=' + _this.get('newcourier.hubbed_user_id') + '&status=' + activeBool, {
              headers: {
                'Accept': 'application/json',
                'Authorization': "Bearer " + _this.get('session.data.authenticated.dotnet_jwt')
              }
            }).then(function () /*result*/{
              _this.get('notify').success('eCommerce partner has been successfully Updated.');
              _this.transitionToRoute('ecommerce.list');
            }, function () /*error*/{
              _this.get('notify').success('eCommerce partner has been successfully Updated.');
              _this.transitionToRoute('ecommerce.list');
            });
          } else {
            _this.get('notify').error(data.responseMessage);
          }
        })['catch'](function (error) {
          _this.set("isSavingBool", false);
          if (error && error.errors) {
            var errorMsg = error.errors.map(function (e) {
              return e.detail;
            });

            if (errorMsg[0] && errorMsg[0].message) {
              _this.get('notify').error(errorMsg[0].message);
            } else if (errorMsg[0] && errorMsg[0].result && errorMsg[0].result.length > 0) {
              _this.get('notify').error(errorMsg[0].result[0].description);
            } else {
              _this.get('notify').error('Error updating details.');
            }
          } else {
            _this.transitionToRoute('ecommerce.list');
          }
        });
      },
      checkForPageSetup: function checkForPageSetup(e) {
        var self = this;
        self.get("productMappingList").map(function (a) {
          if (a.id === e) {
            _ember['default'].set(a, 'enabled', !a.enabled);
          }
        });
      },
      reset_password: function reset_password() {
        this.set("reseetPasswordBool", !this.get('reseetPasswordBool'));
        if (this.get('reseetPasswordBool') === true) {
          this.set('newcourier.notificationPassword', '');
        } else {
          this.set('newcourier.notificationPassword', this.get('tempPassword'));
        }
      }
    }
  });
});